import {
  CommandBarButton,
  IButtonProps,
} from 'office-ui-fabric-react/lib/Button';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import {
  ContextualMenu,
  IContextualMenuProps,
} from 'office-ui-fabric-react/lib/ContextualMenu';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import React from 'react';
import { Toggle, TooltipHost, IconButton } from 'office-ui-fabric-react';
import appConfig from '../../assets/configuration/config';

export class Navigation extends React.Component<
  {
    currentUserName: string;
    logoutHandler: any;
    handleFeedback: any;
    isAdmin: boolean;
    isSynthetic: boolean;
    toggleIsSynthetic(): any;
  },
  {}
> {
  private overflowProps: IButtonProps = { ariaLabel: 'More commands' };
  public componentDidMount() {
    document
      .getElementsByClassName('ms-FocusZone ms-CommandBar')[0]
      .setAttribute('role', 'none');
    document
      .getElementsByClassName('ms-OverflowSet ms-CommandBar-primaryCommand')[0]
      .setAttribute('role', 'menubar');
    document
      .getElementsByClassName(
        'ms-OverflowSet ms-CommandBar-secondaryCommand'
      )[0]
      .setAttribute('role', 'menubar');
  }
  public render(): JSX.Element {
    return (
      <div className="navbar fixed-top navbar-expand-lg navbar-light bg-primary">
        <CommandBar
          role="banner"
          items={this.getItems()}
          farItems={this.getFarItems()}
          overflowButtonProps={this.overflowProps}
          ariaLabel={
            'Use left and right arrow keys to navigate between commands'
          }
          styles={this.getStyles()}
        />
      </div>
    );
  }

  // returns styles for the nav bar.
  private getStyles = () => {
    return {
      root: {
        height: '35px',
        isSticky: true,
      },
    };
  };

  // Data for Navigationbar
  private getItems = () => {
    return [
      {
        ariaLabel: 'GTO Invoicing',
        buttonStyles: this.getButtonStyles(),
        iconProps: {
          iconName: 'WindowsLogo',
        },
        key: 'GTO Invoicing',
        name: 'GTO Invoicing',
        href: `${appConfig.GTOUIBaseUrl}#/Home`,
      },

      {
        ariaLabel: 'Engagement',
        cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
        key: 'newItem',
        name: 'Engagement',
        iconProps: {
          iconName: 'Add',
        },
        subMenuProps: {
          items: [
            {
              ariaLabel: 'Engagement',
              iconProps: {
                iconName: 'ReportAdd',
              },
              key: 'Engagement',
              name: 'Engagement',
              href: `${appConfig.GTOUIBaseUrl}#/engagement`,
            },
            {
              ariaLabel: 'Rollover',
              iconProps: {
                iconName: 'Forward',
              },
              key: 'Rollover',
              name: 'Rollover',
              href: `${appConfig.GTOUIBaseUrl}#/rollOver`,
            },
            {
              ariaLabel: 'Automated SEID',
              iconProps: {
                iconName: 'ChangeEntitlements',
              },
              key: 'Automated Supplier Engagements',
              name: 'Automated Supplier Engagements',
              href: `${appConfig.GTOvNextUIBaseUrl}automatedSupplierEngagements`,
            },
          ],
        },
      },
      {
        ariaLabel: 'Pricing',
        // changing this key will invalidate this items cache
        cacheKey: 'myCacheKey',
        iconProps: {
          iconName: 'Money',
        },
        key: 'Pricing',
        name: 'Pricing',
        subMenuProps: {
          items: [
            {
              ariaLabel: 'Supplier Contracts',
              iconProps: {
                iconName: 'MiniContract',
              },
              key: 'Supplier Contracts',
              name: 'Supplier Contracts',
              href: `${appConfig.GTOvNextUIBaseUrl}contract`,
            },
            {
              ariaLabel: 'Pricing',
              iconProps: {
                iconName: 'Money',
              },
              key: 'Pricing',
              name: 'Pricing',
              href: `${appConfig.GTOUIBaseUrl}#/pricing`,
            },
            // {
            //   ariaLabel: 'Pricing',
            //   iconProps: {
            //     iconName: 'Money',
            //   },
            //   key: 'Pricing',
            //   name: 'Pricing N',
            //   href: `${appConfig.GTOvNextUIBaseUrl}pricing`,
            // },
          ],
        },
      },

      {
        ariaLabel: 'Invoicing',
        cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
        iconProps: {
          iconName: 'M365InvoicingLogo',
        },
        key: 'Invoicing',
        name: 'Invoicing',

        // ariaLabel: 'New. Use left and right arrow keys to navigate',
        subMenuProps: {
          items: [
            // {
            //   ariaLabel: 'Invoice Management',
            //   iconProps: {
            //     iconName: 'DocumentManagement',
            //   },
            //   key: 'Invoice Management',
            //   name: 'Invoice Management',
            //   href: `${appConfig.GTOUIBaseUrl}#/invoiceManagement`,
            // },
            {
              ariaLabel: 'Invoice Management',
              iconProps: {
                iconName: 'DocumentManagement',
              },
              key: 'Invoice Management',
              name: 'Invoice Management',
              href: `${appConfig.GTOvNextUIBaseUrl}invoiceManagement`,
            },
            {
              ariaLabel: 'Purchase Order',
              iconProps: {
                iconName: 'script',
              },
              key: 'Purchase Order',
              name: 'Purchase Order',
              href: `${appConfig.GTOUIBaseUrl}#/purchaseOrders`,
            },
            {
              ariaLabel: 'Internal Order',
              iconProps: {
                iconName: 'activateorders',
              },
              key: 'Internal Order',
              name: 'Internal Order',
              href: `${appConfig.GTOUIBaseUrl}#/bulkImportIO`,
            },
            {
              ariaLabel: 'Billable Units',
              iconProps: {
                iconName: 'BarChartVerticalEdit',
              },
              key: 'Billable Units',
              name: 'Billable Units',
              href: `${appConfig.GTOvNextUIBaseUrl}billableunits`,
            },
            {
              ariaLabel: 'Invoice',
              iconProps: {
                iconName: 'Financial',
              },
              key: 'Invoice',
              name: 'Invoice',
              href: `${appConfig.GTOvNextUIBaseUrl}invoiceview`,
            },
            {
              ariaLabel: 'BoB Report',
              iconProps: {
                iconName: 'PowerBILogo',
              },
              key: 'BoB Report',
              name: 'Book of Business Discount',
              href: `${appConfig.GTOvNextUIBaseUrl}bobdiscount`,
            },
          ],
        },
      },
      {
        ariaLabel: 'Supplier',
        iconProps: {
          iconName: 'Onboarding',
        },
        key: 'Supplier',
        name: 'Supplier',
        href: `${appConfig.GTOvNextUIBaseUrl}`,
      },
      {
        ariaLabel: 'Admin',
        cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
        key: 'Admin',
        name: 'Admin',

        iconProps: {
          iconName: 'Admin',
        },

        subMenuProps: {
          items: [
            {
              ariaLabel: 'EngagementID Attribute',
              iconProps: {
                iconName: '',
              },
              key: 'EngagementID Attribute',
              name: 'EngagementID Attribute',
              href: `${appConfig.GTOUIBaseUrl}#/orgAttribute`,
            },
            // {
            //   ariaLabel: 'EngagementID Attribute',
            //   iconProps: {
            //     iconName: '',
            //   },
            //   key: 'EngagementID Attribute',
            //   name: 'EngagementID Attribute N',
            //   href: `${appConfig.GTOvNextUIBaseUrl}orgAttribute`,
            // },
            // {
            //   ariaLabel: 'NRCLineItem Category',
            //   iconProps: {
            //     iconName: '',
            //   },
            //   key: 'NRCLineItem Category',
            //   name: 'NRCLineItem Category',
            //   href: `${appConfig.GTOUIBaseUrl}#/nrcLineItems`,
            // },
            {
              ariaLabel: 'NRCLineItem Category',
              iconProps: {
                iconName: '',
              },
              key: 'NRCLineItem Category',
              name: 'NRCLineItem Category',
              href: `${appConfig.GTOvNextUIBaseUrl}nrcLineItems`,
            },
            // {
            //   ariaLabel: 'Suppliers',
            //   iconProps: {
            //     iconName: '',
            //   },
            //   key: 'Suppliers',
            //   name: 'Suppliers',
            //   title:'Suppliers',
            //   href: `${appConfig.GTOUIBaseUrl}#/supplier`,
            // },
            {
              ariaLabel: 'Suppliers',
              iconProps: {
                iconName: '',
              },
              key: 'Suppliers',
              name: 'Suppliers',
              href: `${appConfig.GTOvNextUIBaseUrl}supplier`,
            },
            {
              ariaLabel: 'Supplier Sites',
              iconProps: {
                iconName: '',
              },
              key: 'Supplier Sites',
              name: 'Supplier Sites',
              href: `${appConfig.GTOvNextUIBaseUrl}suppliersites`,
            },

            // {
            //   ariaLabel: 'Supplier Sites',
            //   iconProps: {
            //     iconName: '',
            //   },
            //   key: 'Supplier Sites',
            //   name: 'Supplier Sites',
            //   href: `${appConfig.GTOUIBaseUrl}#/supplierSite`,
            // },
            // {
            //   ariaLabel: 'User Management',
            //   iconProps: {
            //     iconName: '',
            //   },
            //   key: 'User Management',
            //   name: 'User Management',
            //   href: `${appConfig.GTOUIBaseUrl}#/userManagement`,
            // },
            {
              ariaLabel: 'User Management',
              iconProps: {
                iconName: '',
              },
              key: 'User Management',
              name: 'User Management',
              href: `${appConfig.GTOvNextUIBaseUrl}userManagement`,
            },
          ],
        },
      },
      // {
      //   ariaLabel: 'Reports',
      //   cacheKey: 'myCacheKey', // changing this key will invalidate this items cache
      //   iconProps: {
      //     iconName: 'ReportLibrary',
      //   },
      //   key: 'Reports',
      //   name: 'Reports',
      //   href: `${appConfig.GTOUIBaseUrl}#/GTOReports`,
      //   // ariaLabel: 'New. Use left and right arrow keys to navigate',
      // },
      {
        ariaLabel: 'Reports',
        iconProps: {
          iconName: 'ReportLibrary',
        },
        key: 'Reports',
        name: 'Reports',
        href: `${appConfig.GTOvNextUIBaseUrl}GTOReports`,
        // ariaLabel: 'New. Use left and right arrow keys to navigate',
      },
      {
        ariaLabel: 'Help',
        iconProps: {
          iconName: 'Help',
        },
        key: 'Help',
        name: 'Help',
        href: this.onPathChange(),
        target: '_blank',
      },
    ];
  };

  private emojiMessage = () => {};

  private onPathChange = () => {
    let currentUrl = window.location.pathname;
    switch (currentUrl) {
      case '/invoiceview':
        return `${appConfig.GTOvNextUIBaseUrl}help/invoiceview`;
      case '/billableunits':
        return `${appConfig.GTOvNextUIBaseUrl}help/billableunits`;
      case '/contract':
        return `${appConfig.GTOvNextUIBaseUrl}help/contract`;
      default:
        return '/help';
    }
  };
  private getFarItems = () => {
    if (this.props.isAdmin) {
      return [
        {
          key: 'syntheticChange',
          onRender: this.renderIsSyntheticToggle,
        },
        {
          key: 'feedback1',
          onRender: this.renderEmojiButton,
        },
        {
          key: 'profile',
          onRender: this.renderAvatarButton,
        },
      ];
    } else {
      return [
        {
          key: 'feedback1',
          id: 'feedbackMenu',
          iconProps: {
            iconName: 'Emoji2',
            style: { fontSize: 25, color: 'black' },
          },
          onClick: this.props.handleFeedback,
          iconOnly: true,
          ariaLabel: 'Feedback',
        },
        {
          key: 'profile',
          onRender: this.renderAvatarButton,
        },
      ];
    }
  };

  private renderIsSyntheticToggle = () => {
    return (
      <div style={{ paddingTop: '0.5vh' }}>
        <Toggle
          label="Synthetic Transactions:"
          onText="On"
          offText="Off"
          inlineLabel
          onChange={this.onChangeIsSyntheticToggle}
        />
      </div>
    );
  };

  private onChangeIsSyntheticToggle = () => {
    // calling redux to toggle the state.
    this.props.toggleIsSynthetic();
  };

  private renderEmojiButton = () => {
    return (
      <TooltipHost
        content={'Feedback'}
        calloutProps={{ gapSpace: 0 }}
        styles={{ root: { display: 'inline-block' } }}
      >
        <IconButton
          onClick={() => this.props.handleFeedback}
          iconProps={{
            iconName: 'Emoji2',
            style: { fontSize: 25, color: 'black' },
          }}
          ariaLabel="Feedback"
        />
      </TooltipHost>
    );
  };

  private renderAvatarButton = () => {
    return (
      <TooltipHost
        content={'Profile Menu'}
        calloutProps={{ gapSpace: 0 }}
        styles={{ root: { display: 'inline-block', paddingTop: '3px' } }}
      >
        <CommandBarButton
          menuAs={this.getMenu}
          id="ProfileMenu"
          className="button"
          styles={this.getButtonStyles()}
          onRenderIcon={this.renderAvatar}
          onRenderMenuIcon={() => {
            return <div />;
          }}
          ariaLabel={'Profile menu'}
          menuProps={{
            items: [
              {
                iconProps: { iconName: 'AccountManagement' },
                id: 'ProfileMenuItem',
                key: 'profile',
                text: 'Profile',
              },
              {
                iconProps: { iconName: 'BulletedList' },
                id: 'ResourcesMenuItem',
                key: 'resources',
                text: 'Resources',
              },
              {
                iconProps: { iconName: 'Settings' },
                id: 'SettingsMenuItem',
                key: 'settings',
                text: 'Settings',
              },
              {
                href: 'https://privacy.microsoft.com/en-us/privacystatement',
                iconProps: { iconName: 'QuickNote' },
                id: 'privacyMenuItem',
                key: 'privacy',
                text: 'Privacy Notice',
              },
              {
                iconProps: { iconName: 'SignOut' },
                id: 'SignoutMenuItem',
                key: 'signout',
                onClick: this.props.logoutHandler,
                text: 'Sign out',
              },
            ],
          }}
        />
      </TooltipHost>
    );
  };

  private getMenu = (menuProps: IContextualMenuProps): JSX.Element => {
    return <ContextualMenu {...menuProps} />;
  };

  // Button Styles
  private getButtonStyles = () => {
    return {
      root: {
        color: 'Black',
        fontSize: 14,
      },
    };
  };

  // Render Login
  private renderAvatar = () => {
    return (
      <Persona
        text={this.props.currentUserName}
        size={PersonaSize.size28}
        hidePersonaDetails={true}
        initialsColor={'#3C1642'}
      />
    );
  };
}
