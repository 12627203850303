import {
  CommandBarButton,
  IconButton,
  ActionButton,
} from 'office-ui-fabric-react/lib/Button';
import {IColumn} from '@fluentui/react/lib/DetailsList';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DefaultPalette,
  mergeStyleSets,
} from 'office-ui-fabric-react/lib/Styling';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import * as React from 'react';
import {
  IPurchaseOrder,
  ISupplier,
  TelemetryEvent,
} from '../../../shared/models';
import telemetryContext from '../../../shared/services/TelemetryServices';

import { PurchaseOrderModal } from './purchase-order-modal/PurchaseOrderModal';
import { PurchaseOrderTable } from './purchase-order-table/PurchaseOrderTable';
import './PurchaseOrders.css';
// tslint:disable-next-line: ordered-imports
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import appConfig from '../../../assets/configuration/config';
import { Announced } from '@fluentui/react/lib/Announced';

// Interface for PurchaseOrder props.
interface IPurchaseOrdersProps {
  purchaseOrderItems: IPurchaseOrder[];
  showLoader: boolean;
  currentFiscalYear: number;
  currentSupplier: ISupplier;
  isMyOrderFeatureEnabled: boolean;
  isMyOrder2FeatureEnabled: boolean;
}

// Modal types.
enum IModalTypes {
  PurchaseOrder,
  CreatePurchaseOrder,
  AddAaravoId,
}

// Redirect Types
enum IRedirectTypes {
  None,
  Aravo,
  PurchaseOrder,
}
// Interface for PurchaseOrder state.
interface IPurchaseOrdersState {
  showModal: boolean;
  modalType: IModalTypes;
  redirect: IRedirectTypes;
}

class PurchaseOrders extends React.Component<
  IPurchaseOrdersProps,
  IPurchaseOrdersState
> {
  // Style for stack
  private stackStyles = mergeStyleSets({
    root: {
      selectors: {
        '> *': {
          alignItems: 'center',
          display: 'flex',

          justifyContent: 'center',
          color: DefaultPalette.black,
          fontWeight: '400',
        },
      },
    },
  });

  // Columns for Invoice tables in Modal
  private invoiceTableColumns: IColumn[] = [
    {
      key: 'column1',
      name: 'Invoice Number',
      fieldName: 'invoiceNumber',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'Invoice Created Date',
      fieldName: 'invoiceCreatedDate',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,

      data: 'string',
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'Invoice Amount',
      fieldName: 'invoiceAmount',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,

      data: 'string',
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'Invoice Status',
      fieldName: 'invoiceStatus',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      isPadded: true,
    },
  ];

  // Selected PO Number.
  private selectedPONumber: string = '';

  public constructor(props) {
    super(props);

    // State Initialization
    this.state = {
      redirect: IRedirectTypes.None,
      showModal: false,
      modalType: IModalTypes.PurchaseOrder,
    };
  }
  public render() {
    // console.log('PurchaseOrdcedrProps', this.props);
    // Checking if redirect is enabled. TODO: See behavior when you wish to route back to the same page.
    if (this.state.redirect === IRedirectTypes.PurchaseOrder) {
      // Calculating path
      const path: string = `/supplier/${this.props.currentSupplier.supplierNumber}/purchaseorder/create`;

      return <Redirect push={true} to={path} />;
    } else if (this.state.redirect === IRedirectTypes.Aravo) {
      // Calculating path
      const path: string = `/supplier/${this.props.currentSupplier.supplierNumber}/aravoengagement/add`;
      // Return redirect.
      return <Redirect push={true} to={path} />;
    } else {
      return <React.Fragment>{this.getPOContent()}</React.Fragment>;
    }
  }

  // Show Invoice Modal
  private handleShowInvoice = (item: any) => {
    telemetryContext.logEvent(TelemetryEvent.ViewInvoicesButtonClick, {
      purchaseOrderNumber: item.poNumber,
    });
    this.showModal(IModalTypes.PurchaseOrder, item.poNumber);
  };

  // Returning the supplier PO content
  private getPOContent = () => {
    // If showLoader is true then render loading.
    if (this.props.showLoader) {
      return (
        <div aria-label={'Loading'}>
          <Spinner
            style={{ height: '100%' }}
            label="Loading..."
            size={SpinnerSize.large}
          />
        </div>
      );
    } else {
      return (
        <>
          <PurchaseOrderModal
            showModal={this.state.showModal}
            closeModal={this.closeModal}
          >
            {this.getModalContent()}
          </PurchaseOrderModal>
          <Stack verticalAlign="space-between">
            <h1
              className="header-text"
              style={{ marginLeft: '2vw', width: '97%', textAlign: 'left',display: 'block', fontSize: '1.17em', fontWeight: 'bold', }}
            >
              Purchase Orders
            </h1>
            <div
              style={{
                display: 'flex',
                width: '98%',
                justifyContent: 'flex-end',
              }}
            >
              <CommandBarButton
                data-automation-id="test1"
                iconProps={{ iconName: 'Add' }}
                text=" Purchase Order"
                ariaLabel="Create Purchase Order"
                style={{
                  display: 'flex-end',
                  marginRight: '20px',
                  fontSize: '13px',
                  height: '30px',
                }}
                onClick={this.handleAddPurchaseOrder}
                // disabled={!this.props.isMyOrderFeatureEnabled}
                role="link"
              />
              <CommandBarButton
                data-automation-id="test2"
                iconProps={{ iconName: 'Add' }}
                text="Engagement"
                ariaLabel="Add Engagement"
                style={{
                  display: 'flex-end',
                  fontSize: '13px',
                  height: '30px',
                }}
                onClick={this.handleAddAravo} // Setting  Aravo Modal Type
                // disabled={!this.props.isMyOrderFeatureEnabled} // TODO: Uncomment this as this is temporary.
                role="link"
              />
            </div>
            <Fabric
              style={{
                width: '80vw',
                height: '30vh',
                overflow: 'auto',
                display: 'block',
                margin: '0 auto',
              }}
            >
              {this.props.purchaseOrderItems.length > 0 && this.props.purchaseOrderItems[0].poAmount !== null ? (
                <PurchaseOrderTable
                  items={this.createPOTableItems()}
                  columns={this.createPOTableColumns()}
                />
              ) : (
                <label
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', fontSize: '2em', margin: '0.67em 0 0.67em 0', fontWeight: 'bold',
                  }}
                >
                  {`Purchase Order Information is not avaliable for this supplier for fiscal year ${this.props.currentFiscalYear}.
          `}
                </label>
              )}
            </Fabric>
          </Stack>
        </>
      );
    }
  };

  // Logging creation of Purchase Order
  private handleAddPurchaseOrder = () => {
    telemetryContext.logEvent(TelemetryEvent.AddPurchaseOrderButtonClick, {
      supplierName: this.props.currentSupplier.supplierLegalName,
      supplierNumber: this.props.currentSupplier.supplierNumber,
    });

    this.setState({ redirect: IRedirectTypes.PurchaseOrder });
  };

  // Logging the aravo click event.
  private handleAddAravo = () => {
    telemetryContext.logEvent(TelemetryEvent.AddAravoEngagementButtonClick, {
      supplierName: this.props.currentSupplier.supplierLegalName,
      supplierNumber: this.props.currentSupplier.supplierNumber,
    });
    //  this.showModal(IModalTypes.AddAaravoId);

    this.setState({ redirect: IRedirectTypes.Aravo });
  };

  // Returns modal content based on the display type.
  private getModalContent() {
    if (
      this.state.showModal &&
      this.state.modalType === IModalTypes.PurchaseOrder
    ) {
      // PO Data based on the selected PO Number
      const purchaseOrderData: IPurchaseOrder = this.props.purchaseOrderItems.find(
        (x) => x.poNumber === this.selectedPONumber
      );

      return (
        <div className="po-modal-container">
          <h2
            aria-label="Purchase Order Number"
            style={{ marginLeft: '10px' }}
            tabIndex={0}
          >
            Purchase Order: {this.selectedPONumber}
          </h2>

          <div className="po-details">
            <span
              aria-label="PO-Details"
              style={{ padding: '20px', fontSize: '20px', fontWeight: 'bold' }}
              tabIndex={0}
            >
              Details{' '}
            </span>
            <div>
              <Stack>
                <Stack
                  horizontal={true}
                  horizontalAlign="space-between"
                  className={this.stackStyles.root}
                  padding="s1 15%"
                >
                  <div
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    tabIndex={0}
                  >
                    <span>Title</span>
                  </div>
                  <div
                    aria-label="Created Date"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    tabIndex={0}
                  >
                    <span>Created Date</span>
                  </div>
                  <div
                    aria-label="Amount"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    tabIndex={0}
                  >
                    <span>Amount</span>
                  </div>
                  <div
                    aria-label="Contract"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                      fontWeight: 700,
                      fontSize: '15px',
                    }}
                    tabIndex={0}
                  >
                    <span>Contract</span>
                  </div>
                </Stack>
                <Stack
                  horizontal={true}
                  horizontalAlign="space-between"
                  className={this.stackStyles.root}
                  padding="s1 15%"
                >
                  <div
                    aria-label="PO Title"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                    }}
                    tabIndex={0}
                  >
                    <span>{purchaseOrderData.poTitle}</span>
                  </div>
                  <div
                    aria-label="PO Created Date"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                    }}
                    tabIndex={0}
                  >
                    <span>{purchaseOrderData.poCreatedDate}</span>
                  </div>
                  <div
                    aria-label="Purchase Order Amount"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                    }}
                    tabIndex={0}
                  >
                    <span>{purchaseOrderData.poAmount}</span>
                  </div>
                  <div
                    aria-label="Contract Number"
                    style={{
                      textAlign: 'center',
                      minWidth: 130,
                      maxWidth: 130,
                      height: 'auto',
                    }}
                    tabIndex={0}
                  >
                    <span>
                      {purchaseOrderData.contracts.map((x) => (
                        <ActionButton
                          style={{ marginRight: '3px' }}
                          role="button"
                        >
                          {`${x.contractID}`}{' '}
                        </ActionButton>
                      ))}
                    </span>
                  </div>
                </Stack>
              </Stack>
            </div>
            <div
              style={{
                width: '310px',
                marginRight: '0px',
                marginLeft: 'auto',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              <div>
                <CommandBarButton
                  data-automation-id="test"
                  iconProps={{ iconName: 'Add' }}
                  ariaLabel="Extend Purchase Orders"
                  text="Extend PurchaseOrder"
                  style={{
                    backgroundColor: '#f4f4f4',
                    // marginLeft: '5px',
                    marginLeft: 'auto',
                    marginRight: 0,
                    color: '#0078d7',
                  }}
                  tabIndex={0}
                  // disabled={!this.props.isMyOrderFeatureEnabled}
                />
                <CommandBarButton
                  data-automation-id="test"
                  ariaLabel="Create Invoices"
                  iconProps={{ iconName: 'Add' }}
                  text="Create Invoice"
                  style={{
                    backgroundColor: '#f4f4f4',

                    marginLeft: 'auto',
                    marginRight: 0,
                    color: '#0078d7',
                  }}
                  tabIndex={0}
                  disabled={!this.props.isMyOrder2FeatureEnabled}
                />
              </div>
            </div>
          </div>
          <p />
          <div
            style={{
              width: '56vw',
              height: '30vh',
              overflow: 'auto',
              display: 'block',
              margin: '0 auto',
              backgroundColor: '#f4f4f4',
              borderRadius: '2px',
              boxShadow:
                '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
            }}
          >
            <span
              style={{
                marginLeft: '20px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
              tabIndex={0}
            >
              Invoices
            </span>
            <PurchaseOrderTable
              items={purchaseOrderData.invoices}
              columns={this.invoiceTableColumns}
            />
          </div>
        </div>
      );
    }
  }

  // Close Modal and setting textbox input empty
  private closeModal = (): void => {
    this.setState({ showModal: false });
  };

  // Handler for opening the Modal
  private showModal = (
    selectedModalType: IModalTypes,
    poNumber?: string
  ): void => {
    // Setting the current selected po number.
    this.selectedPONumber = poNumber;

    // Setting the Modal state
    this.setState({ showModal: true, modalType: selectedModalType });
  };

  // Create PO table columns based on PO Display Type.
  private createPOTableColumns(): IColumn[] {
    // Creating the column list.

    const columns: IColumn[] = [
      {
        key: 'column5',
        name: 'Expand',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        onRender: this.renderOpenIcon,
        isPadded: true,
      },
      {
        key: 'column6',
        name: 'PO Number',
        fieldName: 'poNumber',
        minWidth: 65,
        maxWidth: 150,
        isResizable: true,
        onRender: this.renderPoNumber,

        data: 'string',
        isPadded: true,
      },
      {
        key: 'column14',
        name: 'Aravo Id',
        fieldName: 'aravoEngagementId',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column7',
        name: 'PO Title',
        fieldName: 'poTitle',
        minWidth: 160,
        maxWidth: 250,
        isResizable: true,

        data: 'string',
        isPadded: true,
      },

      {
        key: 'column8',
        name: 'PO Created Date',
        fieldName: 'poCreatedDate',
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,

        data: 'string',
        isPadded: true,
      },
      {
        key: 'column',
        name: 'Parent PO',
        fieldName: 'parentPurchaseOrder',
        minWidth: 90,
        maxWidth: 150,
        isResizable: true,
        onRender: this._onRenderItemColumnvalues,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column19',
        name: 'PO Status',
        fieldName: 'poState',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column9',
        name: 'PO Amount',
        fieldName: 'poAmount',
        minWidth: 75,
        maxWidth: 150,
        isResizable: true,

        data: 'string',
        isPadded: true,
      },
      {
        key: 'column13',
        name: 'Extend PO',
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
        data: 'string',
        // On hover will show the names otherwise render Icons
        onRender: (item: IPurchaseOrder) => {
          if (item.poType === 'Master' && item.poState === 'Completed') {
            return (
              <span>
                <Link
                  to={`/supplier/${this.props.currentSupplier.supplierNumber}/purchaseorder/${item.poNumber}/create`}
                  role="link"
                >
                  Extend PO
                </Link>
                {/* <a href="#">Extend PO</a> */}
              </span>
            );
          }else{
            return (
              <span >No purchase order exists for this supplier for the selected fiscal year</span>
          );
          }
        },
        isPadded: true,
      },
    ];

    return columns;
  }

  // Create table items based on current Po display type.
  private createPOTableItems(): IPurchaseOrder[] {
    let finalPOList: IPurchaseOrder[];
    finalPOList = this.props.purchaseOrderItems.filter(
      (x) => x.fiscalYearNumber === this.props.currentFiscalYear
    );
    return finalPOList;
  }

  // This function renders the open icon on the PO table.
  private renderOpenIcon = (item: any): JSX.Element => {
    return (
      <TooltipHost
        content={'View Invoices'}
        id={'viewInvoices'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ height: '10px', textAlign: 'center' }}
          data-automation-id="test"
          iconProps={{ iconName: 'MiniExpand' }}
          // tslint:disable-next-line: jsx-no-lambda
          onClick={() => this.handleShowInvoice(item)}
          aria-label={'Close modal'}
          disabled={!this.props.isMyOrderFeatureEnabled}
        />
      </TooltipHost>
    );
  };

  // Private function callback to render PO number.
  private renderPoNumber = (item: any): JSX.Element => {
    return (
      <a
        className="doc-link"
        id={item.poNumber}
        href={`${appConfig.POOrderOverviewUrl}${item.poNumber}`}
        target="_blank"
        role="link"
        rel="noopener noreferrer"
        type="link"
      >
     <span role="link">{item.poNumber}</span> <p className="sr-only">link</p>
      </a>
    );
  };

  // Private function callback to render contract number.
  private renderContractNumber = (item: any): JSX.Element => {
    return (
      <React.Fragment>
        {item.contracts.map((x) => (
          <ActionButton role="button" style={{ marginRight: '3px' }}>
            {`${x.contractNumber}`}
          </ActionButton>
        ))}
      </React.Fragment>
    );
  };

  private _onRenderItemColumnvalues = (item: any, index: number, column: IColumn): JSX.Element | React.ReactText => {
    if (item.parentPurchaseOrder === null) {
        return (
            <span >{item.parentPurchaseOrder === null  ? 'No purchase order exists for this supplier for the selected fiscal year' : item.parentPurchaseOrder}</span>
        );
    }
};
}

export default PurchaseOrders;
