import * as React from 'react';
import {
  PrimaryButton,
  DefaultButton,
  TextField,
  IconButton,
  ActionButton,
  ITextField,
} from  '@fluentui/react';
import { Dropdown, IDropdownOption,IDropdown,  Modal} from '@fluentui/react';
import { connect } from 'react-redux';
import { IHeadCountMonthList } from '../../shared/models';
import { delay } from 'q';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import {
  getNRCTypes,
  getNRCCategories,
  getNRCDescription,
  saveNewOrEdittedNRCDescription,
  submitNRCDetails,
  isNRCDescriptionFetched
} from '../../store/actions/headCount.action';
import {
  INrcType,
  INrcCategory,
  INrcDescription,
  ISubmitNRCDetails,
} from '../../shared/models/NRC'
import { getId } from 'office-ui-fabric-react/lib/Utilities';


export interface IAddNRCProps {
  showNRC: boolean;
  closeNRC: any;
  fiscalYear: number;
  monthList: IHeadCountMonthList[];
  units: IInvoiceBillableUnitDetails[];
  getNRCTypes: any;
  nrcTypes: INrcType[];
  getNRCCategories: any;
  nrcCategories: INrcCategory[];
  getNRCDescription: any;
  nrcDescription: INrcDescription[];
  saveNewOrEdittedNRCDescription: any;
  submitNRCDetails: any;
  isDescriptionFetched: boolean;
  selectedCumMonth: number;
  isNRCDescriptionFetched: any;
}

export interface IAddNRCState {
  changeNrcWidth:string
  selectedNRCType: string;
  selectedNRCTypeKey: number;
  nrcSeid: number;
  currencyForNrc: string;
  selectedNRCCategory: string;
  selectedNRCCategoryKey: number;
  isEditDescription: boolean;
  nrcUnits: number;
  selectedNRCDescription: string;
  isNRCDescriptionSelected: boolean;
  marginTop:string;
}

const dropDownRef = React.createRef<IDropdown>();
const textFieldRef = React.createRef<ITextField>();

class AddNRC extends React.Component<IAddNRCProps, IAddNRCState> {
  private isSeidExists: boolean;
  private textFieldplaceholderNrc: string = '';
  private selectedDescriptionKey: React.ReactText = '';
  private isNewDescription: boolean;
  private titleId: string = getId('title');
  private subtitleId: string = getId('subText');

  constructor(props) {
    super(props);
    this.state = {
      changeNrcWidth:'20vw',
      selectedNRCType: '',
      selectedNRCTypeKey: 0,
      nrcSeid: 0,
      currencyForNrc: '',
      selectedNRCCategory: '',
      selectedNRCCategoryKey: 0,
      isEditDescription: false,
      nrcUnits: 0,
      selectedNRCDescription: '',
      isNRCDescriptionSelected: false,
      marginTop:'5vh'
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize()
    this.props.getNRCTypes();
    this.props.getNRCCategories();
  }
  resize = () => {
    let widnowSize = window.innerWidth;
if(widnowSize >600 && widnowSize < 769){
  this.setState({
    changeNrcWidth:'25vw',
    marginTop:'10vh'
  })
}
else if(widnowSize >425 && widnowSize < 590){
  this.setState({
    changeNrcWidth:'40vw'
  })
}
else if(widnowSize >380 && widnowSize < 424){
  this.setState({
    changeNrcWidth:'40vw'
  })
}
else if(widnowSize >280 && widnowSize < 350){
  this.setState({
    changeNrcWidth:'55vw'
  })
}else if(widnowSize < 280){
  this.setState({
    changeNrcWidth:'55vw'
  })
}
  }


  public render() {
    return (
      <Modal
        titleAriaId={this.titleId}
        subtitleAriaId={this.subtitleId}
        isOpen={this.props.showNRC}
        onDismiss={() => {
            this.props.closeNRC(false);
          }}
        isBlocking={false}
      >
        <div className="nrcModal" style={{  width: this.state.changeNrcWidth}}>
          <h2 style={{marginTop:this.state.marginTop}}id={this.titleId}>Add NRC ({this.displayCurrentMonthNRC()})</h2>
          <TextField
            label="Supplier Engagement Id"
            id="seid"
            type="number"
            autoComplete="off"
            name="seid"
            className="nrcTextBoxes"
            onGetErrorMessage={this._getErrorMessage}
            onChange={this.onSupplierEngagementChange}
            styles={{
              fieldGroup: { margin: '5px 0px 0px 0px' },
            }}
            validateOnLoad={false}
            required={true}
          />
          <Dropdown
            ariaLabel="Type"
            placeholder="Select type"
            id="nrcType"
            className="nrcDropdowns"
            label="Type"
            onChange={(e, o) => this.onNrcTypeDropdownChange(o)}
            options={this.NRCTypeOptions()}
            style={{ marginRight: '1vw', marginBottom: '2vh' }}
            selectedKey={this.state.selectedNRCTypeKey}
            required={true}
          />
          <Dropdown
            ariaLabel="Category"
            placeholder="Select category"
            id="nrcCategory"
            label="Category"
            className="nrcDropdowns"
            onChange={(e, o) => this.onNrcCategoryDropdownChange(o)}
            options={this.NRCCategoryOptions()}
            style={{ marginRight: '1vw', marginBottom: '2vh' }}
            selectedKey={this.state.selectedNRCCategoryKey}
            required={true}
          />
          {this.state.isEditDescription ? (
            <TextField
              label="Description"
              ariaLabel="Description"
              styles={{
                fieldGroup: { margin: '5px 0px 0px 0px' },
              }}
              className="nrcDropdowns"
              defaultValue={this.textFieldplaceholderNrc}
              onRenderSuffix={this.editDescriptionPrefix}
              onChange={this.onNrcDescriptionChange}
              componentRef={textFieldRef}
              required={true}
            />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Dropdown
                ariaLabel="Description"
                placeholder="Select description"
                id="nrcDescription"
                label="Description"
                className="nrcDropdowns"
                options={this.onNrcDescriptionOptions()}
                onRenderOption={this._onRenderOption}
                onChange={(e, o) => this.onNrcDescriptionDropdownChange(o)}
                style={{ marginRight: '1vw', marginBottom: '2vh' }}
                disabled={!this.props.isDescriptionFetched}
                componentRef={dropDownRef}
                selectedKey= {this.selectedDescriptionKey} 
                required={true}
              />
            </div>
          )}
          <TextField
            label={`Units ${this.state.currencyForNrc}`}
            id="billableUnits"
            type="number"
            autoComplete="off"
            className="nrcTextBoxes"
            name="billableUnits"
            onChange={this.onNRCBillableUnitsChange}
            styles={{
              fieldGroup: { margin: '5px 0px 0px 0px' },
            }}
            required={true}
          />
          <div className="addNrcButtonGroup">
            <DefaultButton
              data-automation-id="cancelBtn"
              text="Cancel"
              aria-label='Cancel'
              onClick={() => {
                this.props.closeNRC(false);
              }}
              style={{ margin: '10px' }}
            />
            <PrimaryButton
              data-automation-id="submitBtn"
              role="button"
              aria-label='Submit'
              text="Submit"
              allowDisabledFocus
              disabled={this.state.isEditDescription || this.state.nrcSeid === 0 || this.state.currencyForNrc === '' || this.state.selectedNRCCategoryKey === 0 || this.state.selectedNRCTypeKey === 0 || this.state.selectedNRCDescription === '' || this.state.nrcUnits === 0}
              onClick={() => this.onClickSubmitNRC()}
              style={{ float: 'right', margin: '10px 0px' }}
            />
          </div>
          </div>
      </Modal>
    );
  }

  //private renderSEID = ():JSX.Element =>{
  //  return(
  //      <TextField
  //          label="Supplier Engagement Id"
  //          id="seid"
  //          type="number"
  //          autoComplete="off"
  //          name="seid"
  //          className="nrcTextBoxes"
  //          onGetErrorMessage={this._getErrorMessage}
  //          onChange={this.onSupplierEngagementChange}
  //          styles={{
  //            fieldGroup: { margin: '5px 0px 0px 0px' },
  //          }}
  //          validateOnLoad={false}
  //      />
  //  );
  //};

  
  //Function to display the month in NRC Modal
  private displayCurrentMonthNRC = () => {
    const { monthList } = this.props;
    for (let i = 0; i < monthList.length; i++) {
      if (monthList[i].CumMonth === this.props.selectedCumMonth) {
        return monthList[i].PeriodDetail;
      }
    }
  };

  //Function to display error message for supplier engagement id in NRC modal
  private _getErrorMessage = (value: string): string => {
    let errorMessage: string = '';
    let seid: number[] = [];
    for (let i = 0; i < this.props.units.length; i++) {
      seid[i] = this.props.units[i].SupplierEngagementId;
    }
    if(value.length === 0){
      errorMessage = '';
    }
    if (seid.indexOf(Number(value)) !== -1) {
      let index = seid.indexOf(Number(value));
      errorMessage = '';
      //this.isSeidExists = true;
      this.setState({
        currencyForNrc: `(1 Unit = 1 ${this.props.units[index].CurrencyCode})`,
      });
    }
     else {
      errorMessage = `SEID doesn't exists`;
      this.setState({ currencyForNrc: '' });
    }
    return errorMessage;
  };

  //Function for onChange supplier engagement id (NRC modal)
  private onSupplierEngagementChange = (e, newValue) => {
    let seid: number = Number(newValue);
    let seidList: number[] = [];
    for (let i = 0; i < this.props.units.length; i++) {
      seidList[i] = this.props.units[i].SupplierEngagementId;
    }
    if (seidList.indexOf(Number(newValue)) !== -1) {
        this.isSeidExists = true;
    }
    this.props.isNRCDescriptionFetched(false);
    this.setState({
            nrcSeid: seid,
            isEditDescription: false,
            selectedNRCType: '',
            selectedNRCTypeKey: -1,
            selectedNRCCategory: '',
            selectedNRCCategoryKey: -1,
        });
    this.selectedDescriptionKey = -1;
  };

  //Function for NRC Type dropdown change
  private onNrcTypeDropdownChange = (o: IDropdownOption) => {
    this.setState({
      selectedNRCType: o.text,
      selectedNRCTypeKey: Number(o.key),
    });
  };

  //Function for NRC Type dropdown options
  private NRCTypeOptions = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    const { nrcTypes } = this.props;
    nrcTypes.some((x) => {
        if (this.isSeidExists && x.IsActive) {
        options.push({
          key: x.TypeId,
          text: x.Type,
        });
      }
      return null;
    });
    return options;
  };

  //Function for populating NRC category dropdown options
  private NRCCategoryOptions = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    const { nrcCategories } = this.props;
    nrcCategories.some((x) => {
      if (this.state.selectedNRCTypeKey === x.TypeId && x.IsActive) {
        options.push({
          key: x.CategoryID,
          text: x.Category,
        });
      }
      return null;
    });
    return options;
  };

  //Function for NRC Description dropdown options
  private onNrcDescriptionOptions = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];
    const { nrcDescription } = this.props;
    nrcDescription.forEach((x) => {
      if (
        x.SupplierEngagementId === this.state.nrcSeid &&
        x.NRCTypeId === this.state.selectedNRCTypeKey &&
        x.NRCCategoryId === this.state.selectedNRCCategoryKey &&
        x.IsActive
      ) {
        options.push({
          key: x.Description,
          text: x.Description,
        });
      }
    });
    options.push({
      key: 'Add',
      text: 'Add',
    });
    return options;
  };

  //Function for NRC Type dropdown change
  private onNrcCategoryDropdownChange = (o: IDropdownOption) => {
    this.setState({
      selectedNRCCategory: o.text,
      selectedNRCCategoryKey: Number(o.key),
      isEditDescription: false, 
    });
    this.props.getNRCDescription(this.state.nrcSeid);
  };

  //Function to render save button for editing Description textfield
  private editDescriptionPrefix = () => {
    return (
      <div className="saveButton">
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          onClick={this.onCancelEditDescription}
          ariaLabel="Cancel"
        />
        <IconButton
          iconProps={{ iconName: 'Save' }}
          onClick={this.onSaveEdittedDescription}
          ariaLabel="Save"
          disabled={
            this.textFieldplaceholderNrc === '' ||
            this.textFieldplaceholderNrc === null
          }
        />
      </div>
    );
  };

  //Function to remove edit box when the user does not want to save the description
  private onCancelEditDescription = () => {
    this.selectedDescriptionKey = -1;
    this.setState({ isEditDescription: false}, () => {
      dropDownRef.current.focus();
    });    
  };

  //Function to save the editted NRC Description
  private onSaveEdittedDescription = () => {
    let saveNRCDescription: INrcDescription = {
      NRCId: 0,
      SupplierEngagementId: 0,
      NRCTypeId: 0,
      NRCCategoryId: 0,
      Description: '',
      AnaplanNRCId: '',
      IsActive: true,
    };
    if (
      this.props.nrcDescription
        .map((x) => {
          return x.Description.toLowerCase();
        })
        .includes(this.textFieldplaceholderNrc.toLowerCase())
    ) {
      alert('Description already exists');
    } else {
      const { nrcDescription } = this.props;
      for (let i = 0; i < nrcDescription.length; i++) {
        if (nrcDescription[i].Description === this.selectedDescriptionKey) {
          saveNRCDescription.SupplierEngagementId = this.state.nrcSeid;
          saveNRCDescription.NRCTypeId = this.state.selectedNRCTypeKey;
          saveNRCDescription.NRCCategoryId = this.state.selectedNRCCategoryKey;
          saveNRCDescription.Description = this.textFieldplaceholderNrc;
          saveNRCDescription.IsActive = true;
          saveNRCDescription.NRCId = nrcDescription[i].NRCId;
          saveNRCDescription.AnaplanNRCId = nrcDescription[i].AnaplanNRCId;
        }
      }

      if (this.isNewDescription) {
        saveNRCDescription.NRCId = null;
        saveNRCDescription.AnaplanNRCId = null;
        saveNRCDescription.SupplierEngagementId = this.state.nrcSeid;
        saveNRCDescription.NRCTypeId = this.state.selectedNRCTypeKey;
        saveNRCDescription.NRCCategoryId = this.state.selectedNRCCategoryKey;
        saveNRCDescription.Description = this.textFieldplaceholderNrc;
        saveNRCDescription.IsActive = true;
        this.isNewDescription = false;
      }
      this.props.saveNewOrEdittedNRCDescription(
        this.props.fiscalYear,
        saveNRCDescription
      );
    }
    delay(3000).then(() => {
      this.onCancelEditDescription();
    });
  };

  //Function for onChange units (NRC modal)
  private onNRCBillableUnitsChange = (e, newValue) => {
    this.setState({ nrcUnits: Number(newValue) });
  };

  //Function for onChange NRC Description
  private onNrcDescriptionChange = (e, newValue) => {
    this.textFieldplaceholderNrc = newValue;
  };

  //Function to render options for NRC Description dropdown
  private _onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        {option.key === 'Add' ? (
          <ActionButton
            style={{ height: '20px' }}
            className="addIcon"
            iconProps={{ iconName: 'Add' }}
          >
            Add New
          </ActionButton>
        ) : (
          <span>{option.text}</span>
        )}
      </div>
    );
  };

  //Function for NRC Description dropdown change
  private onNrcDescriptionDropdownChange = (o: IDropdownOption) => {
    this.setState({ selectedNRCDescription: o.text });
    this.setState({ isNRCDescriptionSelected: true});
    this.textFieldplaceholderNrc = o.text;
    this.selectedDescriptionKey = o.key;
    if (o.key === 'Add') {
      this.isNewDescription = true;
        this.setState({ isEditDescription: true }, () => {
            textFieldRef.current.focus();
        });
      this.textFieldplaceholderNrc = '';
    }
  };

  //Function to set the state to show the edit box on edit button click
  private onEditClick = () => {
    this.setState({ isEditDescription: true }, () => {
      textFieldRef.current.focus();
    });
  };

  //Function to submit the NRC details
  private onClickSubmitNRC = () => {
    let nrcDetails: ISubmitNRCDetails = {
      SupplierEngagementId: 0,
      FiscalYearNumber: 0,
      CumMonth: 0,
      PostCumMonth: 0,
      BillableUnits: 0,
      Comment: '',
      NRCId: 0,
    };
    nrcDetails.SupplierEngagementId = this.state.nrcSeid;
    nrcDetails.FiscalYearNumber = this.props.fiscalYear;
    nrcDetails.CumMonth = this.props.selectedCumMonth;
    nrcDetails.PostCumMonth = this.props.selectedCumMonth;
    nrcDetails.BillableUnits = this.state.nrcUnits;
    this.props.nrcDescription.forEach((x) => {
      if (x.Description === this.state.selectedNRCDescription) {
        nrcDetails.NRCId = x.NRCId;
      }
    });
    this.props.submitNRCDetails(nrcDetails);
    this.props.closeNRC(false);
    this.props.isNRCDescriptionFetched(false);
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNRCTypes: () => {
      dispatch(getNRCTypes());
    },
    getNRCCategories: () => {
      dispatch(getNRCCategories());
    },
    getNRCDescription: (supplierEngagementId: number) => {
      dispatch(getNRCDescription(supplierEngagementId));
    },
    saveNewOrEdittedNRCDescription: (
      fiscalYearNumber: number,
      nrcDescriptionDetails: INrcDescription
    ) => {
      dispatch(
        saveNewOrEdittedNRCDescription(fiscalYearNumber, nrcDescriptionDetails)
      );
    },
    submitNRCDetails: (nrcDetails: ISubmitNRCDetails) => {
      dispatch(submitNRCDetails(nrcDetails));
    },
    isNRCDescriptionFetched: (isDescriptionFetched: boolean) =>{
      dispatch(isNRCDescriptionFetched(isDescriptionFetched));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    monthList: state.headCount.monthList,
    units: state.headCount.billableUnits,
    nrcTypes: state.headCount.nrcTypes,
    nrcCategories: state.headCount.nrcCategories,
    nrcDescription: state.headCount.nrcDescription,
    isDescriptionFetched: state.headCount.isDescriptionFetched,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNRC);
