import * as React from 'react';
import { Stack} from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Toggle } from '@fluentui/react/lib/Toggle';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode
} from '@fluentui/react/lib/DetailsList';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption, IDropdown } from '@fluentui/react';
import {
  IconButton,
} from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import { TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import appConfig from '../../assets/configuration/config';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoApiNewInstance, { gtoMsalApiFetch } from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { delay } from 'q';
import { css } from 'glamor';
import {  ScrollablePane } from 'office-ui-fabric-react';
import HelmetTab from "../../shared/Helmet/Helmet";
import { getUserRoles } from '../../store/actions/user.action';
import { connect } from 'react-redux';


export interface ISuppliersSitePageProps {
  getUserRoles: any;  
  IsInvoicingManager: boolean;
  IsSupplierSupportManager:boolean;
}

export interface ISuppliersSiteState {
  showPendingPanel: boolean;
  showaddpanel:boolean;
  showeditpanel:boolean;
  showfilterpanel:boolean;
  selectionDetails: any;
  items: [];
  supplierSiteList:any
  getPendingRecordsToggle:boolean;
  supplierSiteListActive:any;
  allSuppliersToggle:boolean;
  activestatelist:any;
  getPendingRecordsList:any;
  getPendingRecordsListSelected:any;
  _edituserItems:any;
  itemSelected:any;
  panelflag: any;
  filterOptions: IDropdownOption[];
  suppliersitedropdown:IDropdownOption[];
  suppliersitedropdownAddorEdit:IDropdownOption[];
  fiscalyearsdropdown:IDropdownOption[];
  selectedFiscalYear:any;
  selectedSupplier:any;
  suppliersSiteCitiesDropdown:IDropdownOption[];
  showsuppliercontacts:boolean;
  suppliersites:'',
  supplierId:any;
  supplierName:any,
  supplierSiteId:'',
  supplierRequestId:'',
  supplierSiteRequestId:'',
  supplierCityId:any,
  supplierCityName:any,
  supplierSite:'',
  oneditSupplierId:'',
  oneditSupplierIdWithName:'',
  oneditSupplierSiteId:'',
  oneditSupplierRequestId:'',
  oneditSupplierSiteRequestId:'',
  oneditSupplierCityId:'',
  oneditSupplierCityIdWithName:'',
  oneditSupplierSite:'',
  supplierIdmain:any,
  onChangeSelectedFiscalYear:any,
  disabledit:boolean
}

class Suppliersites extends React.Component<ISuppliersSitePageProps, ISuppliersSiteState> {
  private _selection: Selection;
  private _allItems: any;
  private _columns: IColumn[];
  private indexSelected: number = -1;


  constructor(props) {
    super(props);
    this.props.getUserRoles();

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetails();
      },
    });
    this._columns = [
      {
        key: 'column1',
        name: 'Supplier',
        fieldName: 'SupplierName',
        minWidth: 150,
        maxWidth: 300,
        isResizable: true,
        onRender: this.renderSupplierNameWithCC,

      },
      {
        key: 'column2',
        name: 'Supplier City',
        fieldName: 'SupplierCity',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'Country',
        fieldName: 'SupplierCountry',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Active',
        fieldName: 'IsActive',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
      },
      {
        key: 'column5',
        name: 'Supplier Engagements',
        fieldName: 'SupplierEngagements',
        minWidth: 400,
        maxWidth: 650,
        isResizable: true,
      },
      {
        key: 'column6',
        name: 'Action',
        fieldName: 'action',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        onRender: this.renderaction,
      },
     
    ];

    this.state = {
      items: this._allItems,
      itemSelected:[],
      selectionDetails: this._getSelectionDetails(),
      showPendingPanel: false,
      panelflag: '',
      suppliersites:'',
      filterOptions: [],
      activestatelist:[],
      supplierSiteList:[],
      supplierSiteListActive:[],
      getPendingRecordsToggle:false,
      allSuppliersToggle:false,
      getPendingRecordsList:[],
      getPendingRecordsListSelected:[],
      suppliersitedropdown:[],
      fiscalyearsdropdown:[],
      selectedFiscalYear:'',
      selectedSupplier:'All',
      suppliersSiteCitiesDropdown:[],
      showsuppliercontacts:false,
      _edituserItems:'',
      showaddpanel:false,
      showeditpanel:false,
      showfilterpanel:false,
      supplierId:'',
      supplierName:'',
      supplierSiteId:'',
      supplierRequestId:'',
      supplierSiteRequestId:'',
      supplierCityId:'',
      supplierCityName:'',
      supplierSite:'',
      oneditSupplierId:'',
      oneditSupplierSiteId:'',
      oneditSupplierRequestId:'',
      oneditSupplierSiteRequestId:'',
      oneditSupplierCityId:'',
      oneditSupplierSite:'',
      supplierIdmain:0,
      onChangeSelectedFiscalYear:'',
      disabledit:true,
      suppliersitedropdownAddorEdit:[],
      oneditSupplierIdWithName:'',
      oneditSupplierCityIdWithName:''
    };
  }
  
  public componentDidMount(){
    this.loadFiscalYears();
    this.getSupplierSiteList();
    this.getPendingRecordsListFn();
  }


  getSupplierSiteList = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      var suppliersites =[];
         suppliersites =[{
          key:0,
          text:'All',
          sid:0,
          supplierName:'All'
        }]      

      // Defining the url

      url = Routes.getSuppliers;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierSiteList, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
      })
        .then((response: any) => {
         var   x = response.data.map((x) => ({
            key: x.supplierId,
            text: `${x.supplierName} - (CC:${x.companyCode}, VN:${x.supplierNumber})`,
            sid:x.supplierId,
            supplierName:x.supplierName
          }));
          // Array.prototype.push.apply(suppliersites,x); 
          suppliersites = suppliersites.concat(x)

          this.setState({
            suppliersitedropdown: suppliersites,
            suppliersitedropdownAddorEdit:x
          })
          // Mapping the response data.
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierSiteListFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch user roles.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  
  loadFiscalYears = () =>{
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      // Defining the url
      const url: string = `${Routes.GetAvailableFiscalYears}
      `;
      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchAvailableFiscalYears,
        {
          headers,
          url,
        }
      );
       gtoMsalApiFetch(gtoApiNewInstance.get, url, { headers })
        .then((response: any) => {
          let fiscalyears = response.data.fiscalYearNumbers.map((x,key) => ({
            key: x.key,
            text: x,
          }));
          this.setState({
            fiscalyearsdropdown:fiscalyears,
            selectedFiscalYear:response.data.currentFiscalYearNumber           
            // Mapping the response data.
          },() => this.getSupplierSite() )

        if(response.data && response.status === 200){
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchCurrentFiscalYearForSupplierSite,
            {
              headers,
              url,
            }
          );
        }
        })
        .catch((error) => {
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchCurrentFiscalYearForSupplierSiteFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error(
              'Fiscal Year information is not available ',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }
        });
    }
}
getPendingRecordsListFn = () =>{
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    // Defining the url
    const url: string = `${Routes.getPendingSupplierSiteRecords}
    `;
    // Logging telemetry
    telemetryContext.logEvent(
      TelemetryEvent.FetchSupplierSitePendingRecords,
      {
        headers,
        url,
      }
    );
     gtoMsalApiFetch(gtoApiNewInstance.get, url, { headers })
      .then((response: any) => {

        this.setState({
          getPendingRecordsList:response.data
        })
      
      if(response.data && response.status === 200){
        // Logging success
        telemetryContext.logEvent(
          TelemetryEvent.FetchSupplierSitePendingRecords,
          {
            headers,
            url,
          }
        );
      }
      })
      .catch((error) => {
        telemetryContext.logException(
          error.toString(),
          TelemetryException.FetchSupplierSitePendingRecordsFailure,
          undefined,
          {
            headers,
            url,
          }
        );

        if (error.response && error.response.status === 404) {
          toast.error(
            'Unable to fetch pending requests ',
            {
              className: css({
                background: '#a80000 !important',
              }),
            }
          );
        }
      });
  }
}

loadSupplierSiteCities = () =>{
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    // Defining the url
    const url: string = `${Routes.getSupplierSiteCity}`;
    // Logging telemetry
    telemetryContext.logEvent(
      TelemetryEvent.FetchSuppliersSiteCities,
      {
        headers,
        url,
      }
    );
     gtoMsalApiFetch(gtoApiNewInstance.get, url, { headers })
      .then((response: any) => {
        let citiesList = response.data.map((x,key) => ({
          key: x.CityId,
          text: x.City,
        }));
        this.setState({
          suppliersSiteCitiesDropdown:citiesList,
        })
      if(response.data && response.status === 200){
        // Logging success
        telemetryContext.logEvent(
          TelemetryEvent.FetchSuppliersSiteCities,
          {
            headers,
            url,
          }
        );
      }
      })
      .catch((error) => {
        telemetryContext.logException(
          error.toString(),
          TelemetryException.FetchSuppliersSiteCitiesFailure,
          undefined,
          {
            headers,
            url,
          }
        );

        if (error.response && error.response.status === 404) {
          toast.error(
            'supplier cities information is not available ',
            {
              className: css({
                background: '#a80000 !important',
              }),
            }
          );
        }
      });
  }
}
  
  getSupplierSite = () =>{
    //checking if app is using mock data
    if(appConfig.UseMockData === 'false'){
      //creating headers for telemetry
      const headers:object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey : uuidv4(),
      };
      
      //Defining the url 
      let url : string = Routes.getSupplierSiteList+`?fiscalYearNumber=${this.state.selectedFiscalYear}&supplierId=${this.state.supplierIdmain}`;      

      //Logging telemetry for SupplierSite api call.
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierSiteList,{
        url,
        headers,
      })

      //Making the get call
      return gtoMsalApiFetch(gtoApiNewInstance.get,url,{
        headers,
      })
      .then((response:any)=>{
        this.setState({
          supplierSiteList:response.data,
          activestatelist : response.data.filter(x => x.IsActive === true),
          supplierSiteListActive:response.data.filter(x=> x.IsActive === true && x['SupplierSiteRequestId'] === 0),
          // Mapping the response data.
        })

      })
      .catch((error)=>{
        //Log Exeception
        telemetryContext.logException(
          error.toString(),
        TelemetryException.FetchSupplierSiteListFailure,
        undefined,
        {
          url:url,
          headers,
        }
      );
      //Error handling 
      if(error.response && error.response.status === 404){
        toast.error('unable to fetch supplier site',{
          className:css({
            background: '#a80000 !important',
          }),
        });
      }
      throw error;
    });
  } else{
    //Setting mock data.
    return delay(2000).then(()=>{
      this.setState({
        //items:SupplierSiteMockData
      })
    });
  }

  };

  addSupplierSite = (value) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.addSupplierSite}`;
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.AddSupplierSite, {
        headers,
        url,
      });
      var body= {};
        if(value === 'add'){
        if(this.state.supplierName === '' || this.state.supplierCityName === ''
          || this.state.supplierCityId === ''  || this.state.supplierId === ''){
            return
        }
       body = {
          "supplierName": this.state.supplierName,
          "supplierCity": this.state.supplierCityName,
          "supplierCountry": "",
          "isActive": true,
          "supplierEngagements": "",
          "supplierCityId": this.state.supplierCityId,
          "supplierId": this.state.supplierId,
          "supplierSite": "",
          "supplierSiteID": 0,
          "supplierSiteRequestId": 0,
          "isApproved": false,
          "supplierRequestId": 0
        }
     
    }else if(value === 'edit'){
      if(this.state.oneditSupplierId === '' || this.state.oneditSupplierCityId === '')
     {
        return
    }
  
    body = {
      "supplierName": this.state.oneditSupplierIdWithName,
      "supplierCity": this.state.oneditSupplierCityIdWithName,
      "supplierCountry": this.state.itemSelected.SupplierCountry,
      "isActive": this.state.itemSelected.IsActive,
      "supplierEngagements": this.state.itemSelected.SupplierEngagements,
      "supplierCityId": this.state.oneditSupplierCityId,
      "supplierId": this.state.oneditSupplierId,
      "supplierSite": this.state.itemSelected.SupplierSite,
      "supplierSiteID": this.state.itemSelected.SupplierSiteID,
      "supplierSiteRequestId": this.state.itemSelected.SupplierSiteRequestId,
      "isApproved": this.state.itemSelected.IsApproved,
      "supplierRequestId": this.state.itemSelected.SupplierRequestId
    }
    }
      return gtoMsalApiFetch(gtoApiNewInstance, url, {
        data: body,
        headers,
        method: 'post',
      })
        .then((response: any) => {
          this._selection.setAllSelected(false)
          // Logging success
          telemetryContext.logEvent(TelemetryEvent.AddSuppliers, {
            headers,
            url,
          });
          this.setState({
            showeditpanel:false,
            showaddpanel:false,
            supplierIdmain:0,
            getPendingRecordsToggle:false
          },() => this.getSupplierSite());
          this.getPendingRecordsListFn();
  
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });
        })
        .catch((error) => {
          this.setState({
            showeditpanel:false,
            showaddpanel:false,
            getPendingRecordsToggle:false
          })
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.AddSuppliersFailure,
            undefined,
            {
              headers,
              url,
            }
          );
  
        });
    } else {
      return delay(2000).then(() => {
      });
    }
  };

  archiveUnArchiveSupplier = (value,flag) =>{
    //checking if app is using mock data
    if(appConfig.UseMockData==='false'){
      var rolesformat =[]
      //creating headers for telemetry
      const headers : object ={
        'X-CorrelationId':uuidv4(),
        SubCorrelationKey : uuidv4(),
      };
      let url:string;
      // Defining the url
      url = Routes.archiveSupplierSite +`?supplierSiteId=${this.state.itemSelected.SupplierSiteID}&isArchive=${value}`;

      //Logging telemetry for NRC Categories api call
      telemetryContext.logEvent(TelemetryEvent.ArchiveSupplierSite,{
        url,
        headers,
      });

      //Making the get call
      return gtoMsalApiFetch(gtoApiNewInstance.get,url,{
        headers,
        method:'PUT',
      })
      .then((response:any)=>{
        this._selection.setAllSelected(false);
        this.setState({
          getPendingRecordsToggle:false,
          allSuppliersToggle:false
        })
        this.getSupplierSite();

        //Mapping the response data.
        toast.success(response.data,{
          className:css({
            background: '#107c10 !important',
          }),
        });
      })
      .catch((error)=>{
        //Log Exception
        telemetryContext.logException(
          error.toString(),
          TelemetryException.ArchiveSupplierSiteFailure,
          undefined,{
            url:url,
            headers,
          }
        );
        //Error handling
        if(error.response && error.response.status === 404){
          toast.error('Unable to archive/un archive',{
            className : css({
              background: '#a80000 !important',
            }),
          });
        }
        throw error;
      });
    }else{
      //setting mock data.
      return delay(2000).then(()=>{});
    }
  };

  approveOrRejectSupplier = (value) =>{
    //checking if app is using mock data
    if(appConfig.UseMockData==='false'){
      var rolesformat =[]
      //creating headers for telemetry 
      const headers:object={
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey:uuidv4(),
      };
      let url:string;
      var telemetrySuccess:string;
      var telemetryFailure:string;
      if(value==='approve'){
        //Defining the url
        url =Routes.approveSupplierSite +`?supplierSiteRequestId=${this.state.getPendingRecordsListSelected.SupplierSiteRequestId}`;
        telemetrySuccess = TelemetryEvent.ApproveSupplierSite;
        telemetryFailure = TelemetryException.ApproveSupplierSiteFailure
      }else if(value === 'reject'){
        // Defining the url
        url =Routes.rejectSupplierSite +`?supplierSiteRequestId=${this.state.getPendingRecordsListSelected.SupplierSiteRequestId}`;
        telemetrySuccess = TelemetryEvent.RejectSupplierSite;
        telemetryFailure = TelemetryException.RejectSupplierSiteFailure
      }
      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(telemetrySuccess, {
        url,
        headers,
      });
      // Making the get call.
      return gtoMsalApiFetch(gtoApiNewInstance.get, url, {
        headers,
        method: 'PUT',
      })
        .then((response: any) => {
          this._selection.setAllSelected(false)

          this.setState({
            getPendingRecordsToggle:false,
            supplierIdmain:0
          },() =>this.getSupplierSite())
            this.getPendingRecordsListFn()

          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            telemetryFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
           // Error handling.
           if (error.response && error.response.status === 404) {
            toast.error('Unable to approve/reject supplier site', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  onChangeAllSuppliersToggle = (e) => {
    const temp = this.state
    this.setState({
      getPendingRecordsToggle:false
    })
    this._selection.setAllSelected(false)
    if(temp.supplierSiteList.length > 0){
    if(!this.state.allSuppliersToggle){
          this.setState({
            allSuppliersToggle:!this.state.allSuppliersToggle,
            activestatelist:this.state.supplierSiteList.filter(x=> x['supplierSiteRequestId'] !== 0),
          })
    }else{
      this.setState({
        allSuppliersToggle:!this.state.allSuppliersToggle,
        activestatelist:this.state.supplierSiteListActive,
      })  
    }

   }
  }
  onChangeGetPendingRecordsToggle = (e) => {
    // this.getPendingRecordsListFn();
    this.setState({
      allSuppliersToggle:false
    })
      this._selection.setAllSelected(false)

    if(!this.state.getPendingRecordsToggle){
          this.setState({
            getPendingRecordsToggle:!this.state.getPendingRecordsToggle,
            activestatelist:this.state.getPendingRecordsList,
          })
    }else{
      this.setState({
        getPendingRecordsToggle:!this.state.getPendingRecordsToggle,
        activestatelist:this.state.supplierSiteListActive,
      })
    }
    }
    
  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    
    switch (selectionCount) {
      case 0:
        this.setState({
          disabledit:true
        })
        return ;
        case 1:
          this.indexSelected = this._selection["_anchoredIndex"]
          this.setState({
            itemSelected:this.state.activestatelist[this.indexSelected],
            disabledit:false,
            getPendingRecordsListSelected:this.state.getPendingRecordsList[this.indexSelected]
          })
          return ;
          default:
            this.setState({
              disabledit: true,
            });
    }

  }


  onclickaddbutton = () =>{
    this.setState({
      showaddpanel:true
    },() => this.loadSupplierSiteCities() )
  }
  ondismissadd = () =>{
    this.setState({
        showaddpanel:false
    })
  }
  onclickeditbutton = () => {
    this.setState({
      showeditpanel: true,
      oneditSupplierId:this.state.itemSelected.SupplierId,
      oneditSupplierCityId:this.state.itemSelected.SupplierCityId,
      oneditSupplierCityIdWithName:this.state.itemSelected.SupplierCity,
      oneditSupplierIdWithName:this.state.itemSelected.SupplierName
    },() => this.loadSupplierSiteCities());
  };
  ondismissEdit = () => {
    this.setState({
        showeditpanel: false,
    });
  };

  private renderaction = (item: any): JSX.Element => {

    // if(item.supplierRequestId === 0){
    if(item.IsActive === true){
    return (
      <TooltipHost
        content={'Archive'}
        id={'Archive'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="archive"
          name="archive"
          aria-label="archive"
          iconProps={{ iconName: 'Archive' }}
          onClick={() => this.archiveUnArchiveSupplier(0,item.isActive)}
          disabled={(this.props.IsSupplierSupportManager || this.props.IsInvoicingManager) && !this.state.getPendingRecordsToggle && !item.IsInUse? false :true}
        />
      </TooltipHost>);
    }else if(item.IsActive === false){
      return (
        <TooltipHost
          content={'Unarchive'}
          id={'Unarchive'}
          calloutProps={{ gapSpace: 0 }}
        >
          <IconButton
            style={{ margin: '5px' }}
            data-automation-id="Unarchive"
            name="Unarchive"
            aria-label="Unarchive"
            iconProps={{ iconName: 'Archive' }}
            onClick={() => this.archiveUnArchiveSupplier(1,item.isActive)}
            disabled={(this.props.IsSupplierSupportManager || this.props.IsInvoicingManager) && !this.state.getPendingRecordsToggle && !item.IsInUse ? false :true}
          />
        </TooltipHost>);
        }
  };
  private renderSupplierNameWithCC = (item: any): JSX.Element => {
    return (
      <span>{`${item.SupplierName} - (CC:${item.CompanyCode}, VN:${item.SupplierNumber})`}</span>
    );
  };
  private onChangeSupplierSiteDropdown = (o:any) => {
    this.setState({
      supplierId:Number(o.sid),
      supplierName:o.supplierName,
      oneditSupplierId:o.sid,
      oneditSupplierIdWithName:o.supplierName
        })
  }
  private onChangeSupplierSiteCity = (o:any) => {
    this.setState({
      supplierCityId:o.key,
      supplierCityName:o.text,
      oneditSupplierCityId:o.key,
      oneditSupplierCityIdWithName:o.text
    })
  }
  private onChangeSupplierSiteDropdownMain = (o:any) => {
    this._selection.setAllSelected(false);
    this.setState({
      supplierIdmain:o.sid,
      getPendingRecordsToggle:false,
      allSuppliersToggle:false
        },() => this.getSupplierSite())
  }
  private onChangeFiscalYearDropdown = (o:any) => {
    this._selection.setAllSelected(false);
    this.setState({
      selectedFiscalYear:o.text,
      getPendingRecordsToggle:false,
      allSuppliersToggle:false
        },() => this.getSupplierSite())
  }



  render() {
    return (
      <React.Fragment>
      <div style={{height:'100%'}}>
      <HelmetTab title={'Supplier Site'} />
        <h1
          style={{ padding: '10px', fontSize: '18.72px' }}
          aria-label={'Suppliers'}
        >
          Supplier Sites
        </h1>
        <Stack>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row" style={{ marginLeft: '10px' }}>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg6">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg6">
              <Dropdown
                    placeholder={this.state.selectedSupplier !== undefined ?`${this.state.selectedSupplier}`: "Select Supplier"}
                    ariaLabel="Supplier"
                    options={this.state.suppliersitedropdown}
                    required={false}
                    onChange={(e,o) => this.onChangeSupplierSiteDropdownMain(o)}
                    selectedKey={this.state.supplierIdmain}
                  />               
                   </div>
               
                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <Dropdown
                placeholder={this.state.selectedFiscalYear !== undefined ?`${this.state.selectedFiscalYear}`: "Fiscal Year"}
                ariaLabel="Fiscal Year"
                    options={this.state.fiscalyearsdropdown}
                    defaultSelectedKey={this.state.selectedFiscalYear}
                    required={false}
                    onChange={(e,o) => this.onChangeFiscalYearDropdown(o)}
                  />              
                </div>
              </div>
              
            </div>
          </div>

          <div className="ms-Grid" dir="ltr" style={{marginTop:'10px'}}>
            <div
              className="ms-Grid-row "
              style={{ marginLeft: '10px', margin: '10px' }}
            >
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg8">
                <PrimaryButton
                  text="Add"
                  iconProps={{ iconName: 'Add' }}
                  style={{ marginRight: '5px' }}
                  disabled={this.props.IsSupplierSupportManager || this.props.IsInvoicingManager ? false : true}
                  onClick={() => this.onclickaddbutton()}
                />

                <PrimaryButton
                  text="Edit"
                  iconProps={{ iconName: 'EditNote' }}
                  style={{ marginRight: '5px',marginLeft:'5px'}}
                  onClick={() => this.onclickeditbutton()}
                  disabled={ ((this.state.itemSelected.IsInUse && !this.props.IsSupplierSupportManager || !this.props.IsInvoicingManager) && !this.state.getPendingRecordsToggle && this.state.disabledit) ? true : ((!this.props.IsSupplierSupportManager || !this.props.IsInvoicingManager) && this.state.itemSelected.IsInUse && (this.state.getPendingRecordsToggle && !this.state.disabledit ) ? true :
                   ( (this.props.IsSupplierSupportManager || this.props.IsInvoicingManager) && !this.state.itemSelected.IsInUse && !this.state.getPendingRecordsToggle && !this.state.disabledit ) ? false :true)}

                />
                {this.props.IsInvoicingManager && this.state.getPendingRecordsToggle && 
                <PrimaryButton
                  text="Approve"
                  iconProps={{ iconName: 'Accept' }}
                  style={{ marginRight: '5px',marginLeft:'5px'}}
                  disabled={this.state.getPendingRecordsToggle ? this.state.disabledit :this.state.disabledit}
                  onClick={() => this.approveOrRejectSupplier('approve')}
                />
                 } 
                {this.props.IsInvoicingManager && this.state.getPendingRecordsToggle &&  
                <PrimaryButton
                  text="Reject"
                  disabled={this.state.disabledit}
                  style={{ marginLeft:'5px'  }}
                  iconProps={{ iconName: 'Cancel' }}
                  onClick={() => this.approveOrRejectSupplier('reject')}
                />
                } 
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
              <Toggle label="Get Pending Records" inlineLabel
                 onChange={(e) =>this.onChangeGetPendingRecordsToggle(e)}
                 onAriaLabel="On"
                 offAriaLabel="Off"
                 defaultChecked={false}
                 checked={this.state.getPendingRecordsToggle}
                 role="switch" />
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                 <Toggle label="All Supplier Sites" inlineLabel
                 onChange={(e) =>this.onChangeAllSuppliersToggle(e)}
                 onAriaLabel="On"
                 offAriaLabel="Off"
                 defaultChecked={false}
                 checked={this.state.allSuppliersToggle}
                 role="switch" />
              </div>
            </div>
          </div>

          <Panel
          isOpen={this.state.showaddpanel}
          onDismiss={this.ondismissadd}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          headerText={' Add Supplier Sites'}>

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Dropdown
                    label="Supplier"
                    placeholder="Supplier"
                    ariaLabel="Supplier"
                    options={this.state.suppliersitedropdownAddorEdit}
                    onChange={(e,o) => this.onChangeSupplierSiteDropdown(o)}

                    required={true}
                  />                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    label="Supplier City"
                    placeholder="Supplier City"
                    ariaLabel="Supplier City"
                    options={this.state.suppliersSiteCitiesDropdown}
                    onChange={(e,o) => this.onChangeSupplierSiteCity(o)}
                    required={true}
                  />
                </div>
                <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissadd()
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  onClick={() => this.addSupplierSite('add')}
                  style={{ float: 'right', margin: '10px 0px' }}
                  disabled={(this.state.supplierId !== '' && this.state.supplierCityId !=='') ? false : true}
                />
              </div>
            </div>
            </Panel>
            <Panel
          isOpen={this.state.showeditpanel}
          onDismiss={this.ondismissEdit}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          headerText={' Edit Supplier Sites'}>

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Dropdown
                    label="Supplier"
                    placeholder="Supplier"
                    ariaLabel="Supplier"
                    options={this.state.suppliersitedropdownAddorEdit}
                    onChange={(e,o) => this.onChangeSupplierSiteDropdown(o)}
                    required={true}
                    selectedKey={this.state.oneditSupplierId}
                    disabled={true}

                  />                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    label="Supplier City"
                    placeholder="Supplier City"
                    ariaLabel="Supplier City"
                    options={this.state.suppliersSiteCitiesDropdown}
                    onChange={(e,o) => this.onChangeSupplierSiteCity(o)}
                    selectedKey={this.state.oneditSupplierCityId}
                    required={true}
                  />
                </div>
                <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissEdit()
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  onClick={() => this.addSupplierSite('edit')}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>
            </Panel>

            <div style={{
            height: '70vh',
            position: 'relative',
            marginLeft: '1vw',
          marginTop:'2vh'}}
            >
         <ScrollablePane>
            <DetailsList
                compact={true}
                items={this.state.activestatelist}
                columns={this._columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                selectionMode={SelectionMode.single}
              />
              </ScrollablePane>
          </div>
        </Stack>
      </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserRoles: () => {
      dispatch(getUserRoles());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    IsInvoicingManager: state.user.IsInvoicingManager,
    IsSupplierSupportManager:state.user.IsSupplierSupportManager
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliersites);
