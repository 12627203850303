import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack} from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Toggle } from '@fluentui/react/lib/Toggle';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import {
  IconButton,
} from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import {  ScrollablePane } from 'office-ui-fabric-react';
import HelmetTab from "../../shared/Helmet/Helmet";
import appConfig from '../../assets/configuration/config';
import { v4 as uuidv4 } from 'uuid';
import { TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { delay } from 'q';
import { getUserRoles } from '../../store/actions/user.action';
import { connect } from 'react-redux';



export interface ISupplierPageProps {
  getUserRoles: any;  
  IsInvoicingManager: boolean;
}


export interface ISupplierPageState {
  showPendingPanel: boolean;
  selectionDetails: any;
  supplierList:[];
  panelflag: any;
  showEditPanel: boolean;
  filterOptions: IDropdownOption[];
  showsuppliercontacts:boolean;
  itemSelected:any;
  itemSelectedSupplierContacts:any;
  activestatelist:any;
  allSuppliersToggle:boolean;
  supplierListActive:any;
  supplierMasterOptions:any;
  supplierDiscountTerm:any;
  supplierCurrencyList:any;
  supplierMasterName:any;
  supplierNumber:any;
  supplierLegalName:any;
  supplierCompanyCode:any;
  supplierCurrenyCode:any;
  supplierDiscountTermValue:any;
  supplierDiscountTermID:any;
  taxPercent:any;
  taxCalculatedOn:any;
  supplierContactName:any;
  supplierContactEmail:any;
  supplierName:any;
  getPendingRecordsToggle:boolean;
  getPendingRecordsSupplier:any;
  onclickadd:boolean;
  onclickedit:boolean;
  disabledit:boolean;
  oneditSupplierMasterName:any;
  oneditSupplierNumber:string;
  oneditSupplierLegalName:string;
  oneditSupplierCompanycode:string;
  oneditSupplierCurrencycode:any;
  oneditSupplierName:string;
  oneditDiscountTermId:any;
  oneditDiscountTermValue:any;
  oneditTaxPercent:any;
  oneditTaxCalculatedOn:string;
  oneditSupplierContactName:string;
  oneditSupplierContactEmail:string;
  getPendingRecordsList:any;
  ItemSelectedforEdit:any;
  showAddInvoiceDiv:boolean;
  invoiceContactEmail:any;
  disableEditSave:boolean;
  errorMsg:any;

}

class Suppliers extends React.Component<ISupplierPageProps, ISupplierPageState> {
  private _selection: Selection;
  private _selectionEdit: Selection;
  private _selectionpending: Selection;
  private _allItems: any;
  private _columns: IColumn[];
  private _invoiceContactColumns: IColumn[];
  private indexSelected: number = -1;


  constructor(props) {
    super(props);
    this.props.getUserRoles();

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetails();
      },
    });

    this._columns = [
      {
        key: 'column1',
        name: 'Supplier Name',
        fieldName: 'supplierName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column9',
        name: 'Supplier Legal Name',
        fieldName: 'supplierLegalName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: 'Supplier Number',
        fieldName: 'supplierNumber',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'Company Code',
        fieldName: 'companyCode',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Currency',
        fieldName: 'currencyCode',
        minWidth: 50,
        maxWidth: 80,
        isResizable: true,
      },
      {
        key: 'column5',
        name: 'Discount Term',
        fieldName: 'discountTerm',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column10',
        name: 'Tax Percent (%)',
        fieldName: 'taxPercent',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: 'column11',
        name: 'Tax Calculated On',
        fieldName: 'taxCalculationOn',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
      {
        key: 'column6',
        name: 'Active',
        fieldName: 'isActive',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
      },
      {
        key: 'column7',
        name: 'Supplier Contacts',
        fieldName: 'suppliercontacts',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.renderlink,
      },
      {
        key: 'column8',
        name: 'Action',
        fieldName: 'action',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        onRender: this.renderaction,
      },
    ];

    this._invoiceContactColumns =[
      {
        key: 'column1',
        name: 'Invoice Contact Email',
        fieldName: 'email',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column2',
        name: 'Delete',
        fieldName: 'email',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.renderInvoiceContactDelete,
      },
    ]

    this.state = {
      selectionDetails: this._getSelectionDetails(),
      showPendingPanel: false,
      panelflag: '',
      showEditPanel: false,
      filterOptions: [],
      showsuppliercontacts:false,
      supplierList:[],
      itemSelected:[],
      itemSelectedSupplierContacts:[],
      activestatelist:[],
      allSuppliersToggle:false,
      supplierListActive:[],
      supplierMasterOptions:[],
      supplierDiscountTerm:[],
      supplierCurrencyList:[],
      supplierMasterName:'',
      supplierNumber:'',
      supplierLegalName:'',
      supplierCompanyCode:'',
      supplierCurrenyCode:'',
      supplierDiscountTermValue:'',
      supplierDiscountTermID:'',
      taxPercent:'',
      taxCalculatedOn:'',
      supplierContactName:'',
      supplierContactEmail:'',
      supplierName:'',
      getPendingRecordsToggle:false,
      getPendingRecordsSupplier:[],
      onclickadd:false,
      onclickedit:false,
      disabledit:true,
      oneditSupplierMasterName:'',
      oneditSupplierNumber:'',
      oneditSupplierLegalName:'',
      oneditSupplierCompanycode:'',
      oneditSupplierCurrencycode:'',
      oneditSupplierName:'',
      oneditDiscountTermId:'',
      oneditDiscountTermValue:'',
      oneditTaxPercent:'',
      oneditTaxCalculatedOn:'',
      oneditSupplierContactName:'',
      oneditSupplierContactEmail:'',
      getPendingRecordsList:[],
      ItemSelectedforEdit:[],
      showAddInvoiceDiv:false,
      invoiceContactEmail:'',
      disableEditSave:true,
      errorMsg:'',
  
        };
  }

  public componentDidMount() {

    this.getSupplierlisrFn();
  }


  getSupplierlisrFn = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };

      // Defining the url
      let url: string = Routes.getSupplierList;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchSupplierList, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          this.setState({
            supplierList:response.data,
            activestatelist:response.data.filter(x=> x.isActive === true),
            supplierListActive:response.data.filter(x=> x.isActive === true && x.supplierRequestId === 0),
            getPendingRecordsSupplier:response.data.filter(x => x.supplierRequestId > 0)
          })
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierListFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch suppliers list.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {
        this.setState({
          // items:NRCCategoryMockData
        })
      });
    }
  
};

onChangeAllSuppliersToggle = (e) => {
  const temp = this.state
  this._selection.setAllSelected(false);
  this.setState({
    getPendingRecordsToggle:false
  })
  if(temp.supplierList.length > 0){
  if(!this.state.allSuppliersToggle){
        this.setState({
          allSuppliersToggle:!this.state.allSuppliersToggle,
          activestatelist:this.state.supplierList.filter(x=> x['supplierRequestId'] === 0),
        })
  }else{
    this.setState({
      allSuppliersToggle:!this.state.allSuppliersToggle,
      activestatelist:this.state.supplierListActive,
    })
  }
}
  }
  onChangeGetPendingRecordsToggle = (e) => {
      this._selection.setAllSelected(false);
      this.setState({
        allSuppliersToggle:false
      })

    if(!this.state.getPendingRecordsToggle){
          this.setState({
            getPendingRecordsToggle:!this.state.getPendingRecordsToggle,
            activestatelist:this.state.getPendingRecordsSupplier,
          })

    }else{
      this.setState({
        getPendingRecordsToggle:!this.state.getPendingRecordsToggle,
        activestatelist:this.state.supplierListActive,

      })
    }
    }

  private renderaction = (item: any): JSX.Element => {

    if(item.supplierRequestId === 0){
    if(item.isActive === true){
    return (
      <TooltipHost
        content={'Archive'}
        id={'Archive'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="archive"
          name="archive"
          aria-label="archive"
          iconProps={{ iconName: 'Archive' }}
          onClick={() => this.archiveUnArchiveSupplier(0,item.isActive)}
        />
      </TooltipHost>);
    }else if(item.isActive === false){
      return (
        <TooltipHost
          content={'Unarchive'}
          id={'Unarchive'}
          calloutProps={{ gapSpace: 0 }}
        >
          <IconButton
            style={{ margin: '5px' }}
            data-automation-id="Unarchive"
            name="Unarchive"
            aria-label="Unarchive"
            iconProps={{ iconName: 'Archive' }}
            onClick={() => this.archiveUnArchiveSupplier(1,item.isActive)}

          />
        </TooltipHost>);
        }
      }else {
        if(item.supplierRequestId > 0) {
          if(item.isActive === true){

    
            return (
                <IconButton
                  style={{ margin: '5px' }}
                  data-automation-id="archive"
                  name="archive"
                  aria-label="archive"
                  disabled={true}
                  iconProps={{ iconName: 'Archive' }}
                />
              );
            }else if(item.isActive === false){
              return (
                  <IconButton
                    style={{ margin: '5px' }}
                    data-automation-id="Unarchive"
                    name="Unarchive"
                    disabled={true}
                    aria-label="Unarchive"
                    iconProps={{ iconName: 'Archive' }}
                  />
                );
                }
        }
      }

  };
  private renderInvoiceContactDelete = (item: any): JSX.Element => {

    return (
      <TooltipHost
        content={'Delete'}
        id={'Delete'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton  style={{ height: '10px', textAlign: 'center' }}
  data-automation-id="test" ariaLabel="delete" title="delete"
     iconProps={{ iconName: 'delete' }} 
     onClick={() =>this.AddDeleteInvoiceContact('delete',item)}
/>
      </TooltipHost>);
    

  };

  
  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    
    switch (selectionCount) {
      case 0:
        this.setState({
          disabledit:true
        })
        return ;
        case 1:
          this.indexSelected = this._selection["_anchoredIndex"]
          this.setState({
            itemSelected:this.state.activestatelist[this.indexSelected],
            itemSelectedSupplierContacts:this.state.activestatelist[this.indexSelected]['invoiceContactEmails'],
            disabledit:false,
            getPendingRecordsList:this.state.getPendingRecordsSupplier[this.indexSelected]
          })
          return ;
          default:
            this.setState({
              disabledit: true,
            });
    }

  }

  archiveUnArchiveSupplier = (value,flag) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url
      url =Routes.archiveSupplier +`?supplierId=${this.state.itemSelected.supplierId}&isArchive=${value}`;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.ArchiveSupplier, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'PUT',
      })
        .then((response: any) => {
          this._selection.setAllSelected(false);
          this.setState({
            getPendingRecordsToggle:false,
            allSuppliersToggle:false
          })
          this.getSupplierlisrFn();

          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.ArchiveSupplierFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to archive/un archive', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };

  approveOrRejectSupplier = (value) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      var telemetrySuccess:string;
      var  telemetryFailure:string;

      if(value === 'approve'){
      // Defining the url
        url =Routes.approveSupplier +`?supplierRequestId=${this.state.getPendingRecordsList.supplierRequestId}`;
        telemetrySuccess = TelemetryEvent.ApproveSuppliers;
        telemetryFailure = TelemetryException.ApproveSuppliersFailure
      }else if(value === 'reject'){
      // Defining the url
      url =Routes.rejectSupplier +`?supplierRequestId=${this.state.getPendingRecordsList.supplierRequestId}`;
      telemetrySuccess = TelemetryEvent.RejectSuppliers;
        telemetryFailure = TelemetryException.RejectSuppliersFailure
      }

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(telemetrySuccess, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'PUT',
      })
        .then((response: any) => {
          this._selection.setAllSelected(false)
          this.setState({
            getPendingRecordsToggle:false
          })
          
          this.getSupplierlisrFn();

          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            telemetryFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to approve/reject supplier', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  AddDeleteInvoiceContact = (flag,value) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      var telemetrySuccess:string;
      var  telemetryFailure:string;
      var method :string;
      if(flag === 'delete'){
      // Defining the url
        url =Routes.addOrDeleteInvoiceContact +`?supplierId=${value.supplierId}&email=${value.email}`;
        telemetrySuccess = TelemetryEvent.DeleteSuppliersInvoiceContact;
        telemetryFailure = TelemetryException.DeleteSuppliersInvoiceContactFailure
        method = 'PUT'
      }else if(flag === 'add'){
        if(value === ''){
          return;
        }else
         if(value !== ''){
          var filterDuplicateInvoiceContacts;
          if(this.state.itemSelectedSupplierContacts.length > 0) {

            filterDuplicateInvoiceContacts = this.state.itemSelectedSupplierContacts.filter((x)=> x.email === value)

            if(filterDuplicateInvoiceContacts.length > 0){
              this.setState({
                showsuppliercontacts:false
              })
              toast.error(`${value} is a duplicate invoice contact can't be added`, {
                className: css({
                  background: '#a80000 !important',
                }),
              });
              return
            }
          }
  
        }
       
      // Defining the url
      url =Routes.addOrDeleteInvoiceContact +`?supplierId=${this.state.itemSelected.supplierId}&email=${value}`;
      telemetrySuccess = TelemetryEvent.AddSuppliersInvoiceContact;
        telemetryFailure = TelemetryException.AddSuppliersInvoiceContactFailure
        method = 'post'
      }
      

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(telemetrySuccess, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: method,
      })
        .then((response: any) => {
          this.setState({
            showsuppliercontacts:false
          })
          this._selection.setAllSelected(false)

          this.getSupplierlisrFn();
          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            telemetryFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to approve/reject supplier', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };

  loadSupplierMasterList = () =>{
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      // Defining the url
      const url: string = `${Routes.getSupplierMasterData}`;
      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchSupplierMasterList,
        {
          headers,
          url,
        }
      );
      // console.log('Calling available fiscal years api start');
       gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
        .then((response: any) => {
        if(response.data && response.status === 200){

          let masterData = response.data.map((x) => ({
            key: x.supplierMasterId,
            text:x.supplierName,
            isActive:x.isActive
          }));
          // Logging success
          this.setState({
            supplierMasterOptions:masterData
          })
          // Logging success
        
          telemetryContext.logEvent(
            TelemetryEvent.FetchSupplierMasterList,
            {
              headers,
              url,
            }
          );
          
        }
        })
        .catch((error) => {

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchSupplierMasterListFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error(
              'Supplier Master  information is not available ',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }
        });
    }
}
loadDiscountTermList = () => {
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    // Defining the url
    const url: string = `${Routes.getDiscountTermList}`;
    // Logging telemetry
    telemetryContext.logEvent(
      TelemetryEvent.FetchDiscountTermList,
      {
        headers,
        url,
      }
    );
    // console.log('Calling available fiscal years api start');
     gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
      .then((response: any) => {
      if(response.data && response.status === 200){

        let discountTerm = response.data.map((x) => ({
          key: x.discountTermId,
          text:x.description,
          isActive:x.isActive
        }));
        // Logging success
        this.setState({
          supplierDiscountTerm:discountTerm
        })
        // Logging success
      
        telemetryContext.logEvent(
          TelemetryEvent.FetchDiscountTermList,
          {
            headers,
            url,
          }
        );
        
      }
      })
      .catch((error) => {

        telemetryContext.logException(
          error.toString(),
          TelemetryException.FetchDiscountTermListFailure,
          undefined,
          {
            headers,
            url,
          }
        );

        if (error.response && error.response.status === 404) {
          toast.error(
            'DiscountTerm information is not available ',
            {
              className: css({
                background: '#a80000 !important',
              }),
            }
          );
        }
      });
  }
}
loadCurrencyList = () => {
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    // Defining the url
    const url: string = `${Routes.getCurrencyList}`;
    // Logging telemetry
    telemetryContext.logEvent(
      TelemetryEvent.FetchCurrencyList,
      {
        headers,
        url,
      }
    );
    // console.log('Calling available fiscal years api start');
     gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
      .then((response: any) => {
      if(response.data && response.status === 200){

        let currencyList = response.data.map((x) => ({
          key: x.currencyCode,
          text:x.currencyCode,
          isActive:x.isActive
        }));
        this.setState({
          supplierCurrencyList:currencyList
        })
        // Logging success
      
        telemetryContext.logEvent(
          TelemetryEvent.FetchCurrencyList,
          {
            headers,
            url,
          }
        );
        
      }
      })
      .catch((error) => {

        telemetryContext.logException(
          error.toString(),
          TelemetryException.FetchCurrencyListFailure,
          undefined,
          {
            headers,
            url,
          }
        );

        if (error.response && error.response.status === 404) {
          toast.error(
            'DiscountTerm information is not available ',
            {
              className: css({
                background: '#a80000 !important',
              }),
            }
          );
        }
      });
  }
}

addSuppliers = (value) => {
  // Checking if app is using mock data.
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    let url: string = `${Routes.addSupplier}`;
    // Logging telemetry
    telemetryContext.logEvent(TelemetryEvent.AddSuppliers, {
      headers,
      url,
    });
    var body= {};
if(value === 'add'){
      if(this.state.supplierNumber === '' || this.state.supplierCompanyCode === ''
        || this.state.supplierName === ''  || this.state.supplierLegalName === ''||
        this.state.supplierCurrenyCode === '' || this.state.supplierDiscountTermValue === '' ||
        this.state.taxPercent === ''|| this.state.taxCalculatedOn === ''||
        this.state.supplierContactName === '' || this.state.supplierContactEmail === ''|| this.state.supplierMasterName ===''){
          return
      }
        body={
      "supplierId": 0,
      "supplierRequestId": 0,
      "supplierNumber": this.state.supplierNumber,
      "msCompanyCode": this.state.supplierCompanyCode,
      "supplierName": this.state.supplierName,
      "supplierLegalName": this.state.supplierLegalName,
      "currencyCode": this.state.supplierCurrenyCode,
      "discountTerm": this.state.supplierDiscountTermValue,
      "discountTermId": this.state.supplierDiscountTermID,
      "discountPercent": 0,
      "taxPercent": this.state.taxPercent,
      "taxCalculationOn": this.state.taxCalculatedOn,
      "supplierContactName": this.state.supplierContactName,
      "supplierContactEmail": this.state.supplierContactEmail,
      "supplierMasterId": this.state.supplierMasterName,
      "isActive": true,
    }
  }else if(value === 'edit'){

    if(this.state.oneditSupplierNumber === '' || this.state.oneditSupplierCompanycode === ''
    || this.state.oneditSupplierName === ''  || this.state.oneditSupplierLegalName === ''||
    this.state.oneditSupplierCurrencycode === '' || this.state.oneditDiscountTermValue === '' ||
    this.state.oneditTaxPercent === ''|| this.state.oneditTaxCalculatedOn === ''||
    this.state.oneditSupplierContactName === '' || this.state.oneditSupplierContactEmail === ''|| this.state.oneditSupplierMasterName ===''){
      return
  }

    body={
      "supplierId": this.state.itemSelected.supplierId,
      "supplierRequestId": this.state.itemSelected.supplierRequestId,
      "supplierNumber": this.state.oneditSupplierNumber,
      "msCompanyCode": this.state.oneditSupplierCompanycode,
      "supplierName": this.state.oneditSupplierName,
      "supplierLegalName": this.state.oneditSupplierLegalName,
      "currencyCode": this.state.oneditSupplierCurrencycode,
      "discountTerm": this.state.oneditDiscountTermValue,
      "discountTermId": this.state.oneditDiscountTermId,
      "discountPercent": 0,
      "taxPercent": this.state.oneditTaxPercent,
      "taxCalculationOn": this.state.oneditTaxCalculatedOn,
      "supplierContactName": this.state.oneditSupplierContactName,
      "supplierContactEmail": this.state.oneditSupplierContactEmail,
      "supplierMasterId": this.state.oneditSupplierMasterName,
      "isActive": true,
    };
  }
  

    return gtoMsalApiFetch(gtoNewApiInstance, url, {
      data: body,
      headers,
      method: 'post',
    })
      .then((response: any) => {
        this.setState({
          showEditPanel:false,
          getPendingRecordsToggle:false

        })
        this._selection.setAllSelected(false)
        this.getSupplierlisrFn()

        // Logging success
        telemetryContext.logEvent(TelemetryEvent.AddSuppliers, {
          headers,
          url,
        });

        toast.success(response.data, {
          className: css({
                background: '#107c10 !important',
              }),
            });
        this.getSupplierlisrFn();
      })
      .catch((error) => {
        this.setState({
          showEditPanel:false

        })
        //Logging Exception
        telemetryContext.logException(
          error.toString(),
          TelemetryException.AddSuppliersFailure,
          undefined,
          {
            headers,
            url,
          }
        );

      });
  } else {
    return delay(2000).then(() => {
      toast.success('NRC Line Item Created successfully', {
        className: css({
          background: '#107c10 !important',
        }),
      });
    });
  }

};


  clickpendingrequests = (flag) => {
    this.setState({
      showPendingPanel: true,
      panelflag: flag,
    });
  };
  ondismiss = () => {
    this.setState({
      showPendingPanel: false,
    });
  };
  onclickeditpermissions = (value) => {
    this.loadSupplierMasterList();
    this.loadDiscountTermList();
    this.loadCurrencyList();
    if(value === 'add'){
      this.setState({
        onclickadd:true,
        onclickedit:false
      })
    }else if(value === 'edit'){

      this.setState({
        onclickadd:false,
        onclickedit:true,
        oneditSupplierMasterName:this.state.itemSelected.supplierMasterId,
        oneditSupplierNumber:this.state.itemSelected.supplierNumber,
        oneditSupplierLegalName:this.state.itemSelected.supplierLegalName,
        oneditSupplierCompanycode:this.state.itemSelected.companyCode,
        oneditSupplierCurrencycode:this.state.itemSelected.currencyCode,
        oneditSupplierName:this.state.itemSelected.supplierName,
        oneditDiscountTermId:this.state.itemSelected.discountTermId,
        oneditDiscountTermValue:this.state.itemSelected.discountTerm,
        oneditTaxPercent:Number(this.state.itemSelected.taxPercent),
        oneditTaxCalculatedOn:this.state.itemSelected.taxCalculationOn,
        oneditSupplierContactName:this.state.itemSelected.supplierContactName,
        oneditSupplierContactEmail:this.state.itemSelected.supplierContactEmail
      })
    }
    this.setState({
      showEditPanel: true,
    });
  };
  ondismissEdit = () => {
    this.setState({
      showEditPanel: false,
    });
  };
    
  private renderlink = (item: any): JSX.Element => {
    return (
      <TooltipHost
        content={'Supplier contact'}
        id={'Supplier contact'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="test"
          iconProps={{ iconName: 'People' }}
          name="Supplier contact"
          aria-label="Supplier Contact"
          tabIndex={0}
          onClick={() =>
            this.onclickSuppliercontacticon()
          }
        />
      </TooltipHost>
    );
  };

  onclickSuppliercontacticon = () =>{
    this.setState({
      showsuppliercontacts:true
    })
  }
  ondismisssuppliercontacts = () =>{
    this.setState({
      showsuppliercontacts:false,
      showAddInvoiceDiv:false

    })
  }
 

  private onaction = (item: any): JSX.Element => {
    return (
      <div className="ms-Grid-row ">
        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="test"
          iconProps={{ iconName: 'Accept' }}
        />

        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="test"
          iconProps={{ iconName: 'Cancel' }}
        />
      </div>
    );
  };

  private filterwithSupplierNumber = (e, newValue) => {
    this._selection.setAllSelected(false);
    if(isNaN(newValue)){

      if(newValue.length > 0){
        this.setState({
          activestatelist:this.state.supplierList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(newValue.toLowerCase()) ),
        })
      }else{
        this.setState({
          activestatelist:this.state.supplierListActive
  
        })
      }
    }else{
    if(newValue.length > 0){
      this.setState({
        activestatelist:this.state.supplierList.filter(x=> JSON.stringify(x['supplierNumber']).includes(newValue)),
      })
    }else{
      this.setState({
        activestatelist:this.state.supplierListActive

      })
    }
  }
  

  };
  private filterwithCompanycode = (e, newValue) => {
    this._selection.setAllSelected(false);
    if(newValue.length > 0){
      this.setState({
        activestatelist:this.state.supplierList.filter(x=>  JSON.stringify(x['companyCode']).includes(newValue)),
      })
    }else{
      this.setState({
        activestatelist:this.state.supplierListActive

      })
    }

  };
  private filterwithCurrency = (e, newValue) => {
    this._selection.setAllSelected(false);
    if(newValue.length > 0){
      this.setState({
        activestatelist:this.state.supplierList.filter(x=> JSON.stringify(x['currencyCode']).includes(newValue.toUpperCase())),
      })
    }else{
      this.setState({
        activestatelist:this.state.supplierListActive
      })
    }

  };
  private onchangeMasterName = (o: IDropdownOption) => {
    this.setState({
    supplierMasterName:Number(o.key),
    oneditSupplierMasterName:Number(o.key)
    })
  };
  private onchangeLegalName = (e, newValue) => {
    this.setState({
    supplierLegalName:newValue,
    oneditSupplierLegalName:newValue
    })
  };
  private onchangeSupplierNumber = (e, newValue)  => {
    this.setState({
    supplierNumber:newValue,
    oneditSupplierNumber:newValue
    })
  };
  private onchangeCompanyCode = (e, newValue)  => {
    this.setState({
    supplierCompanyCode:newValue,
    oneditSupplierCompanycode:newValue
    })
  };

  private onchangeSupplierName = (e, newValue)  => {
    this.setState({
    supplierName:newValue,
    oneditSupplierName:newValue
    })
  };
  private onchangeDiscountTerm = (o: IDropdownOption) => {
    this.setState({
    supplierDiscountTermID:Number(o.key),
    supplierDiscountTermValue:o.text,
    oneditDiscountTermId:Number(o.key),
    oneditDiscountTermValue:o.text
    })
  };
  private onchangeCurrencyCode = (o: IDropdownOption) => {
    this.setState({
    supplierCurrenyCode:o.text,
    oneditSupplierCurrencycode:o.text
    })
  };

  private onchangeContactName = (e,newValue) =>{
    this.setState({
      supplierContactName:newValue,
      oneditSupplierContactName:newValue
    })
  }
  private onchangeContactEmail = (e,newValue) =>{
    this.setState({
      supplierContactEmail:newValue,
      oneditSupplierContactEmail:newValue
    })
  }
  
  private onchangeTaxPercent = (e,newValue) =>{
      this.setState({
        taxPercent:Number(newValue),
        oneditTaxPercent:Number(newValue)
      })
    
  }

  private fixDecimalPlace (e){
    let newUnitWith2Decimal = parseFloat(e.target.value).toFixed(2)
    this.onchangeTaxPercent(e,newUnitWith2Decimal)
  }

  private onchangeTaxCalculatedOn = (o: IDropdownOption) => {
    this.setState({
    taxCalculatedOn:o.text,
    oneditTaxCalculatedOn:o.text
    })
  };
  private _getErrorMessageSupplierNumber = (value: string): string => {
    let errorMessage: string = '';
    if(value.length > 0 ){

      if(value.length === 7 ){
        errorMessage =''
      }else{
        errorMessage="Supplier number should  7 digits"
      }
    }
    this.setState({
      errorMsg:errorMessage
    })
    return errorMessage;
  };
  private _getErrorMessageCompanyCode = (value: string): string => {
    let errorMessage: string = '';
    if(value.length > 0 ){
      if(value.length === 4 ){
        errorMessage =''
      }else{
        errorMessage=" Company code  will be 4 digits"
      }
    }
    this.setState({
      errorMsg:errorMessage
    })
    return errorMessage;
  };
  private _getErrorMessageSupplierContactEmail = (value: string): string => {
    let errorMessage: string = '';
    var regex = /^\S+@\S+\.\S+$/
    if(value.length > 0 ){
      if(regex.test(value)){
      errorMessage =''
    }else{
      errorMessage="Enter valid contact email"
    }
  }
    return errorMessage;
  };
  private _getErrorMessageTaxPercent = (value: string): string => {

    let errorMessage: string = '';
    if(value.length > 0 ){
      if(Number(value) <= 100){
      errorMessage =''
    }else{
      errorMessage="Enter valid tax percent (0 to 100)"
    }
  }
  this.setState({
    errorMsg:errorMessage
  })
    return errorMessage;
  };

  manageAddInvoiceContactDiv =() =>{
    this.setState({
      showAddInvoiceDiv:true
    })
  }
  cancelmanageAddInvoiceContactDiv =() =>{
    this.setState({
      showAddInvoiceDiv:false
    })
  }
  private onchangeInvoiceContactEmail = (e,newValue) =>{
    this.setState({
      invoiceContactEmail:newValue
    })
  }

  render() {
    return (
      <React.Fragment>
      <div style={{height:'100%'}}>
      <HelmetTab title={'Suppliers'} />
        <h1
          style={{ padding: '10px', fontSize: '18.72px' }}
          aria-label={'Suppliers'}
        >
          Suppliers
        </h1>
        <Stack>
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row" style={{ marginLeft: '10px' }}>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <TextField placeholder="Supplier Name or Number" onChange={this.filterwithSupplierNumber} />
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <TextField placeholder="Company Code"  onChange={this.filterwithCompanycode}/>
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <TextField placeholder="Currency"  onChange={this.filterwithCurrency}/>
              </div>
            </div>
          </div>

          <div className="ms-Grid" dir="ltr" >
            <div
              className="ms-Grid-row "
              style={{ marginLeft: '10px', margin: '10px' ,marginTop:'15px'}}
            >
               <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg8">
               <PrimaryButton
                  text="Add"
                  iconProps={{ iconName: 'Add' }}
                  style={{ marginRight: '10px' }}
                  onClick={() => this.onclickeditpermissions('add')}
                />
                <PrimaryButton
                  text="Edit"
                  iconProps={{ iconName: 'EditNote' }}
                  style={{ marginRight: '10px' }}
                  disabled={!this.state.getPendingRecordsToggle ? this.state.disabledit : this.state.disabledit}
                  onClick={() => this.onclickeditpermissions('edit')}
                />
                {this.props.IsInvoicingManager && this.state.getPendingRecordsToggle && <PrimaryButton
                  text="Approve"
                  iconProps={{ iconName: 'Accept' }}
                  style={{ marginRight: '10px' }}
                  disabled={this.state.getPendingRecordsToggle ? this.state.disabledit :this.state.disabledit}
                  onClick={() => this.approveOrRejectSupplier('approve')}
                />}
                {this.props.IsInvoicingManager && this.state.getPendingRecordsToggle && <PrimaryButton
                  text="Reject"
                  disabled={this.state.disabledit}
                  style={{ marginRight: '5px' }}
                  iconProps={{ iconName: 'Cancel' }}
                  onClick={() => this.approveOrRejectSupplier('reject')}

                />}
              </div>

               <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
               <Toggle label="Get Pending Records" inlineLabel 
                 onChange={(e) =>this.onChangeGetPendingRecordsToggle(e)}
                 onAriaLabel="On"
                 offAriaLabel="Off"
                 defaultChecked={false}
                 checked={this.state.getPendingRecordsToggle}
                 role="switch" />

                </div>
               <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
               <Toggle label="All Suppliers" inlineLabel
                 onChange={(e) =>this.onChangeAllSuppliersToggle(e)}
                 onAriaLabel="On"
                 offAriaLabel="Off"
                 defaultChecked={false}
                 checked={this.state.allSuppliersToggle}
                 role="switch" />
              {/* </div>
              </div> */}
              </div>
            </div>
          </div>

          <Panel
          isOpen={this.state.showsuppliercontacts}
          onDismiss={this.ondismisssuppliercontacts}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          >

            <div>
            <p style={{fontSize:'20px',color:'rgb(50, 49, 48)'}}><b>Supplier Contact for {this.state.itemSelected.supplierName}</b></p>

              <p> <b> Name : </b>{this.state.itemSelected['supplierContactName']}</p>
              <p> <b> Email : </b>{this.state.itemSelected['supplierContactEmail']} </p>

             {/* {!this.state.showAddInvoiceDiv && this.state.itemSelected['supplierRequestId'] === 0 && <PrimaryButton text='Add Invoice Contact' onClick={() => this.manageAddInvoiceContactDiv ()}></PrimaryButton> } */}
             

              
          <Stack>
              <p style={{fontSize:'20px',color:'rgb(50, 49, 48)'}}><b>Invoice Contacts</b></p>

              {this.state.itemSelected['supplierRequestId'] === 0 &&
              <div className="ms-Grid" dir="ltr" >
              <div
                className="ms-Grid-row "
                // style={{ marginLeft: '10px', margin: '10px' ,marginTop:'15px'}}
              >
              <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg8">
                <TextField placeholder="Enter valid invoice contact Email" 
                 onGetErrorMessage ={this._getErrorMessageSupplierContactEmail}
                 onChange={this.onchangeInvoiceContactEmail} />
                 </div>
                 <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4">
                  <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Add"
                  disabled={this.state.invoiceContactEmail ===''}
                  onClick={() =>this.AddDeleteInvoiceContact('add',this.state.invoiceContactEmail)}
                  style={{ float: 'right' }}
                />
                </div>
              </div>
              {/* <div className="addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.cancelmanageAddInvoiceContactDiv();
                  }}
                  style={{ margin: '10px' }}
                />
                
              </div> */}
              </div>
              }
  {this.state.itemSelectedSupplierContacts.length > 0 && 
              <DetailsList
                compact={true}
                items={this.state.itemSelectedSupplierContacts}
                columns={this._invoiceContactColumns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                selectionMode={
                  SelectionMode.none
                  }
              />}
              </Stack>

           </div>
            </Panel>

          <Panel
            isOpen={this.state.showEditPanel}
            onDismiss={this.ondismissEdit}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            headerText={this.state.onclickadd ? ' Add Supplier' : 'Edit Supplier'}
          >
            { this.state.onclickadd && <div>
              <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Supplier Master Name"
                    label="Supplier Master Name"
                    ariaLabel="Supplier Master Name"
                    options={this.state.supplierMasterOptions}
                    required={true}
                    onChange={(e,o) => this.onchangeMasterName(o)}

                  />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Supplier Number"  placeholder ="Supplier Number" type='number' required  
                              onGetErrorMessage={this._getErrorMessageSupplierNumber}
                              onChange={this.onchangeSupplierNumber}/>
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Company Code" type='number' placeholder ="Company Code" required onChange={this.onchangeCompanyCode} 
                  onGetErrorMessage={this._getErrorMessageCompanyCode}/>
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField placeholder="Supplier Name: (appear in GTO tool & report)"
                    label="Supplier Name: (appear in GTO tool & report)" required 
                    onChange={this.onchangeSupplierName} />
                </div>

                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <TextField 
                label="Supplier Legal Name"
                placeholder="Supplier Legal Name"
                ariaLabel="Supplier Legal Name"
                required  onChange={this.onchangeLegalName}/>
                </div>
                
                
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Currency"
                    label="Currency"
                    ariaLabel="Currency"
                    options={this.state.supplierCurrencyList}
                    required={true}
                    onChange={(e,o) => this.onchangeCurrencyCode(o)} />
                </div>
               
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Discount Term"
                    label="Discount Term"
                    ariaLabel="Discount Term"
                    options={this.state.supplierDiscountTerm}
                    required={true}
                    onChange={(e,o) => this.onchangeDiscountTerm(o)}      />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Tax Percent (%)" required   min={0} 
                              placeholder='Tax Percent (%)' type='number' onChange={this.onchangeTaxPercent} 
                  onGetErrorMessage={this._getErrorMessageTaxPercent}/>
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Tax Calculated On"
                    label="Tax Calculated On"
                    ariaLabel="Tax Calculated On"
                    options={[
                      { key: 'Gross', text: 'Gross' },
                      { key: 'Total', text: 'Total' },
                      
                    ]}
                    onChange={(e,o) => this.onchangeTaxCalculatedOn(o)}
                    required={true}
                  />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Supplier Contact Name"  placeholder="Supplier Contact Name" required  onChange={this.onchangeContactName}/>
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Supplier Contact Email" required placeholder="Supplier Contact Email" onChange={this.onchangeContactEmail}
                  onGetErrorMessage ={this._getErrorMessageSupplierContactEmail}/>
                </div>
              </div>
              <div className="addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissEdit();
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  disabled={(this.state.supplierNumber === '' || this.state.supplierNumber.length > 7|| this.state.supplierCompanyCode === '' || this.state.supplierCompanyCode.length > 4
                  || this.state.supplierName === ''  || this.state.supplierLegalName === ''||
                  this.state.supplierCurrenyCode === '' || this.state.supplierDiscountTermValue === '' || this.state.errorMsg !=='' ||
                  this.state.taxPercent === '' || this.state.taxCalculatedOn === ''||
                  this.state.supplierContactName === '' || this.state.supplierContactEmail === ''|| this.state.supplierMasterName ==='')?true:false}
                  onClick={() => this.addSuppliers('add')}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>}

            {this.state.onclickedit && 
            <div>
              <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Supplier Master Name"
                    label="Supplier Master Name"
                    ariaLabel="Supplier Master Name"
                    options={this.state.supplierMasterOptions}
                    required={true}
                    onChange={(e,o) => this.onchangeMasterName(o)}
                    selectedKey={this.state.oneditSupplierMasterName}
                  />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Supplier Number" required  type ='number'  onGetErrorMessage={this._getErrorMessageSupplierNumber} onChange={this.onchangeSupplierNumber}
                  value={this.state.oneditSupplierNumber}
                  disabled={true}
                  />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <TextField 
                label="Supplier Legal Name"
                placeholder="Supplier Legal Name"
                ariaLabel="Supplier Legal Name"
                required  onChange={this.onchangeLegalName}
                value={this.state.oneditSupplierLegalName}/>

                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Company Code" required type ='number' onChange={this.onchangeCompanyCode} 
                  value={this.state.oneditSupplierCompanycode}
                  onGetErrorMessage={this._getErrorMessageCompanyCode}
                  disabled={true}
                  />
                </div>
                
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Currency"
                    label="Currency"
                    ariaLabel="Currency"
                    options={this.state.supplierCurrencyList}
                    required={true}
                    onChange={(e,o) => this.onchangeCurrencyCode(o)} 
                    selectedKey={this.state.oneditSupplierCurrencycode}
                    disabled={true}

                    />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField placeholder="Supplier Name: (appear in GTO tool & report)"
                    label="Supplier Name: (appear in GTO tool & report)" required 
                    onChange={this.onchangeSupplierName} 
                    value={this.state.oneditSupplierName}/>
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Discount Term"
                    label="Discount Term"
                    ariaLabel="Discount Term"
                    options={this.state.supplierDiscountTerm}
                    required={true}
                    onChange={(e,o) => this.onchangeDiscountTerm(o)}  
                    selectedKey={this.state.oneditDiscountTermId}
                    />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Tax Percent (%)" required  onChange={this.onchangeTaxPercent}
                  value={this.state.oneditTaxPercent} type="number"
                  onGetErrorMessage={this._getErrorMessageTaxPercent}
                  />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <Dropdown
                    placeholder="Tax Calculated On"
                    label="Tax Calculated On"
                    ariaLabel="Tax Calculated On"
                    options={[
                      { key: 'Gross', text: 'Gross' },
                      { key: 'Total', text: 'Total' },
                      
                    ]}
                    onChange={(e,o) => this.onchangeTaxCalculatedOn(o)}
                    required={true}
                    selectedKey={this.state.oneditTaxCalculatedOn}

                  />
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Supplier Contact Name" required  onChange={this.onchangeContactName}
                  value={this.state.oneditSupplierContactName}/>
                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                  <TextField label="Supplier Contact Email" required  onChange={this.onchangeContactEmail}
                  value={this.state.oneditSupplierContactEmail}
                  onGetErrorMessage ={this._getErrorMessageSupplierContactEmail}/>
                </div>
              </div>
              <div className="addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissEdit();
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  disabled={(this.state.oneditSupplierNumber === '' || this.state.oneditSupplierCompanycode === ''
                  || this.state.oneditSupplierName === ''  || this.state.oneditSupplierLegalName === ''||
                  this.state.oneditSupplierCurrencycode === '' || this.state.oneditDiscountTermValue === '' ||this.state.errorMsg !==''||
                  this.state.oneditTaxPercent === '' || this.state.oneditTaxCalculatedOn === ''||
                  this.state.oneditSupplierContactName === '' || this.state.oneditSupplierContactEmail === ''|| this.state.oneditSupplierMasterName ==='')?true :false}
                  onClick={() => this.addSuppliers('edit')}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>}
          </Panel>

            <div style={{
            height: '70vh',
            position: 'relative',
            marginLeft: '1vw'}}
            >
         <ScrollablePane>
            {/* <MarqueeSelection selection={this._selection}> */}
              <DetailsList
                compact={true}
                items={this.state.activestatelist}
                columns={this._columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                // onItemInvoked={this._onItemInvoked}
                selectionMode={
                  SelectionMode.single
                  }
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
              />
            {/* </MarqueeSelection> */}
            </ScrollablePane>
          </div>
        </Stack>
      </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserRoles: () => {
      dispatch(getUserRoles());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    IsInvoicingManager: state.user.IsInvoicingManager,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
