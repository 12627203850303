import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Selection,
  SelectionMode,
  CheckboxVisibility
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';

import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import React from 'react';
import { toast } from 'react-toastify';
import {
  ISupplier,
  ITag,
  TagTypes,
  TelemetryEvent,
} from '../../../../shared/models';
import { css } from 'glamor';
import telemetryContext from '../../../../shared/services/TelemetryServices';
import { connect } from 'react-redux';
import { Checkbox } from '@fluentui/react';

// Interface for props.
export interface ISupplierListProps {
  selectedTags?: ITag[];
  supplierList: ISupplier[];
  selectedSupplier: any;
  currentSupplier: ISupplier;
  returnToSelectedSupplier: boolean;
  isSupplierFiltered: boolean;
}

class SupplierList extends React.Component<ISupplierListProps, {}> {
  private _selection: Selection;
  private _columns: IColumn[];
  private indexSelected: number = -1;
  private prevSelectedIndex: number = -1;
  private checkedlistitem:number = 0

  constructor(props) {
    super(props);

    // create the column definition.
    this._columns = [
      {
        key: 'column1',
        name: '',
        fieldName: 'supplierLegalName',
        minWidth: 50,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: this.renderSupplierListCell,
      },
    ];

    // State for selected component
    this._selection = new Selection({
      onSelectionChanged: () => {
        // Passing the selected Supplier
        this._getSelectionDetails();
      },
    });
  }

  public componentDidMount() {
    if (this.props.returnToSelectedSupplier) {
      const { supplierList } = this.props;
      for (let i = 0; i < supplierList.length; i++) {
        if (
          (supplierList[i] as ISupplier).supplierId ===
          this.props.currentSupplier.supplierId
        ) {
          this.indexSelected = i;
        }
      }
    }
    this._selection.setIndexSelected(this.indexSelected, true, true);
  }

  public componentDidUpdate() {
    if (!this.props.isSupplierFiltered) {
      if (this.indexSelected === -1) {
        this.indexSelected = 0;
      }
      this._selection.setIndexSelected(this.indexSelected, true, true);
    }
  }

  public render() {
    return (
      <Fabric>
      <MarqueeSelection selection={this._selection}>
        <DetailsList
          items={this._getFilteredItems()}
          compact={false}
          columns={this._columns}
          ariaLabelForGrid="Suppliers List"
          checkButtonAriaLabel="select a single Supplier"
          selectionMode={SelectionMode.single}
          // setKey="set"
          checkboxVisibility={CheckboxVisibility.hidden}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={false}
          selection={this._selection}
          selectionPreservedOnEmptyClick={true}
          onItemInvoked={this._onItemInvoked}
          enterModalSelectionOnTouch={true}
          ariaLabelForSelectionColumn="Toggle selection of Suppliers"
        />
        </MarqueeSelection>
      </Fabric>
    );
  }

  // Render the supplier and supplier number tooltip
  private renderSupplierListCell = (item: any): JSX.Element => {
      return (
        <React.Fragment>
          <TooltipHost
            content={`${item.supplierLegalName}  ${item.companyCode}`}
            id={'Supplier List'}
            calloutProps={{ gapSpace: 0 }}
          >
            <span style={{ fontSize: '13px', width: '100%', fontWeight: 430 }}>
           <span> <Checkbox  className="suppliercheckbox" name="myCheckbox"  key={item.key} checked={this.indexSelected === item.key-1 ? true:false}  label={item.supplierLegalName + '\n' +'(' + item.supplierNumber + ')'} />
            </span>
            </span>
          </TooltipHost>
        </React.Fragment>
      );
   
  };

  // Filter Supplier based on tags
  private _getFilteredItems() {
    // filter out supplier items based on users selected tags.
    const filteredItems = this.props.supplierList.filter((item) => {
      if (this.props.selectedTags && this.props.selectedTags.length > 0) {
        let compare: boolean = true;
        this.props.selectedTags.forEach((tag) => {
          // Check if tag is of company code type.
          if (tag.type === TagTypes.CompanyCodes && compare) {
            // Set compare equals true if the company code of the current item matches the selected Tag.
            compare = tag.name === item.companyCode;
          }
          // Check if tag of of supplier name type.
          if (tag.type === TagTypes.SupplierName && compare) {
            // Set compare equals true if the supplier name of the current item matches the selected Tag.
            compare = tag.name === item.masterSupplierName;
          }
        });
        return compare;
      } else {
        return true;
      }
    });
    let i = 1;
    const items = filteredItems.map((x) => ({ ...x, key: i++ }));
    return items;
  }
  private _onItemInvoked(item: any): void {
    toast.info(`Item invoked: ${item.name}`, {
      className: css({
        background: '#0275d8 !important',
      }),
    });
  }

  // OnClick of the supplier this method is called
  private _getSelectionDetails(): ISupplier {
    const selectionCount = this._selection.getSelectedCount();
    
    switch (selectionCount) {
      case 0:
        return;

      case 1:
        const supplierDetail = this._selection.getSelection()[0] as ISupplier;
        this.indexSelected = this.props.supplierList.findIndex(
          (x) => x.supplierId === supplierDetail.supplierId
        );
        if (this.indexSelected !== this.prevSelectedIndex) {
          // Logging the selected supplier.
          telemetryContext.logEvent(TelemetryEvent.SupplierSelection, {
            supplierName: supplierDetail.supplierLegalName,
            supplierNumber: supplierDetail.supplierNumber,
          });
          // Passing the selected supplier and sending it to the SupplierSummary Component(parent) and setting it into the state
          this.props.selectedSupplier(supplierDetail);
          this.prevSelectedIndex = this.indexSelected;
        }
        return supplierDetail;

      default:
        const supplierDetail2 = this._selection.getSelection()[0] as ISupplier;
        return supplierDetail2;
    }
  }

  // It will be executed when the user clicks on the column header.
  private _onColumnClick = (): void => {};
}

const mapStateToProps = (state) => {
  return {
    currentSupplier: state.selectedSupplier.basicInfo,
    returnToSelectedSupplier: state.selectedSupplier.returnToSelectedSupplier,
    isSupplierFiltered: state.selectedSupplier.isSupplierFiltered,
  };
};

export default connect(mapStateToProps, null)(SupplierList);
