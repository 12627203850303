  import { MsalAuthProvider, LoginType, AccessTokenResponse, IdTokenResponse } from 'react-aad-msal';
  import { LogLevel, Logger, AuthenticationParameters } from 'msal';
  import appConfig from '../../assets/configuration/config';

  // This is the client Id for our UI.
  const appId = `${appConfig.GTOvNextUIClientId}`;

  // client id of for gto invoice api.
  const clientId = `${appConfig.GTOAPIClientId}`;

// This auth provider uses react-aad-msal. See: https://github.com/syncweek-react-aad/react-aad
// The AAD app record for the UI has reply urls set for the root page as well as the path ending in auth.html
// which is used during silent flow. See issues discussed here:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1199

const logger = new Logger(
    (logLevel, message, containsPii) => {
        console.log('[MSAL]', message);
    },
    {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false
    }
);

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
let authProvider: MsalAuthProvider;

/**
 * Init auth provider.
 */
const initAuthProvider = (): MsalAuthProvider => {
    // The app config is loaded in the index.tsx with an await, which is done before this getAuthProvider() is called,
    // so at this point the appConfig.current should be loaded, so we don't need to call appConfig.registerConfigLoadedCallback.

    authProvider = new MsalAuthProvider(
        {
            auth: {
                authority: "https://login.microsoftonline.com/microsoft.onmicrosoft.com/",
                clientId: appId,
                postLogoutRedirectUri: window.location.origin,
                redirectUri: window.location.origin,
                validateAuthority: true,

                // After being redirected to the "redirectUri" page, should user
                // be redirected back to the Url where their login originated from?
                navigateToLoginRequestUrl: false
            },
            // Enable logging of MSAL events for easier troubleshooting.
            // This should be disabled in production builds.
            system: {
                logger: logger as any,
                loadFrameTimeout: 30000 // Default is 6 seconds.
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: true
            }
        },
        {
            scopes: ['user.read']
        },
        {
            loginType: LoginType.Redirect,
            // When a token is refreshed it will be done by loading a page in an iframe.
            // Rather than reloading the same page, we can point to an empty html file which will prevent
            // site resources from being loaded twice.
            tokenRefreshUri: window.location.origin
        }
    );

    return authProvider;
}

/**
 * Get auth provider.
 */
export const getAuthProvider = (): MsalAuthProvider => {
    if (authProvider) {
        return authProvider;
    }
    return initAuthProvider();
}

/**
 * Get access token for the scope.
 */
export const getAccessTokenForScope = async (scope: string): Promise<string> => {
    const ap = getAuthProvider();
    const authenticationParameters: AuthenticationParameters = {
        scopes: [scope]
    }
    const accessTokenResponse: AccessTokenResponse | undefined = await ap.getAccessToken(authenticationParameters);
    return accessTokenResponse?accessTokenResponse.accessToken:'';
}

/**
 * Get id token for the user.
 */
export const getIdToken = async (): Promise<string> => {
    const ap = getAuthProvider();
    const idTokenResponse: IdTokenResponse = await ap.getIdToken();
    return idTokenResponse.idToken.rawIdToken;
}

// /**
//  * Get access token for Recycling API calls.
//  */
export const  getAccessTokenForRecycling =  (): Promise<string> => {
    return getAccessTokenForScope(clientId);
}

