import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import {ComboBox,IComboBoxOption} from '@fluentui/react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { DetailsList, DetailsListLayoutMode, Selection, IColumn,SelectionMode } from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { Panel, PanelType }  from 'office-ui-fabric-react'
import {IconButton  } from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import appConfig from '../../assets/configuration/config';
import { v4 as uuidv4 } from 'uuid';
import { TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {  gtoMsalApiFetch,} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { delay } from 'q';
import {  ScrollablePane } from 'office-ui-fabric-react';
import HelmetTab from "../../shared/Helmet/Helmet";
import { getUserModules } from '../../store/actions/user.action';
import { connect } from 'react-redux';



  export interface IUserManagementProps {
    getUserModules: any;
    IsAdmin: boolean;
  }

  export interface IUserManagementState {
    showPendingPanel: boolean;
    selectionDetails:any;
    items:any;
    newUsersList:[];
    panelflag:any;
    showEditPanel:boolean;
    alias:string;
    firstname:string;
    lastname:string;
    rolesdropdown:IComboBoxOption[];
    newUserItemSelected:any;
    disableadduserbutton:boolean;
    showpedingItemList:[];
    disableedituserbutton:boolean;
    ItemSelectedforEdit:any;
    itemSelectedAlias:string;
    checkboxvaluestate:any;
    pendingRequestsCount:any;
    pendingList:[];
    _edituserItems:any;
    isactiveTogglemain:boolean;
    activestatelist:any;
    callsimmer:boolean;
    selectedindexfortest:any;
    testing:any;
    showrejectiontextbox:boolean;
    rejectUserDetails:any;
    rejectreason:string;
    allUsersList:any;

  }
   class UserManagement extends React.Component<IUserManagementProps, IUserManagementState> {
  private _selection: Selection;
  private _selectionadd: Selection;
  private _columns: IColumn[];
  private _pendingcolumns: IColumn[];
  private _addnewusercolumns: IColumn[];
  public _editcolumns: IColumn[];
  // private _edituserItems: any;
  private indexSelectedNewUser: number = -1;
  private indexSelected: number = -1;
  private _rolesSelected:any;


  constructor(props) {
    super(props);
    this.props.getUserModules();
    this._selection = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetailsForEdit();
      },
    });
    this._selectionadd = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetails();
      },
    });

    this._rolesSelected =[];

    this._columns = [
      {
        key: 'column1',
        name: 'Alias',
        fieldName: 'alias',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: 'First Name',
        fieldName: 'firstName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'Last Name',
        fieldName: 'lastName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Active',
        fieldName: 'isActive',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column5',
        name: 'Is Admin',
        fieldName: 'isAdmin',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column6',
        name: 'Roles',
        fieldName: 'roles',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    this._pendingcolumns = [
      {
        key: 'column1',
        name: 'User Alias',
        fieldName: 'alias',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
      },
      { key: 'column2', 
      name: 'Justification', 
      fieldName: 'justification',
      minWidth: 50, 
      maxWidth: 100, 
      isResizable: true
      },
      {
        key: 'column3',
        name: 'Business Analyst',
        fieldName: 'businessanalyst',
        minWidth: 100,
        maxWidth: 130,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
      {
        key: 'column4',
        name: 'Supplier Support Manager',
        fieldName: 'suppliersupportmanager',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
      {
        key: 'column5',
        name: 'Invoice Analyst',
        fieldName: 'invoiceanalyst',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
      {
        key: 'column6',
        name: 'Service Delivery',
        fieldName: 'servicedelivery',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
      {
        key: 'column7',
        name: 'Invoicing Manager',
        fieldName: 'invoicemanager',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
     
      {
        key: 'column8',
        name: 'Finance Owner',
        fieldName: 'financeowner',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
      {
        key: 'column9',
        name: 'Report User',
        fieldName: 'reportuser',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.rendercheckboxPending,
      },
      {
        key: 'column10',
        name: 'Action',
        fieldName: 'action',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        onRender: this.onaction,
      },
    ];

    this._addnewusercolumns = [
      {
        key: 'column1',
        name: 'Personal Number',
        fieldName: 'personnelNumber',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column2',
        name: 'Alias',
        fieldName: 'alias',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'First Name',
        fieldName: 'firstName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Last Name',
        fieldName: 'lastName',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
      },
    ];
    this._editcolumns = [
      {
        key: 'column1',
        name: 'Status',
        fieldName: 'status',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        // onRender: this.rendercheckboxedit,
      },
      {
        key: 'column2',
        name: 'Roles',
        fieldName: 'roleName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
      },
    ];

    this.state = {
      items: [],
      selectionDetails: [],
      showPendingPanel: false,
      panelflag: '',
      showEditPanel: false,
      alias: '',
      firstname: '',
      lastname: '',
      rolesdropdown: [],
      newUsersList: [],
      newUserItemSelected: [],
      disableadduserbutton: true,
      showpedingItemList: [],
      disableedituserbutton: true,
      ItemSelectedforEdit: [],
      itemSelectedAlias: '',
      checkboxvaluestate: '',
      pendingRequestsCount: 0,
      pendingList: [],
      _edituserItems:'',
      isactiveTogglemain:false, 
      activestatelist:[],
      callsimmer:false,
      selectedindexfortest:[],
      testing:[],
      showrejectiontextbox:false,
      rejectUserDetails:'',
      rejectreason:'',
      allUsersList:[],
    };
  }

  public componentDidMount() {
    this.getUserlistdata(0);
    this.getUserRoles();
    this.getUserPendingRequestsFn();
  }

  onChangeToggle = (e) => {
    const temp = this.state
    this._selection.setAllSelected(false)
    this._selectionadd.setAllSelected(false)
    if(temp.items.length > 0){
    if(!this.state.isactiveTogglemain){
          this.setState({
            isactiveTogglemain:!this.state.isactiveTogglemain,
            items:this.state.activestatelist
          })
    }else{
      this.setState({
        isactiveTogglemain:!this.state.isactiveTogglemain,
        items:this.state.allUsersList
      })
    }
  }
    }

  
  getUserlistdata = (value) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url
      if (value === 0) {
        url = Routes.getAllUsersList;
      }
      if (value === 1) {
        url =
          Routes.getAllUsersList +
          `/?userAlias=${this.state.alias}&firstName=${this.state.firstname}&lastName=${this.state.lastname}`;
      }

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchAllUsersList, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          // Mapping the response data.
          this._selection.setAllSelected(false);
          this.setState({
            items: response.data,
            activestatelist:response.data.filter(x=> x.isActive === true),
            allUsersList:response.data
          });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchAllUsersListFaiure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch users list.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  getNewUserlistdata = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url

      url =
        Routes.getNewUserData +
        `/?userAlias=${this.state.alias}&firstName=${this.state.firstname}&lastName=${this.state.lastname}`;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchNewUsersList, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'post',
        data: {},
      })
        .then((response: any) => {
          // Mapping the response data.
          this.setState({
            newUsersList: response.data,
          });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchNewUsersListFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch new users list.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  addNewUserSubmit = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url

      url = Routes.AddNewUser;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.AddNewUser, {
        url,
        headers,
      });
      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'post',
        data: {
          firstName: this.state.newUserItemSelected.firstName,
          lastName: this.state.newUserItemSelected.lastName,
          alias: this.state.newUserItemSelected.alias,
          email: this.state.newUserItemSelected.email,
          isActive: this.state.newUserItemSelected.isActive,
          isAdmin: this.state.newUserItemSelected.isAdmin,
          roles: this.state.newUserItemSelected.roles,
          userId: this.state.newUserItemSelected.userId,
          personnelNumber: this.state.newUserItemSelected.personnelNumber,
        },
      })
        .then((response: any) => {
          this.setState({
            showPendingPanel:false
          })
        
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.AddNewUserFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to add new users .', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  getUserRoles = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url

      url = Routes.getUserRoles;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchUserRoles, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          let roles = response.data.map((x) => ({
            key: x.roleId,
            text: x.roleName,
          }));
          this.setState({
            _edituserItems: response.data,
            rolesdropdown: roles,
          })
          // Mapping the response data.
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchUserRolesFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to fetch user roles.', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  getUserPendingRequestsFn = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url

      url = Routes.getPendingRequests;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.FetchUserPeningRequests, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
      })
        .then((response: any) => {
          this.setState({
            pendingList: response.data,
            pendingRequestsCount: response.data.length,
          });
        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchUserPeningRequestsFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            this.setState({
              pendingRequestsCount:0
            })
            // toast.error('No Pending requests available.', {
            //   className: css({
            //     background: '#a80000 !important',
            //   }),
            // });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };

  

  private _getSelectionDetails(): string {
    const selectionCount = this._selectionadd.getSelectedCount();

    switch (selectionCount) {
      case 0:
        this.setState({
          disableadduserbutton: true,
        });
        return;
      case 1:
        this.indexSelectedNewUser = this._selectionadd['_anchoredIndex'];
        this.setState({
          newUserItemSelected:
            this.state.newUsersList[this.indexSelectedNewUser],
          disableadduserbutton: false,
        });
    }
  }
  private _getSelectionDetailsForEdit(): string {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        this.setState({
          disableedituserbutton: true,
        });
        return;
      case 1:
        this.indexSelected = this._selection['_anchoredIndex'];
        this.setState({
          ItemSelectedforEdit: this.state.items[this.indexSelected],
          checkboxvaluestate: this.state.items[this.indexSelected],
          disableedituserbutton: false,
        });
        return ;
        default:
          this.setState({
            disableedituserbutton: true,
          });

    }
  }
  

  clickpendingrequests = (flag) => {
    if (flag === 'add') {
      if (
        this.state.alias !== '' ||
        this.state.firstname !== '' ||
        this.state.lastname !== ''
      ) {
        this.getNewUserlistdata();
        this.setState({
          showPendingPanel: true,
          panelflag: flag,
        });
      } else {
        this.setState({
          showPendingPanel: false,
        });
      }
    }
    if (flag === 'pending') {
      this.setState({
        showPendingPanel: true,
        panelflag: flag,
      });
    }
  };
  ondismiss = () => {
    this.setState({
      showPendingPanel: false,
    });
  };
  ondismissRejectPanel = () => {
    this.setState({
      showrejectiontextbox: false,
      showPendingPanel:true
    });
  };
  openPendingRequestModal = (item,status) =>{
    this.setState({
      showrejectiontextbox:true,
      rejectUserDetails:item,
    })
  }
  onclickeditpermissions = () => {
    let tempref= this.state._edituserItems;  
    
    for (const iterator of tempref) {
      if(this.state.ItemSelectedforEdit.roles !== '' && this.state.ItemSelectedforEdit.roles !== null){
        let temp = this.state.ItemSelectedforEdit.roles.split(',');
        if(temp.filter(x=> x.trim() === iterator.roleName).length > 0){
          iterator.status= true;
        }
        else{
          iterator.status= false;

        }
      }
    }
     this.setState({
      testing : tempref,
      showEditPanel: true,

    })
    }
  ondismissEdit = () => {
    this.setState({
      showEditPanel: false,
    });
  };
  private onChangeAlias = (e, newValue) => {
    this.setState({
      alias: newValue,
    });
  };
  private onChangeFirstname = (e, newValue) => {
    this.setState({
      firstname: newValue,
    });
  };
  private onChangeLastname = (e, newValue) => {
    this.setState({
      lastname: newValue,
    });
  };
  private onChangeRejectReason = (e, newValue) => {
    this.setState({
      rejectreason: newValue,
    });
  };
  private rendercheckboxPending = (item: any, key, data): JSX.Element => {
    if (item.roles.length > 0) {
      return (
        <Checkbox label={data.name}
          checked={item.roles.filter((x) => x.roleName === data.name).length > 0}
          tabIndex={0}/>
      );
    }
  };

  onrenderCheckboxfn =() => {
    // const {testing} = this.state;
    return this.state.testing.map((attr,key) => {
      return (
        <div key={key} style={{ marginBottom: '1vh' ,marginLeft:'5px'}}>
          <Checkbox
            label={attr.roleName}
            checked={attr.status}
            onChange={(ev, isChecked) =>
              this._onCheckboxChangebox(attr.roleName, ev, isChecked)
            }
          />{' '}{' '} </div>
          )

    })
  }
 
  private _onCheckboxChangebox(
    changedAttribute: string,
    ev: React.FormEvent<HTMLElement>,
    isChecked: boolean
  ): void {
    // Setting the selected true for the toggled checkbox.
    const testing = this.state.testing
    const matchedAttribute =  testing.find(
      x => x.roleName === changedAttribute
    );
    matchedAttribute.status = isChecked;
    // Move the matchedAttribute from it's current place in the array to the end of the array. This will make
    // the attribute appear at the end of the columns. The user could then reorder them if desired.
    // if (isChecked) {
      const index: number = testing.indexOf(matchedAttribute);
      testing[index].status = isChecked
      // testing.splice(index,1);
      // testing.push(matchedAttribute);
    // }
    this.setState({ testing
     });

    // Save user preferences. If matched attribute was checked then add to end of array,
    // otherwise remove from the place it was at. xxx
  }



  editpermissionsfnsubmit = () =>{
// Checking if app is using mock data.
if (appConfig.UseMockData === 'false') {
  var rolesformat = []
  // Creating headers for telemetry
  const headers: object = {
    'X-CorrelationId': uuidv4(),
    SubCorrelationKey: uuidv4(),
  };
  let url: string;
  // Defining the url

  url =Routes.updateUserStatus +`?userId=${this.state.ItemSelectedforEdit.userId}&isActive=true`;
    if(this.state.testing.length > 0){
  for(var i = 0;i< this.state.testing.length ;i++){
        let refd = {
          "roleId": this.state.testing[i].roleId,
          "isActive": this.state.testing[i].status
        }
        rolesformat.push(refd)
      }
    }
    

  // Logging telemetry for NRC Categories api call.
  telemetryContext.logEvent(TelemetryEvent.UserstatusChange, {
    url,
    headers,
  });

  // Making the get call.
  return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
    headers,
    method: 'post',
    data: rolesformat,
  })
    .then((response: any) => {
      this._selection.setAllSelected(false)
      this.setState({
        showEditPanel: false,
      });

      // Mapping the response data.

      
      toast.success(response.data, {
    className: css({
          background: '#107c10 !important',
        }),
      });
      this.getUserlistdata(0);

    })
    .catch((error) => {
      // Log Exception
      telemetryContext.logException(
        error.toString(),
        TelemetryException.UserStatusUpdateFailure,
        undefined,
        {
          url: url,
          headers,
        }
      );
      // Error handling.
      if (error.response && error.response.status === 404) {
        toast.error('Unable to update the state', {
          className: css({
            background: '#a80000 !important',
          }),
        });
      }

      throw error;
    });
} else {
  //Setting mock data.
  return delay(2000).then(() => {});
}
}
  

  activestate = (ev) => {
    this.changeuserstatus();
  };
  inactivestate = () => {
    this.changeuserstatusinactive();
  };

  changeuserstatus = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      var rolesformat = []
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url

      url =
        Routes.updateUserStatus +
        `?userId=${this.state.ItemSelectedforEdit.userId}&isActive=true`;
    if(this.state.ItemSelectedforEdit.roles !== '' && this.state.ItemSelectedforEdit.roles !== null){

        let temp = this.state.ItemSelectedforEdit.roles.split(',')
        if(temp.length > 0){
          for(var i = 0;i< temp.length ;i++){
            for(var j=0;j<this.state.rolesdropdown.length ;j++){
              if(temp[i].trim() === this.state._edituserItems[j].roleName){
                let refd = {
                  "roleId": this.state._edituserItems[j].roleId,
                  "isActive": true
                }
                rolesformat.push(refd)
              }
            }
          }
        }
    }

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.UserstatusChange, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'post',
        data: rolesformat,
      })
        .then((response: any) => {
          this._selection.setAllSelected(false)
          this.setState({
            showEditPanel: false,
          });

          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });
          this.getUserlistdata(0);

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.UserStatusUpdateFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to update the state', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };
  changeuserstatusinactive = () => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      var rolesformat = []
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      // Defining the url

      url =
        Routes.updateUserStatus +
        `?userId=${this.state.ItemSelectedforEdit.userId}&isActive=false`;
        if(this.state.ItemSelectedforEdit.roles !== '' && this.state.ItemSelectedforEdit.roles !== null){

          let temp = this.state.ItemSelectedforEdit.roles.split(',')
            if(temp.length > 0){
              for(var i = 0;i< temp.length ;i++){
                for(var j=0;j<this.state.rolesdropdown.length ;j++){
                  if(temp[i].trim() === this.state._edituserItems[j].roleName){
                    let refd = {
                      "roleId": this.state._edituserItems[j].roleId,
                      "isActive": true
                    }
                    rolesformat.push(refd)
                  }
                }
              }
            }
        }

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.UserstatusChange, {
        url,
        headers,
      });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'post',
        data: rolesformat
      })
        .then((response: any) => {
          // Mapping the response data.
          this._selection.setAllSelected(false)

          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });
          this.getUserlistdata(0);

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.UserStatusUpdateFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable to update the state', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };

  approveOrRejectPendingRequets = (item,status) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      var rolesdata =[];
    for(var i=0 ;i< item.roles.length;i++){
      let refd = {
        "roleId": item.roles[i].roleId,
        "isActive": true
      }
      rolesdata.push(refd)
    }
      let url: string;

  
      // Defining the url

      url =Routes.approveRequestPending+`?isApproved=${status}`;

      // Logging telemetry for NRC Categories api call.
      telemetryContext.logEvent(TelemetryEvent.UserAccessRequestAction, {
        url,
        headers,
      });
      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        headers,
        method: 'post',
        data: {
          requestId: item.requestId,
          alias: item.alias,
          roles:rolesdata,
          reason: this.state.rejectreason
        }
      })
        .then((response: any) => {
          this.setState({
            showPendingPanel: false,
            showrejectiontextbox:false
          });
          this.getUserPendingRequestsFn();

          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.UserAccessRequestActionFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            this.setState({
              showPendingPanel: false,
            });
            toast.error('Unable to process', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
  };

  formatapproveRejectBody = (item) =>{
    var roles =[];
    for(var i=0 ;i<= item.roles.length;i++){
      roles.push({
        roleId: item.roles[i].roleId,
        isActive: true
      })
    }
    return roles
  }
  private onaction = (item: any): JSX.Element => {
    return (
      <div className="ms-Grid-row ">
        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="Approve"
          iconProps={{ iconName: 'Accept' }}
          name='Approve'
          aria-label="Approve"
          onClick={() =>this.approveOrRejectPendingRequets(item,true)}
        />

        <IconButton
          style={{ margin: '5px' }}
          data-automation-id="Reject"
          iconProps={{ iconName: 'Cancel' }}
          name='Reject'
          aria-label="Reject"
          onClick={() => this.openPendingRequestModal(item,false)}

        />
      </div>
    );
  };
 changeroles = (e, newValue) => {
    if(newValue.selected){
      this._rolesSelected.push(newValue.text)
    }else{
      this._rolesSelected.pop(newValue.text)
    }
  }

  filterRecords =() =>{
    this._selection.setAllSelected(false);
    if(this._rolesSelected.length > 0 )
      this._rolesSelected.map((x) => {
   this.setState({
     items:!this.state.isactiveTogglemain ? this.state.allUsersList.filter((y) => JSON.stringify(y['roles']).includes(x)) :
     this.state.activestatelist.filter((y) => JSON.stringify(y['roles']).includes(x))
   })
    })  
}

  render() {
    return (
      <React.Fragment>
      <div style={{height:'90vh'}}>
      <HelmetTab title={'User Management'} />

        <h2 className="Header"
          style={{ padding: '10px', fontSize: '18.72px' }}
          aria-label={'User Managament'}
        >
          User Management
        </h2>
        <Stack>
          <div className="ms-Grid one" dir="ltr">
            <div className="ms-Grid-row" style={{ marginLeft: '10px' }}>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <TextField placeholder="Alias" onChange={this.onChangeAlias} 
                id="alias"
                ariaLabel="alias"/>
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <TextField
                  placeholder="First Name"
                  onChange={this.onChangeFirstname}
                  id="firstName"
                  ariaLabel="First Name"
                />
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <TextField
                  placeholder="Last Name"
                  onChange={this.onChangeLastname}
                  id="lastname"
                  ariaLabel="Last Name"
                />
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <ComboBox
                  multiSelect
                  placeholder="Roles"
                  id="roles"
                  ariaLabel="roles"
                  options={this.state.rolesdropdown}
                  onChange={this.changeroles}
                />  
              </div>
              <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                  <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                    <PrimaryButton
                      text="Search"
                      ariaLabel="Search"
                      label="Search"
                      onClick={() => {this._rolesSelected.length > 0 ? this.filterRecords() :this.getUserlistdata(1)}}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg3" style={{marginLeft:'10px'}}>
                    <PrimaryButton
                      iconProps={{ iconName: 'Add',}}
                      text="Add User"
                      ariaLabel="Add User"
                      label="Add User"
                      disabled={!this.props.IsAdmin}
                      onClick={() => this.clickpendingrequests('add')}
                      style={{fontSize:'13px'}}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                  <PrimaryButton
                      iconProps={{ iconName: 'Assign' }}
                      ariaLabel="Pending Requets"
                      label="Pending Requests"
                      text={`Pending Requests - (${this.state.pendingRequestsCount})`}
                      disabled={!this.props.IsAdmin}
                      onClick={() => this.clickpendingrequests('pending')}
                    />
                  </div>
              </div>
            </div>
          </div>
          <div className="ms-Grid three " dir="ltr" style={{marginTop:'2vh'}}>
            <div
              className="ms-Grid-row two"
              style={{ marginLeft: '10px', margin: '10px' }}
            >

               <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg2">
              <Toggle label="IsActive"   onChange={(e) =>this.onChangeToggle(e)} 
              onAriaLabel="On"
              offAriaLabel="Off"
              defaultChecked={this.state.isactiveTogglemain}
              inlineLabel
              role="switch"/>
              </div>

              <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg2">
                <PrimaryButton
                  text="Edit Permissions"
                  id="editpermissions"
                  ariaLabel="Edit Permissions"
                  iconProps={{ iconName: 'EditNote' }}
                  onClick={() => this.onclickeditpermissions()}
                  disabled={(!this.state.disableedituserbutton && this.props.IsAdmin ) ? false :true }
                />
              </div>
              <div style={{ float: 'right' }}>
                <DefaultButton
                  text="Action"
                  primary
                  split
                  id="Action"
                  ariaLabel="Action"
                  splitButtonAriaLabel="See 2 options"
                  aria-roledescription="split button"
                  menuProps={{
                    items: [
                      {
                        key: 'active',
                        text: 'Active',
                        ariaLabel:'Active',
                        iconProps: { iconName: 'Mail' },
                        onClick: this.activestate,
                      },
                      {
                        key: 'inactive',
                        text: 'Inactive',
                        ariaLabel:'Inactive',
                        iconProps: { iconName: 'Calendar' },
                        onClick: this.inactivestate,
                      },
                    ],
                  }}
                  disabled={(!this.state.disableedituserbutton && this.props.IsAdmin ) ? false :true }
                />
              </div>
            </div>
          </div>

          <Panel
            isOpen={this.state.showEditPanel}
            onDismiss={this.ondismissEdit}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            headerText={'Admin User Permissionns'}
          >
            <h3>
              {' '}
              User Persmissions for :{this.state.ItemSelectedforEdit.alias}{' '}
            </h3>

            {/* <div style={{ float: 'right' }}>
              <Toggle label="IsActive" defaultChecked />
            </div> */}
              {this.state.showEditPanel && this.onrenderCheckboxfn()}
            {/* </MarqueeSelection> */}
            <div className="ms-Grid" dir="ltr" style={{ marginTop: '10px' }}>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col">
                  <PrimaryButton text="Ok" name="Ok" aria-label="Ok"onClick={this.editpermissionsfnsubmit} />
                </div>
                <div className="ms-Grid-col">
                  <DefaultButton
                    text="Cancel"
                    name="Cancel" aria-label="Cancel"
                    onClick={() => this.ondismissEdit()}
                  />
                </div>
              </div>
            </div>
          </Panel>

          <Panel
            isOpen={this.state.showPendingPanel}
            onDismiss={this.ondismiss}
            type={PanelType.large}
            closeButtonAriaLabel="Close"
            headerText={
              this.state.panelflag === 'pending'
                ? 'Pending Requests'
                : 'Add New User'
            }
          >
            {this.state.panelflag !== 'pending' && (
              <div style={{ margin:'10px' }}>
                <PrimaryButton
                  text="Add Selected User"
                  label="Add Selected User"
                  aria-label="Add Selected User"
                  tabIndex={0}
                  disabled={this.state.disableadduserbutton}
                  onClick={this.addNewUserSubmit}
                />{' '}
              </div>
            )}

            {this.state.panelflag === 'pending' ? (
              <DetailsList
                compact={true}
                items={this.state.pendingList}
                columns={this._pendingcolumns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.none}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              />
            ) : (
              <div style={{marginTop:'10px'}}>
              <MarqueeSelection selection={this._selectionadd}>
                <DetailsList
                  compact={true}
                  items={this.state.newUsersList}
                  columns={this._addnewusercolumns}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  selection={this._selectionadd}
                  selectionPreservedOnEmptyClick={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="select row"
                />
              </MarqueeSelection>
              </div>
            )}
          </Panel>
          <Panel
            isOpen={this.state.showrejectiontextbox}
            onDismiss={this.ondismissRejectPanel}
            type={PanelType.smallFixedFar}
            closeButtonAriaLabel="Close"
            headerText="Provide reason for rejection"
          >

            <div style={{marginTop:'10px'}}>
            <TextField label="Reason" required multiline autoAdjustHeight onChange={this.onChangeRejectReason}/>

            <div className="ms-Grid" dir="ltr" style={{ marginTop: '10px' }}>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col">
                  <PrimaryButton text="Submit" name="Submit" aria-label="Submit"  onClick={() => this.approveOrRejectPendingRequets(this.state.rejectUserDetails,false)}/>
                </div>
                <div className="ms-Grid-col">
                  <DefaultButton
                    text="Cancel"
                    name="Cancel" aria-label="Cancel"
                    onClick={this.ondismissRejectPanel}
                  />
                </div>
              </div>
            </div>

           </div>


            
          </Panel>

          <div className="four ">
            <div style={{
            height: '60vh',
            position: 'relative',
            marginLeft: '1vw'}}
            >
          <ScrollablePane>
              <DetailsList
                compact={true}
                items={this.state.items}
                columns={this._columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                // onItemInvoked={this._onItemInvoked}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                isHeaderVisible={true}
              />
              </ScrollablePane>
            </div>
          
            
          </div>
        </Stack>
      </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    getUserModules: () => {
      dispatch(getUserModules());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    IsAdmin: state.user.IsAdmin,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)


