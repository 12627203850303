import * as React from 'react';
import {
  Stack,
  Panel,
  PanelType,
  // Checkbox,
  // PrimaryButton,
  // ActionButton,
  Spinner,
  Label,
  SpinnerSize,
  Selection,
  FontIcon,
  // Pivot,
  // PivotItem,
  Icon,
  TooltipHost,
  IContextualMenuProps,
  TextField,
  MessageBar,
  MessageBarType,
  Toggle,
  IButton,
  Fabric
} from 'office-ui-fabric-react';
import { PrimaryButton,ActionButton,Checkbox  } from '@fluentui/react';
import {Pivot, PivotItem } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  CheckboxVisibility
} from '@fluentui/react/lib/DetailsList';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import {
  CommandButton,
  DefaultButton,
} from 'office-ui-fabric-react/lib/Button';
import { connect } from 'react-redux';
import { ISupplier, IHeadCountMonthList,TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import {
  getBillableUnits,
  postInvoiceBillableUnits,
  getMonthListData,
  setPeriodDetailPriorAdjustment,
  postPriorPeriodAdjustmentDetails,
  isColumnSortedDetails,
  microsoftSubmittedBillableUnits,
  amountCalculationHeadCount,
  setBillableUnitsChange,
  isNRCDescriptionFetched,
} from '../../store/actions/headCount.action';
import { CardStyle } from '../../shared/styles/Card';
import VolumeList, {
  IAmountCalculation,
  ICurrencyTotalAmount,
} from './volume-nrc/detail-list/VolumeDetailList';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import './HeadCount.css';
import { getFiscalYearsForSupplier } from '../../store/actions/selectedSupplier.action';
import { IPriorPeriodAdjustment } from '../../shared/models/PriorPeriodAdjustment';
import { IInvoiceHeadCountDetails } from '../../shared/models/InvoiceHeadCountDetails';
import { IPriorPeriodDetails } from '../../shared/models/PriorPeriodDetails';
import { setIsLoadingGlobal, getUserPreferences, saveUserPreferences } from '../../store/actions/user.action';
import { IInvoiceHeadCountFilterDetails } from '../../shared/models/HeadcountFilterDetails.model';
import ExcelExport from '../common-components/ExcelExport';
import { MicrosoftSubmittedBillableUnits } from '../../shared/models/MicrosoftSubmittedBillableUnits';
import { IDataCount } from '../../shared/models/DataCount';
import AddNRC from './AddNRC';
import AddProxy from './AddProxy';
import AdditionalAttributes from '../common-components/additionalAttributes';
import FilterDropdown from '../common-components/FilterComponents/FilterDropdown';
import {setIsActionChanged,getEventDateTime} from '../../store/actions/invoiceView.action'
import { InvoicingEvent } from '../../shared/models/InvoicingEvent.enum';
import readXlsxFile from 'read-excel-file';
import {
  IInvoiceUploadDetails,
  IInvoiceUploadValidationError,
} from '../../shared/models/InvoiceUploadModels';
import { UserPreferences } from '../../shared/models/UserPreferences';
import { columnsForBillableUnitsViewInitialGrid } from '../common-components/ColumnsForGrid';
import HelmetTab from "../../shared/Helmet/Helmet";
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import appConfig from '../../assets/configuration/config';
import { css } from 'glamor';
import { Announced } from '@fluentui/react/lib/Announced';

// interface for the class props
export interface IHeadCountProps {
  getSupplierEngagements: any;
  supplierList: ISupplier[];
  getBillableUnits: any;
  setGlobalLoadingFlag: any;
  units: IInvoiceBillableUnitDetails[];
  postBillableUnits: any;
  getMonthList: any;
  monthList: IHeadCountMonthList[];
  amountTotal: IAmountCalculation[];
  totalAmount: ICurrencyTotalAmount[];
  fiscalYearNumbers: number[];
  getFiscalYearsForSupplier: any;
  setPeriodDetailPriorAdjustment: any;
  priorPeriod: IPriorPeriodAdjustment[];
  showMonthLoader: boolean;
  showErrorMessage: boolean;
  showErrorMessageMonth: boolean;
  postPriorPeriodAdjustmentDetails: any;
  isLoadingGlobal: boolean;
  isBillableUnitChange: boolean;
  isColumnSortedDetails: any;
  showInvoiceLoader: boolean;
  microsoftSubmittedBillableUnits: any;
  previousMonthMicrosoftSubmittedData: MicrosoftSubmittedBillableUnits[];
  amountCalculationHeadCount: any;
  dataCount: IDataCount;
  setBillableUnitsChange: any;
  setIsActionChanged: any;
  getEventDateTime: any;
  sdmLockedOn: string;
  modificationStartOn: string;
  accrualsPostOn: string;
  loggedinUserAlias: string;
  getUserPreferences: any;
  saveUserPreferences: any;
  userPreferences: UserPreferences;
  isNRCDescriptionFetched: any;
}

// interface for the class state.
export interface IHeadCountState {
  bulkuploadmodalmargin:string;
  showPanel: boolean;
  showUploadPanel: boolean;
  disableUpload: boolean;
  additionalAttributes: IAdditionalAttribute[];
  isFetchingSeid: boolean; // TODO: move this to redux later if necessary. This is in state only for the demo purposes.
  stateDisplayType: string;
  totalAmount: number;
  showPriorPeriodModal: boolean;
  selectedPostPeriod: string;
  items: IInvoiceBillableUnitDetails[];
  isReset: boolean;
  showNRC: boolean;
  showProxy: boolean;
  filteredItems: IInvoiceBillableUnitDetails[];
  isFilter: boolean;
  itemCountFilter: IDataCount;
  removeFilter: boolean;
  invoiceMonth: string;
  monthChanged: boolean;
  fileName: string;
  fileErrorMessage: string;
  fileSuccessMessage: string;
  displayUploadProgress: boolean;
  totalFileRecords: number;
  completedFileRecords: number;
  percentComplete: number;
  recordsUploadedCount: number;
  hideUploadSuccessMessage: boolean;
  hideUploadErrorMessage: boolean;
  hideUploadValidationDetails: boolean;
  userPreferencesLoaded: boolean;
  isAutomatedSEIDsFiltered: boolean;
  marginrightsubmit:string;
  showrightside:boolean;
  announcedMessage:string;
}

// This interface is for the additional attributes
export interface IAdditionalAttribute {
  name: string;
  displayName: string;
  selected: boolean;
  key: number;
}

export const MonthListInitialData: IHeadCountMonthList = {
  CalendarYear: 0,
  CumMonth: 0,
  Month: '',
  Adjustments: 0,
  IsCurrentInvoiceMonth: false,
  PeriodDetail: '',
  index:0
};

const textFieldRef = React.createRef<HTMLInputElement>();
const cancelButtonRef = React.createRef<IButton>();
const changetableAttributes = React.createRef<IButton>();
const iconProps = { iconName: 'FolderHorizontal' };

class HeadCount extends React.Component<IHeadCountProps, IHeadCountState> {
  private columns: IColumn[];
  private fileUploadValidationColumns: IColumn[];
  private _selection: Selection;
  private changedItems: IInvoiceHeadCountFilterDetails[];
  private submitBillableUnits: IInvoiceHeadCountDetails[];
  private selectedFiscalYear: number;
  private prevSelectedIndex: number = -1;
  private indexSelected: number = -1;
  private selectedMonthDetails: IHeadCountMonthList = MonthListInitialData;
  private resetBillableUnitsData: IInvoiceBillableUnitDetails[] = [];
  private isCurrentMonthFields: boolean;
  private currentCumulativeMonth: number;
  private dataFromGrid: IInvoiceBillableUnitDetails[] = [];
  private uploadSuccessData: IInvoiceUploadDetails[] = [];
  private duplicateUploadData: IInvoiceUploadDetails[] = [];
  private uploadErrorData: IInvoiceUploadValidationError[] = [];
  private formatFailureValidation: string[] = [];
  constructor(props) {
    super(props);

    this.state = {
      bulkuploadmodalmargin:'0%',
      showPanel: false,
      showUploadPanel: false,
      disableUpload: false,
      displayUploadProgress: true,
      totalFileRecords: 0,
      completedFileRecords: 0,
      recordsUploadedCount: 0,
      items: [],
      isReset: false,
      showNRC: false,
      showProxy: false,
      percentComplete: 0,
      hideUploadSuccessMessage: true,
      hideUploadErrorMessage: true,
      hideUploadValidationDetails: true,
      userPreferencesLoaded: false,
      additionalAttributes: AdditionalAttributes.filter(
        (x) =>
          x.name !== 'InvoicePrefix' &&
          x.name !== 'InternalOrder' &&
          x.name !== 'CostCenter' &&
          x.name !== 'AccountCode' &&
          x.name !== 'PurchaseOrder'
      ),
      isFetchingSeid: false,
      stateDisplayType: 'All',
      totalAmount: 0,
      showPriorPeriodModal: false,
      selectedPostPeriod: '',
      filteredItems: this.props.units,
      isFilter: false,
      itemCountFilter: {
        All: 0,
        Volume: 0,
        NRC: 0,
        PPA: 0,
      },
      removeFilter: false,
      invoiceMonth: '',
      monthChanged: false,
      fileName: '',
      fileErrorMessage: '',
      fileSuccessMessage: '',
      isAutomatedSEIDsFiltered: false,
      marginrightsubmit:'6px',
      showrightside:false,
      announcedMessage:undefined
    };
    // create the column definition.
    this.columns = [
      {
        key: 'column1',
        name: '',
        fieldName: 'month',
        minWidth: 10,
        maxWidth: 20,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onRender: this.renderMonthListColumn,
        data: 'string',
        isPadded: true,
      },
    ];
    this.fileUploadValidationColumns = [
      {
        key: 'column1',
        name: 'SEID',
        fieldName: 'supplierEngagementId',
        minWidth: 50,
        maxWidth: 100,
        isResizable:true,
        
        
      },
      {
        key: 'column2',
        name: 'ErrorMessage',
        fieldName: 'validationMessage',
        minWidth: 100,
        maxWidth: 200,
        isResizable:true,
        onRender: this.onRenderValidationMessage,
      },
    ];
    
    this.fileUploadValidationColumns.forEach(e => {
      if(e.key === 'validationMessage')
      {
        e.isMultiline = true;
      }
    });

    // State for selected component
    this._selection = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetails();
      },
    });
  }

  public componentDidMount() {
    document
      .getElementById('fiscalYearDropdown')
      .removeAttribute('aria-describedby');
    document
      .getElementsByClassName('ms-Dropdown-title')[0]
      .removeAttribute('aria-label');
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    this.props.getFiscalYearsForSupplier();
    this.props.setPeriodDetailPriorAdjustment();
    this.props.getUserPreferences();
    this.loadFiscalYears();
  }
  loadFiscalYears = () =>{
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      // Defining the url
      const url: string = `${Routes.GetAvailableFiscalYears}
      `;
      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchAvailableFiscalYearsForSupplierBegin,
        {
          headers,
          url,
        }
      );
      // console.log('Calling available fiscal years api start');
       gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
        .then((response: any) => {
        if(response.data && response.status === 200){
          // Logging success
          telemetryContext.logEvent(
            TelemetryEvent.FetchCurrentFiscalYearForBillableUnitsSuccess,
            {
              headers,
              url,
            }
          );
          this.selectedFiscalYear = response.data.currentFiscalYearNumber
          this.props.getMonthList(response.data.currentFiscalYearNumber);
        }
        })
        .catch((error) => {
          // console.log('Calling avaialable fiscal years failed');

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchCurrentFiscalYearForBillableUnitsFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error(
              'Fiscal Year information is not available ',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }
        });
    }
}
  resize = () => {
    let widnowSize = window.innerWidth;
if(widnowSize >639 && widnowSize < 769){
  this.setState({
    bulkuploadmodalmargin:'10%'
  })

}else if(widnowSize >500 && widnowSize <= 600){
  this.setState({
    bulkuploadmodalmargin:'20%'
  })
}else if(widnowSize >=426 && widnowSize <= 512){
  this.setState({
    bulkuploadmodalmargin:'20%'
  })
}else if(widnowSize >=320 && widnowSize <= 420){
  this.setState({
    bulkuploadmodalmargin:'20%'
  })
}else if(widnowSize >=240 && widnowSize <= 310){
  this.setState({
    bulkuploadmodalmargin:'40%'
  })
}

if(widnowSize === 1280){
  this.setState({
    marginrightsubmit:'45px'
  })
}
if(widnowSize < 1400){
  this.setState({
    showrightside:true
  })
}else{
  this.setState({
    showrightside:false
  })
}
  }

  public componentDidUpdate(prevProps) {
    if (this.indexSelected === -1) {
      const { monthList } = this.props;
      for (let i = 0; i < monthList.length; i++) {
        if (
          (monthList[i] as IHeadCountMonthList).IsCurrentInvoiceMonth === true
        ) {
          this.currentCumulativeMonth = monthList[i].CumMonth;
          this.selectedMonthDetails = monthList[i] as IHeadCountMonthList;
          this.indexSelected = i;
        }
      }
    }
    this._selection.setIndexSelected(this.indexSelected, true, true);

    // If user preferences changed (see getUserPreferences in componentDidMount) then pre-select those attributes.
    const prevUserPreferences: UserPreferences = prevProps.userPreferences || {};
    const thisUserPreferences: UserPreferences = this.props.userPreferences || {};
    if (!this.state.userPreferencesLoaded) {
      if (JSON.stringify(prevUserPreferences.billableUnitsCols) !== JSON.stringify(thisUserPreferences.billableUnitsCols)) {
        // Order the additionalAttributes in the order the user preferred them to be in according to
        // the order of the userPreferences.billableUnitsCols. Place all selected attributes first in the array and then
        // all unselected ones at the end.
        const additionalAttributes: IAdditionalAttribute[] = [];
        if (thisUserPreferences.billableUnitsCols) {
          thisUserPreferences.billableUnitsCols.forEach((name: string) => {
            const additionalAttribute: IAdditionalAttribute = this.state.additionalAttributes.find((x) => x.name === name);
            if (additionalAttribute) {
              additionalAttribute.selected = true;
              additionalAttributes.push(additionalAttribute);
            }
          })
        }
        additionalAttributes.push(...this.state.additionalAttributes.filter((x) => x.selected === false));
        this.setState({ additionalAttributes });
        this.setState({ userPreferencesLoaded: true });
      }
    }
  }

  public render() {
    // Copy the array prior to sorting it. Important to keep the additionalAttributes in the order set in
    // the componentDidUpdate function as it is based on user preferences.
    let sortedAdditionalAttributes: IAdditionalAttribute[] = [...this.state.additionalAttributes];
    sortedAdditionalAttributes = sortedAdditionalAttributes.sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
    return (
      <>
      <HelmetTab title={'Supplier Invoice'} />
      {this.state.announcedMessage ? <Announced message={this.state.announcedMessage} /> : undefined}
        {this.state.showNRC && (
          <AddNRC
            showNRC={this.state.showNRC}
            closeNRC={(isOpen) => this._closeNRC(isOpen)}
            fiscalYear={this.selectedFiscalYear}
            selectedCumMonth={this.selectedMonthDetails.CumMonth}
            // selectedMonthText={this.selectedMonthDetails.PeriodDetail}
          />
        )}
        {this.state.showProxy && (
          <AddProxy
            showProxy={this.state.showProxy}
            closeProxy={(isOpen) => this._closeProxy(isOpen)}
            fiscalYear={this.selectedFiscalYear}
          />
        )}
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          headerText="Additional Attributes:"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={this._onRenderFooterContent}
        >
      <HelmetTab title={'Supplier Invoice'} />
          {sortedAdditionalAttributes.map((attr) => {
            return (
              <div key={attr.key} style={{ marginBottom: '1vh' }}>
                <Checkbox
                  label={attr.displayName}
                  checked={attr.selected}
                  onChange={(ev, isChecked) =>
                    this._onCheckboxChange(attr.name, ev, isChecked)
                  }
                />{' '}
              </div>
            );
          })}
        </Panel>
        <Panel
          isOpen={this.state.showUploadPanel}
          type={PanelType.medium}
          onDismiss={this._hideUploadPanel}
          headerText={
            'Bulk Upload (' + this.selectedMonthDetails.PeriodDetail + ')'
          }
          closeButtonAriaLabel="close"
        >
          <Label style={{ paddingTop: '15px',marginTop:this.state.bulkuploadmodalmargin}}  htmlFor="filePathTextField">File Path</Label>
          <TextField
            id="filePathTextField"
            value={this.state.fileName ? this.state.fileName : 'Select a file'}
            iconProps={iconProps}
            onClick={this._handleFileButtonClick}
            onKeyDown={(e) => this._handleFileButtonClickEnter(e)}
            type='button'
            style={{ textAlign: 'left'}}
          />
          <input
            type="file"
            ref={textFieldRef}
            style={{ display: 'none' }}
            onChange={this._fileChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          />
          <br />
          <div hidden={this.state.displayUploadProgress}>
            <Spinner size={SpinnerSize.large} label="Uploading Records" tabIndex={0} />
          </div>
          <br />
          <div>
            <PrimaryButton
              allowDisabledFocus
              disabled={this.state.disableUpload}
              onClick={this.uploadHeads}
            >
              Upload
            </PrimaryButton>
            <DefaultButton
              onClick={this._hideUploadPanel}
              style={{ marginLeft: '1vw' }}
              componentRef={cancelButtonRef}
            >
              Cancel
            </DefaultButton>
          </div>
          <br />
          <div hidden={this.state.hideUploadSuccessMessage}>
            <br />
            <MessageBar messageBarType={MessageBarType.success}>
              {this.state.fileSuccessMessage}
            </MessageBar>
          </div>
          <div hidden={this.state.hideUploadErrorMessage}>
            <br />
            <MessageBar
              messageBarType={MessageBarType.error}
              style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}
            >
              {this.state.fileErrorMessage}
            </MessageBar>
          </div>
          <div hidden={this.state.hideUploadValidationDetails}>
            <DetailsList
              compact={true}
              items={this.uploadErrorData}
              selectionMode={SelectionMode.none}
              columns={this.fileUploadValidationColumns}
              ariaLabelForGrid="Validation Errors List"
            />
          </div>
          <div>
            <MessageBar messageBarType={MessageBarType.info} tabIndex={0}>
            To avoid any issues please download the data into excel and upload the same after modifying "Billable Units" column, please do not edit any other column.
            </MessageBar>
            <MessageBar messageBarType={MessageBarType.info} tabIndex={0}>
            Please click the submit button after uploading to save the data.
            </MessageBar>
          </div>
        </Panel>
        <div className="flex-container-headcount">
          <div className="flex-container-headcount-1">
            <h1 
            style={{	
              marginBottom: '0px',
              marginTop: '0px',
              paddingLeft: '15px',
              paddingBottom: '3px',
              paddingTop: '3px',
              fontSize: '18.72px'
            }}
              aria-label={'Supplier Invoice'}
            >
              Supplier Invoice
            </h1>
            <div className="flex-container-headcount-1-item">
              <FilterDropdown
                columns={columnsForBillableUnitsViewInitialGrid}
                item={
                  this.state.isFilter
                    ? this.state.filteredItems
                    : this.props.units
                }
                onFilterChange={this.displayFilterData}
                allItems={this.props.units}
                columnSorted={this.props.isColumnSortedDetails}
                disable={
                  this.props.showInvoiceLoader || this.props.showErrorMessage
                }
                isEditable={true}
              />
             {!this.state.showrightside ? <div className="flex-container-headcount-1-item-1">
                  {!this.isCurrentMonthFields ?
                <div className = 'submittedAutomatedSEIDDiv' style={{marginRight:this.state.marginrightsubmit}}>
                <div style={{float: 'left'}}>
                  <label className = 'submittedAutomatedSEIDLabel'>Submit Automated Volume:</label>
                </div>
                 <Toggle
                  ariaLabel="Submit Automated Volume"
                  style = {{marginTop: '4px'}}
                  inlineLabel={true}
                  onAriaLabel="On"
                  offAriaLabel="Off"
                  onChange={this.onChangeIsAutomatedSEIDsToggle}
                  defaultChecked={this.state.isAutomatedSEIDsFiltered}
                  role="switch"
                />
                </div>
                :
                <div className = 'submittedAutomatedSEIDDiv1'></div>
                }
                <Dropdown
                  aria-label="Fiscal Year Dropdown"
                  placeholder={this.selectedFiscalYear !== undefined ?`Fiscal Year ${this.selectedFiscalYear}`: "Select an option"}
                  id="fiscalYearDropdown"
                  title="FiscalYear"
                  onChange={(e, o) => this.onFiscalYearDropdownChange(o)}
                  options={this.buildFiscalYearDropdown()}
                  style={{ marginTop: '1vh' }}
                />
                <ExcelExport
                  billableUnitsData={this.props.units}
                  fileName="HeadCountBillableUnits"
                  isEnable={this.props.units.length}
                  isBillableUnits={true}
                  invoicePeriod={this.selectedMonthDetails.PeriodDetail}
                />
              </div>:
              <div className="flex-container-headcount-1-item-1">
              <div className="ms-Grid">
              <div className="ms-Grid-row parentheadcount" >
                  <div className="ms-Grid-col"><Stack>
                  {!this.isCurrentMonthFields ?
                    <div className = 'submittedAutomatedSEIDDiv'>
                    <div style={{float: 'left'}}>
                      <label className = 'submittedAutomatedSEIDLabel'>Submit Automated Volume:</label>
                    </div>
                     <Toggle
                      ariaLabel="Submit Automated Volume"
                      style = {{marginTop: '4px'}}
                      inlineLabel={true}
                      onAriaLabel="On"
                      offAriaLabel="Off"
                      onChange={this.onChangeIsAutomatedSEIDsToggle}
                      defaultChecked={this.state.isAutomatedSEIDsFiltered}
                      role="switch"
                    />
                    </div>
                    :
                    <div className = 'submittedAutomatedSEIDDiv1'></div>
                    }
                  </Stack>
                  </div>
                  <div className="ms-Grid-col"><Stack>
                  <Dropdown
                      aria-label="Fiscal Year Dropdown"
                      placeholder={this.selectedFiscalYear !== undefined ?`Fiscal Year ${this.selectedFiscalYear}`: "Select an option"}
                      id="fiscalYearDropdown"
                      title="FiscalYear"
                      onChange={(e, o) => this.onFiscalYearDropdownChange(o)}
                      options={this.buildFiscalYearDropdown()}
                      style={{ marginTop: '1vh' }}
                    />
                  </Stack>
                  </div>
                  <div className="ms-Grid-col"><Stack>
                  <ExcelExport
                      billableUnitsData={this.props.units}
                      fileName="HeadCountBillableUnits"
                      isEnable={this.props.units.length}
                      isBillableUnits={true}
                      invoicePeriod={this.selectedMonthDetails.PeriodDetail}
                    />
                  </Stack>
                  </div>
                  </div>
                  </div>
                  </div>}
            </div>
          </div>
          <div
            className="flex-container-headcount-3"
            style={{ marginBottom: '0px' }}
          >
            <div className="flex-container-headcount-3-item-1">
              <div>
                <Pivot onLinkClick={this.handleState}>
                  <PivotItem
                    headerText="All"
                    itemCount={
                      this.props.showInvoiceLoader
                        ? 0
                        : this.state.isFilter
                        ? this.state.itemCountFilter.All
                        : this.props.dataCount.All
                    }
                  />
                  <PivotItem
                    headerText="Volume"
                    itemCount={
                      this.props.showInvoiceLoader
                        ? 0
                        : this.state.isFilter
                        ? this.state.itemCountFilter.Volume
                        : this.props.dataCount.Volume
                    }
                  />
                  <PivotItem
                    headerText="NRC"
                    itemCount={
                      this.props.showInvoiceLoader
                        ? 0
                        : this.state.isFilter
                        ? this.state.itemCountFilter.NRC
                        : this.props.dataCount.NRC
                    }
                  />
                  <PivotItem
                    headerText="PPA"
                    itemCount={
                      this.props.showInvoiceLoader
                        ? 0
                        : this.state.isFilter
                        ? this.state.itemCountFilter.PPA
                        : this.props.dataCount.PPA
                    }
                  />
                </Pivot>
              </div>
              <div>                
                <ActionButton
                  onClick={this._showUploadPanel}
                  iconProps={{ iconName: 'Upload' }}
                  role="button"
                  disabled={
                    this.props.showInvoiceLoader ||
                    this.props.showErrorMessage ||
                    this.disableSubmitButton()
                  }
                >
                  Bulk Upload
                </ActionButton>
                <ActionButton
                  onClick={this._showProxy}
                  iconProps={{ iconName: 'AddFriend' }}
                  role="button"
                  disabled={this.props.showInvoiceLoader}
                >
                  Setup SD Proxies
                </ActionButton>
                <CommandButton
                  iconProps={{ iconName: 'Refresh' }}
                  text="Reset Units"
                  menuProps={this.getMenuProps()}
                  disabled={
                    this.props.showInvoiceLoader || this.props.showErrorMessage
                  }
                />
                <ActionButton
                  onClick={this._showNRC}
                  iconProps={{ iconName: 'Add' }}
                  role="button"
                  disabled={
                    this.isCurrentMonthFields || this.props.showInvoiceLoader
                  }
                >
                  Add NRC
                </ActionButton>
                <ActionButton
                  onClick={this._showPanel}
                  iconProps={{ iconName: 'Edit' }}
                  role="button"
                  disabled={
                    this.props.showInvoiceLoader || this.props.showErrorMessage
                  }
                  componentRef={changetableAttributes}
                >
                  Change Table Attributes
                </ActionButton>
              </div>
            </div>
            <div className="flex-container-headcount-2-item-1"></div>
          </div>
          <div
            className="flex-container-headcount-4"
            style={{ marginLeft: '0px', marginTop: '0px', width: '99%' }}
          >
            <div style={{ ...CardStyle, width: '13%', marginRight: '2%' }}>
              {this.displayMonthList()}
            </div>
            <div
              className="flex-container-headcount-4-item"
              style={{ marginBottom: '5% !important' }}
            >
              <VolumeList
                columns={columnsForBillableUnitsViewInitialGrid}
                billableUnit={
                  this.state.isReset
                    ? this.resetBillableUnitsData
                    : this.state.isFilter
                    ? this.state.filteredItems
                    : this.props.units
                }
                additionalAttributesToDisplay={this.state.additionalAttributes.filter(
                  (x) => x.selected === true
                )}
                updatedItems={(items) => this.updatedItems(items)}
                stateSelected={this.state.stateDisplayType}
                isEditable={true}
                selectedMonth={this.selectedMonthDetails}
                isFilter={this.state.isFilter}
                getData={(data) => this.getData(data)}
                isActualOrAccrual={false}
                onButtonClick={this.removeFilterData}
                onColumnReorder={(frozenColumns: number, draggedIndex: number, targetIndex: number) => this.handleColumnReorder(frozenColumns, draggedIndex, targetIndex)}
                isAutomatedSEIDsFiltered = {this.state.isAutomatedSEIDsFiltered}
              />
            </div>
          </div>
          <div className="flex-container-headcount-5">
            <div className="flex-container-headcount-5-item-1">
            <div>
                <Icon
                  style={{
                    fontSize: '12px',
                    color: '#0178D4',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                  }}
                  iconName="AlertSolid"
                />

                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'New'}
                </span>
                <Icon
                  style={{
                    fontSize: '12px',
                    color: 'green',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                    paddingLeft: '7px',
                  }}
                  iconName="CompletedSolid"
                />
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'Accepted'}
                </span>
                <Icon
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                    paddingLeft: '7px',
                  }}
                  iconName="StatusErrorFull"
                />
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'Rejected'}
                </span>
                
              </div>
              <div>

              <Icon
                  style={{
                    fontSize: '12px',
                    color: 'black',
                    marginLeft: '0px',
                    fontWeight: 'bold',
                    paddingLeft: '0px',
                  }}
                  iconName="People"
                />
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'Pending Supplier'}
                </span>

                <Icon
                  style={{
                    fontSize: '12px',
                    color: '#FFBC02',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                  }}
                  iconName="AwayStatus"
                />

                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'Pending Microsoft'}
                </span>
               
              </div>
              <div>
                <sup>
                  <Icon
                    style={{
                      fontSize: '7px',
                      color: 'black',
                      marginLeft: '1px',
                      fontWeight: 'bold',
                    }}
                    iconName="Asterisk"
                  />
                </sup>
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'Prior Period Adjustment'}
                </span>
                <sup>
                  <Icon
                    style={{
                      fontSize: '7px',
                      color: 'black',
                      marginLeft: '1px',
                      fontWeight: 'bold',
                      paddingLeft: '7px',
                    }}
                    iconName="Add"
                  />
                </sup>
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'NRC'}
                </span>
              </div>
              <div>
                <Icon
                  style={{
                    fontSize: '12px',
                    color: 'green',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                  }}
                  iconName="SquareShapeSolid"
                />

                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'SD1'}
                </span>
                <Icon
                  style={{
                    fontSize: '12px',
                    color: 'purple',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                    paddingLeft: '7px',
                  }}
                  iconName="SquareShapeSolid"
                />
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'SD2'}
                </span>
                <Icon
                  style={{
                    fontSize: '12px',
                    color: '#c17e06',
                    marginLeft: '1px',
                    fontWeight: 'bold',
                    paddingLeft: '7px',
                  }}
                  iconName="SquareShapeSolid"
                />
                <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                  {'Automation SEID'}
                </span>
              </div>
            </div>
            {this.isCurrentMonthFields?
                <div style = {{width:'44%'}}>
                  <Label style = {{fontSize:'12px',marginRight:'7px',paddingTop:"10px"}}>{'The data for this month has already been locked'}</Label>                  
                </div>
              :
                <div style = {{width:'44%'}}>
                  <Label style = {{fontSize:'12px',marginRight:'7px',paddingTop:"10px"}}>{`Accruals for ${this.state.invoiceMonth} will be processed on ${this.props.accrualsPostOn} PST and data for Actuals will be locked on ${this.props.sdmLockedOn} PST and user can review supplier modifications starting ${this.props.modificationStartOn} PST`}</Label>                  
                </div>
            }
            {this.props.units.length > 0 && !this.props.showErrorMessage && (
              <div className="flex-container-headcount-5-item-2">
                <Label style={{ fontSize: '17px' }}>
                  Total amount : {this.displayTotalAmount()}
                </Label>
                <PrimaryButton
                  disabled={
                    this.props.showInvoiceLoader ||
                    this.props.showErrorMessage ||
                    this.disableSubmitButton()
                  }
                  onClick={this.submitHeads}
                  role="button"
                >
                  Submit
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  // Handle column reorder from the detail list.
  private handleColumnReorder(frozenColumns: number, draggedIndex: number, targetIndex: number): void {
    // Adjust indexes for the frozen (unmovable) columns.
    draggedIndex -= frozenColumns;
    targetIndex -= frozenColumns;

    // Get the attributes that are selected.
    const additionalAttributes: IAdditionalAttribute[] = this.state.additionalAttributes.filter((x) => x.selected === true);
    // Get the dragged attribute.
    const draggedAttr: IAdditionalAttribute = additionalAttributes[draggedIndex];
    // Prepare the new additional attributes array.
    const newAdditionalAttributes: IAdditionalAttribute[] = [...additionalAttributes];
    // Insert before the dropped item.
    newAdditionalAttributes.splice(draggedIndex, 1);
    newAdditionalAttributes.splice(targetIndex, 0, draggedAttr);

    // Save user preferred columns (starting after first frozen columns) for the current order.
    const userPreferences: UserPreferences = {
      ...this.props.userPreferences,
      billableUnitsCols: newAdditionalAttributes.map((x) => x.name)
    }
    this.props.saveUserPreferences(userPreferences);

    // Add all the unselected attributes to the array.
    newAdditionalAttributes.push(...this.state.additionalAttributes.filter((x) => x.selected === false));
    // Set the state.
    this.setState({ additionalAttributes: newAdditionalAttributes });
  }

  //Function to get the filtered data for submitting the billable units when there is no change in the units
  private getData = (data: IInvoiceBillableUnitDetails[]) => {
    this.dataFromGrid = data;
  };
  private isNormalInteger = (num: number) => {
    if (num < 0) return false;
    if (num === 0) return false;
    if (num % 1 === 0) return true;
    else return false;
  };
  //Function to get the filtered data on applying filters (data from FilterDropdown component)
  private displayFilterData = (filteredItems, itemCount, isFilter) => {
    this.setState({
      filteredItems: filteredItems,
      itemCountFilter: itemCount,
      isFilter: isFilter,
    });
  };

  //Function to remove filter data 
  private removeFilterData = (isFilter) => {
    this.props.setIsActionChanged(true);
    this.setState({
      isFilter: isFilter,
    });
    this.setState({ removeFilter: true }, () => {
      this.props.setIsActionChanged(false);
    });
  };

  //Function to disable the submit button
  private disableSubmitButton = () => {
    let isUnitsEditable = this.props.units
      .filter((x) => !x.IsPriorPeriodAdjustment)
      .every((x) => x.IsEditable === true);
    if (isUnitsEditable) {
      return false;
    } else {
      return true;
    }
  };

    private onChangeIsAutomatedSEIDsToggle = () => {
        this.setState({ isAutomatedSEIDsFiltered: !this.state.isAutomatedSEIDsFiltered }); 
  };

  //Function to get the menu props for 'Reset Units' commandButton
  private getMenuProps = () => {
    const menuProps: IContextualMenuProps = {
      items: [
        {
          key: 'resetToZero',
          text: 'Zero ',
          onClick: this.resetBillableUnits,
        },
        {
          key: 'resetToPreviousMonth',
          text: 'Previous Month',
          onClick: this.resetBillableUnitsToPrevMonth,
        },
      ],
    };
    return menuProps;
  };

  //Function to set the state to open NRC modal
  private _showNRC = () => {
    this.setState({ showNRC: true });
  };

  private _showProxy = () => {
    this.setState({ showProxy: true });
  };

  //Function to set the state to close the NRC modal
  private _closeNRC = (isOpen: boolean) => {
    this.setState({ showNRC: isOpen });
    this.props.isNRCDescriptionFetched(false);
  };

  private _closeProxy = (isOpen: boolean) => {
    this.setState({ showProxy: isOpen });
  };
  //handling the key events  on select file field
  private _handleFileButtonClickEnter = (e) => {
    if(e.keyCode === 13 || e.keyCode === 32){
      textFieldRef.current.click();
    }
  };
  private _handleFileButtonClick = () => {
    textFieldRef.current.click();
  };
  private _fileChange = (e) => {
    var allowedExtensions = /(\.xls|\.xlsx)$/i;
    if (e.target.files[0]) {
      if (allowedExtensions.exec(e.target.files[0].name)) {
        this.setState({
          fileName: e.target.files[0].name,
          fileErrorMessage: '',
          disableUpload: false,
          hideUploadSuccessMessage: true,
          hideUploadErrorMessage: true,
          hideUploadValidationDetails: true,
        });
        this.uploadSuccessData.length = 0;
        this.uploadErrorData.length = 0;
        this.duplicateUploadData.length = 0;
        this.formatFailureValidation.length = 0;
      } else {
        this.setState({
          fileErrorMessage: 'Kindly upload only Excel file',
          fileName: '',
          hideUploadErrorMessage: false,
          disableUpload: true,
        });
      }
    } else {
      this.setState({ fileName: '', disableUpload: true });
    }
  };

  //Function to reset the billable units on button click
  private resetBillableUnits = () => {
    if(this.state.isFilter){
      this.resetBillableUnitsData =  this.state.filteredItems
    }
    else{
      this.resetBillableUnitsData = this.props.units;
    }
    for (let i = 0; i < this.resetBillableUnitsData.length; i++) {
      if (this.resetBillableUnitsData[i].IsEditable) {
        this.resetBillableUnitsData[i].BillableUnits = 0;
      }
    }
    if(this.state.isFilter){
      this.props.amountCalculationHeadCount(this.resetBillableUnitsData, true);
      this.setState({ isReset: false });
    }
    else{
      this.props.amountCalculationHeadCount(this.resetBillableUnitsData, false);
      this.setState({ isReset: true });
    }
  };

  //Function to reset the billable units of current month to the units of previous month
  private resetBillableUnitsToPrevMonth = () => {
    let { units, previousMonthMicrosoftSubmittedData } = this.props;
    this.resetBillableUnitsData = units;
    let current = this.resetBillableUnitsData.map((x) => {
      return x.SupplierEngagementId;
    });
    let prev = previousMonthMicrosoftSubmittedData.map((x) => {
      return x.SupplierEngagementId;
    });
    for (let i = 0; i < this.resetBillableUnitsData.length; i++) {
      if (prev.includes(current[i])) {
        for (let j = 0; j < previousMonthMicrosoftSubmittedData.length; j++) {
          if (
            current[i] === prev[j] &&
            this.resetBillableUnitsData[i].NRCId === 0 &&
            previousMonthMicrosoftSubmittedData[j].NRCId === 0 &&
            !this.resetBillableUnitsData[i].IsPriorPeriodAdjustment &&
            this.resetBillableUnitsData[i].IsEditable
          ) {
            this.resetBillableUnitsData[i].BillableUnits =
              previousMonthMicrosoftSubmittedData[j].MicrosoftUnits;
          }
        }
      } else if (this.resetBillableUnitsData[i].NRCId === 0) {
        this.resetBillableUnitsData[i].BillableUnits = 0;
      }
    }
    this.props.amountCalculationHeadCount(this.resetBillableUnitsData, false);
    this.setState({ isReset: true });
  };

  //Function to display the total amount
  private displayTotalAmount = (): JSX.Element => {
    const { totalAmount } = this.props;
    return (
      <span>
        {totalAmount.length === 0 || this.props.showInvoiceLoader ? (
          <span> 0.00 </span>
        ) : (
          totalAmount.map((x) => {
            return (
              <span key={x.key} style={{ marginRight: '1vw' }}>{`${
                x.currencyCode
              } ${x.totalAmount
                .toFixed(2)
                .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`}</span>
            );
          })
        )}
      </span>
    );
  };

  //Function for onChange of Fiscal year dropdown
  private onFiscalYearDropdownChange(o) {
    this.selectedFiscalYear = o.key;
    this.props.getMonthList(this.selectedFiscalYear);
  }

  //Function to display the monthList DetailsList
  private displayMonthList = () => {
    if (this.props.showMonthLoader) {
      return (
        <div style={{ marginTop: '25vh' }}>
          <Spinner label="Loading..." size={SpinnerSize.large} />
        </div>
      );
    }
    if (this.props.monthList.length > 0) {
      if (this.props.showErrorMessageMonth) {
        return (
          <div style={{ marginTop: '25vh' }}>
            <h1>Not Available</h1>
          </div>
        );
      } else {
        return (
          <Fabric
          style={{
            width: '100%',
            height: '67.5vh',
            overflow: 'auto',
            display: 'block',
            margin: '0 auto',
          }}
          tabIndex={0}
        >
          <DetailsList
            items={this.getMonthList()}
            compact={false}
            columns={this.columns}
            selection={this._selection}
            checkboxVisibility={CheckboxVisibility.hidden}
            checkButtonAriaLabel="Select a Single Month"
            ariaLabelForGrid="Month List"
            selectionPreservedOnEmptyClick={true}
            selectionMode={
              this.props.showInvoiceLoader
                ? SelectionMode.none
                : SelectionMode.single
            }
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={false}            
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection of Suppliers"
            styles={{
              root: {
                backgroundColor: 'white',
              },
            }}
          />
          </Fabric>
        );
      }
    }
  };

  //Function to get the month list data
  private getMonthList = () => {
    let months: IHeadCountMonthList[] = [];
    months = months.concat(this.props.monthList);
    return months;
  };

  //Function to store the state All, NRC, and Volume
  private handleState = (item: PivotItem) => {
    this.setState({ stateDisplayType: item.props.headerText });
    this.props.isColumnSortedDetails(false);
    this.props.setBillableUnitsChange(false);
  };
  private onRenderValidationMessage(item: IInvoiceUploadValidationError) {
    return (
      <TooltipHost content={item.validationMessage}>
        {item.validationMessage}
      </TooltipHost>
    )
  }
  //Function to display months in the detailslist
  private renderMonthListColumn = (item: any): JSX.Element =>  {
    return (
      <Stack horizontal horizontalAlign="space-between">
        <span style={{ fontSize: '13px', width: '100%', fontWeight: 430 }}>
           <span> <Checkbox  className="headcountcheckbox" name="myCheckbox"  key={item.index} checked={this.indexSelected === item.index ? true:false}  label={item.Month} /><p className='sr-only'> row   {item.index+1}</p>
            </span>
            </span>
        {/* <div>{item.Month}</div> */}
        {item.Adjustments > 0 && (
          <TooltipHost
            content="Supplier Modified"
            calloutProps={{ gapSpace: 0 }}
            styles={{ root: { display: 'inline-block' } }}
          >
            <Stack horizontalAlign="space-between" horizontal>
              <FontIcon
                style={{
                  fontSize: '16px',
                  paddingTop: '2px',
                  paddingRight: '5px',
                }}
                iconName="AlertSolid"
              />
              <div className='word_div'>{item.Adjustments}</div>
            </Stack>
          </TooltipHost>
        )}
      </Stack>
    );
  }

  //Function to posting the billalble units
  private submitHeads = () => {
    this.props.setGlobalLoadingFlag(true);
    //Need to filter data before sending
    if (this.props.isBillableUnitChange === true) {
      this.submitBillableUnits = this.changedItems.map((y) => ({
        billableUnits: y.BillableUnits,
        cumMonth: y.CumMonth,
        fiscalYearNumber: y.FiscalYearNumber,
        supplierEngagementId: y.SupplierEngagementId,
        postCumMonth: y.PostCumMonth,
        comment: '',
        nrcId: y.NRCId,
      }));       
    } else {
      this.submitBillableUnits = this.dataFromGrid.map((y) => ({
        billableUnits: y.BillableUnits,
        cumMonth: y.CumMonth,
        fiscalYearNumber: y.FiscalYearNumber,
        supplierEngagementId: y.SupplierEngagementId,
        postCumMonth: y.PostCumMonth,
        comment: '',
        nrcId: y.NRCId,
      }));
    } 
    if(this.state.isAutomatedSEIDsFiltered === false) {
        const automatedSEIDList = this.props.units.filter(u => u.IsAutomationSupplierEngagement && u.IsNRC === false).map((y) => ({
            supplierEngagementId: y.SupplierEngagementId,
            nrcId: y.NRCId,
        }));
        this.submitBillableUnits = this.submitBillableUnits.filter(f => automatedSEIDList.filter(d => d.supplierEngagementId === f.supplierEngagementId && d.nrcId === f.nrcId).length === 0)
    }
    this.props.postBillableUnits(
      this.submitBillableUnits,
      this.submitBillableUnits.length
    );
  };

  //Function to update the billable units
  private updatedItems = (items: IInvoiceHeadCountFilterDetails[]) => {
    this.changedItems = items;
  };

  //Function to get the selection details for months
  private _getSelectionDetails(): IHeadCountMonthList {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return;

      case 1:
        const monthSelection = this._selection.getSelection()[0] as IHeadCountMonthList;
        this.selectedMonthDetails = monthSelection;

        this.indexSelected = this.props.monthList.findIndex(
          (x) => x.CumMonth === monthSelection.CumMonth
        );
        if (this.indexSelected !== this.prevSelectedIndex) {
          this.props.getBillableUnits(monthSelection.CumMonth);
          this.props.setIsActionChanged(true);
          this.prevSelectedIndex = this.indexSelected;
          this.props.isColumnSortedDetails(false);
          this.setState({ isReset: false, isFilter: false , invoiceMonth: monthSelection.Month});
          if (monthSelection.CumMonth >= this.currentCumulativeMonth) {
            this.isCurrentMonthFields = false;
          } else {
            this.isCurrentMonthFields = true;
          }
          this.props.microsoftSubmittedBillableUnits(
            Number(this.selectedMonthDetails.CumMonth)
          );
          if (monthSelection.CumMonth < this.selectedMonthDetails.CumMonth) {
            this.props.microsoftSubmittedBillableUnits(monthSelection.CumMonth);
          }
          this.setState({ removeFilter: true }, () => {
            this.props.setIsActionChanged(false);
          });
          this.setState({announcedMessage:`${monthSelection.Month} row ${this.indexSelected} Selected` })

          this.props.getEventDateTime(InvoicingEvent.LockedForMicrosoft,monthSelection.CumMonth,0);
          this.props.getEventDateTime(InvoicingEvent.InvoiceMonthStart,monthSelection.CumMonth + 1,0);
          this.props.getEventDateTime(InvoicingEvent.InvoiceAccrual,monthSelection.CumMonth,0);
        }
        return monthSelection;
    }
  }

  // Fiscal year dropdown creation method
  private buildFiscalYearDropdown = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];

    const { fiscalYearNumbers } = this.props;
    fiscalYearNumbers.forEach((x) => {
      options.push({
        key: x,
        text: `Fiscal Year ${x.toString()}`,
      });
    });

    return options;
  };

  //Function to set the state to open the panel
  private _showPanel = () => {
    this.setState({ showPanel: true });
  };
  private _hidePanel = () => {
    changetableAttributes.current.focus();
    this.setState({ showPanel: false });
  };
  private _showUploadPanel = () => {
    this.setState({ showUploadPanel: true, disableUpload: true, fileName: '' });
  };

  //Function to set the state to close the panel
  private _hideUploadPanel = () => {
    this.setState({
      showUploadPanel: false,
      disableUpload: true,
      hideUploadErrorMessage: true,
      hideUploadSuccessMessage: true,
      hideUploadValidationDetails: true,
      fileName: '',
    });
    this.uploadSuccessData.length = 0;
    this.uploadErrorData.length = 0;
    this.duplicateUploadData.length = 0;
    this.uploadErrorData.length = 0;
    this.formatFailureValidation.length = 0;
  };

  // Additonal Attributes checkbox selection
  private _onCheckboxChange(
    changedAttribute: string,
    ev: React.FormEvent<HTMLElement>,
    isChecked: boolean
  ): void {
    // Setting the selected true for the toggled checkbox.
    const additionalAttributes = [...this.state.additionalAttributes];
    const matchedAttribute: IAdditionalAttribute = additionalAttributes.find(
      x => x.name === changedAttribute
    );
    matchedAttribute.selected = isChecked;
    // Move the matchedAttribute from it's current place in the array to the end of the array. This will make
    // the attribute appear at the end of the columns. The user could then reorder them if desired.
    if (isChecked) {
      const index: number = additionalAttributes.indexOf(matchedAttribute);
      additionalAttributes.splice(index, 1);
      additionalAttributes.push(matchedAttribute);
    }
    this.setState({ additionalAttributes });

    // Save user preferences. If matched attribute was checked then add to end of array,
    // otherwise remove from the place it was at. xxx
    const billableUnitsCols: string[] = [...additionalAttributes.filter((x) => {
      if (x.selected) {
        return x;
      } else {
        return null;
      }
    }).map(x => x.name)];

    const userPreferences: UserPreferences = {
      ...this.props.userPreferences,
      billableUnitsCols
    }
    this.props.saveUserPreferences(userPreferences);
  }

  //Function to show the 'Done' button in Additional Attributes panel
  private _onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          onClick={this._hidePanel}
          style={{ marginRight: '8px',color:'rgb(255, 255, 255)' }}
          role="button"
          aria-label='Done'
          label='Done'
        >
          Done
        </PrimaryButton>
      </div>
    );
  };
  private uploadHeads = (e) => {
    this.setState({
      disableUpload: true,
      displayUploadProgress: false,
      fileName: '',
    });
    this.uploadSuccessData.length = 0;
    this.uploadErrorData.length = 0;
    this.duplicateUploadData.length = 0;
    this.formatFailureValidation.length = 0;
    var uploadedColumns: string[] = [];
    let validationMessage: string = '';
    let ValidateColumnsExists: boolean = false;
    let ValidateColumnuplicate: boolean = false;
    let SEIDIndex: number = 0;
    let billableUnitIndex: number = 0;
    let IsNRCIndex: number = 0;
    let NRCIdIndex: number = 0;
    let InvoicePeriodIndex: number = 0;
    let PostInvoicePeriodIndex: number = 0;
    var seidColumnExists: boolean = false;
    var isNRCCOlumnExists: boolean = false;
    var NRCIdColumExists: boolean = false;
    var billableUnitColumnExists: boolean = false;
    var recordsUploaded: number = 0;
    var recordsRejected: number = 0;
    var invoicePeriodColumnExists: boolean = false;
    var postinvoicePeriodColumnExists: boolean = false;
    var seidColumnsCount: number = 0;
    var isNRCColumnsCount: number = 0;
    var nrcIdColumnsCount: number = 0;
    var billableUnitsColumnsCount: number = 0;
    var invoicePeriodColumnsCount: number = 0;
    var postInvoicePeriodColumnsCount: number = 0;
    readXlsxFile(textFieldRef.current.files[0]).then((rows) => {
      this.setState({
        totalFileRecords: rows.length - 1,
        completedFileRecords: 0,
      });

      //reading column data from first row of Excel
      for (var i = 0; i < 1; i++) {
        let uploadedRows = rows[i];
        for (var j = 0; j < uploadedRows.length; j++) {
          uploadedColumns[j] = uploadedRows[j];
        }
      }
      for (var k = 0; k < uploadedColumns.length; k++) {
        if (
          uploadedColumns[k] &&
          uploadedColumns[k].trim().toLowerCase() === 'supplier engagement id'
        ) {
          seidColumnExists = true;
          SEIDIndex = k;
          seidColumnsCount = seidColumnsCount + 1;
        }
        if (
          uploadedColumns[k] &&
          uploadedColumns[k].trim().toLowerCase() === 'isnrc'
        ) {
          isNRCCOlumnExists = true;
          IsNRCIndex = k;
          isNRCColumnsCount = isNRCColumnsCount + 1;
        }
        if (
          uploadedColumns[k] &&
          uploadedColumns[k].trim().toLowerCase() === 'nrcid'
        ) {
          NRCIdColumExists = true;
          NRCIdIndex = k;
          nrcIdColumnsCount = nrcIdColumnsCount + 1;
        }
        if (
          uploadedColumns[k] &&
          uploadedColumns[k].trim().toLowerCase() === 'billable units'
        ) {
          billableUnitColumnExists = true;
          billableUnitIndex = k;
          billableUnitsColumnsCount = billableUnitsColumnsCount + 1;
        }
        if (
          uploadedColumns[k] &&
          uploadedColumns[k].trim().toLowerCase() === 'invoice period'
        ) {
          InvoicePeriodIndex = k;
          invoicePeriodColumnExists = true;
          invoicePeriodColumnsCount = invoicePeriodColumnsCount + 1;
        }
        if (
          uploadedColumns[k] &&
          uploadedColumns[k].trim().toLowerCase() === 'post invoice period'
        ) {
          PostInvoicePeriodIndex = k;
          postinvoicePeriodColumnExists = true;
          postInvoicePeriodColumnsCount = postInvoicePeriodColumnsCount + 1;
        }
      }
      if (
        seidColumnExists &&
        isNRCCOlumnExists &&
        NRCIdColumExists &&
        billableUnitColumnExists &&
        postinvoicePeriodColumnExists &&
        invoicePeriodColumnExists
      )
        ValidateColumnsExists = true;
      else ValidateColumnsExists = false;
      if (
        seidColumnsCount > 1 ||
        isNRCColumnsCount > 1 ||
        nrcIdColumnsCount > 1 ||
        billableUnitsColumnsCount > 1 ||
        invoicePeriodColumnsCount > 1 ||
        postInvoicePeriodColumnsCount > 1
      )
        ValidateColumnuplicate = false;
      else ValidateColumnuplicate = true;
      if (!ValidateColumnsExists) {
        validationMessage = ' Below column/columns are not exists in the file:';
        if (!seidColumnExists) {
          validationMessage = validationMessage + '\nSupplier Engagement Id';
        }
        if (!isNRCCOlumnExists) {
          validationMessage = validationMessage + '\nIsNRC';
        }
        if (!NRCIdColumExists) {
          validationMessage = validationMessage + '\nNRCId';
        }
        if (!billableUnitColumnExists) {
          validationMessage = validationMessage + '\nBillable Units';
        }
        if (!invoicePeriodColumnExists) {
          validationMessage = validationMessage + '\nInvoice Period';
        }
        if (!postinvoicePeriodColumnExists) {
          validationMessage = validationMessage + '\nPost Invoice Period';
        }
      }
      if (!ValidateColumnuplicate && ValidateColumnsExists) {
        validationMessage =
          'Below column/columns are appeared more than once in the file';
        if (seidColumnsCount > 1) {
          validationMessage = validationMessage + '\nSupplier Engagement Id';
        }
        if (isNRCColumnsCount > 1) {
          validationMessage = validationMessage + '\nIsNRC';
        }
        if (nrcIdColumnsCount > 1) {
          validationMessage = validationMessage + '\nNRCId';
        }
        if (billableUnitsColumnsCount > 1) {
          validationMessage = validationMessage + '\nBillable Units';
        }
        if (invoicePeriodColumnsCount > 1) {
          validationMessage = validationMessage + '\nInvoice Period';
        }
        if (postInvoicePeriodColumnsCount > 1) {
          validationMessage = validationMessage + '\nPost Invoice Period';
        }
      }
      if (validationMessage !== '') {
        this.setState({
          fileErrorMessage: validationMessage,
          displayUploadProgress: true,
          hideUploadErrorMessage: false,
          fileName: '',
        });
        textFieldRef.current.value = null;
      } else {
        this.resetBillableUnitsData = this.props.units;
        this.setState({
          displayUploadProgress: false,
          totalFileRecords: rows.length - 1,
        });

        for (var m = 0; m < this.resetBillableUnitsData.length; m++) {
          for (var l = 1; l < rows.length; l++) {
            // this.calculateAndUpdatePercentCompleted(l, rows.length - 1);
            let uploadedData = rows[l];
            var seid: number = 0;
            var billableUnits: number = 0;
            var fileNRCFlag: boolean;
            var nrcId: number = 0;
            var validationMessageForUpdate: string = '';
            var invoicePeriod: string = '';
            var postInvoicePeriod:string = '';
            for (var n = 0; n < uploadedData.length; n++) {
              if (n === SEIDIndex) {
                if (
                  uploadedData[n] !== null &&
                  !isNaN(uploadedData[n]) &&
                  this.isNormalInteger(uploadedData[n])
                ) {
                  seid = parseInt(uploadedData[n]);
                } else {
                  this.formatFailureValidation.push(
                    'Supplier Engagement not in the correct format with Value ' +
                      uploadedData[n]
                  );
                  break;
                }
              }
              if (n === InvoicePeriodIndex) {
                if (uploadedData[n] !== null) {
                  invoicePeriod = uploadedData[n];
                } else {
                  this.formatFailureValidation.push(
                    'Invoice month not in the correct format for seid ' + seid
                  );
                  break;
                }
              }
              if (n === PostInvoicePeriodIndex) {
                if (uploadedData[n] !== null) {
                  postInvoicePeriod = uploadedData[n];
                } else {
                  this.formatFailureValidation.push(
                    'Post Invoice month not in the correct format for seid ' +
                      seid
                  );
                  break;
                }
              }

              // Pending validation
              if (n === IsNRCIndex) {
                if (
                  uploadedData[n] === 'true' ||
                  uploadedData[n] === true ||
                  uploadedData[n] === 'false' ||
                  uploadedData[n] === false
                ) {
                  if (uploadedData[n] === 'true' || uploadedData[n] === true) {
                    fileNRCFlag = true;
                  }
                  if (
                    uploadedData[n] === 'false' ||
                    uploadedData[n] === false
                  ) {
                    fileNRCFlag = false;
                  }
                } else {
                  if (
                    this.resetBillableUnitsData[m].SupplierEngagementId === seid
                  ) {
                    this.formatFailureValidation.push(
                      'IsNRC should be true or false instead of provided value ' +
                        uploadedData[n] +
                        ' for seid ' +
                        seid
                    );
                    break;
                  }
                }
              }
              // Pending validation
              if (n === NRCIdIndex) {
                if (uploadedData[n] !== null && !isNaN(uploadedData[n])) {
                  if (
                    this.resetBillableUnitsData[m].SupplierEngagementId ===
                      seid &&
                    this.resetBillableUnitsData[m].IsNRC &&
                    fileNRCFlag &&
                    this.isNormalInteger(uploadedData[n])
                  ) {
                    nrcId = parseInt(uploadedData[n]);
                  } else {
                    if (
                      this.resetBillableUnitsData[m].SupplierEngagementId ===
                        seid &&
                      this.resetBillableUnitsData[m].IsNRC &&
                      fileNRCFlag
                    ) {
                      this.formatFailureValidation.push(
                        'NRCId should be non negative and whole number seid ' +
                          seid
                      );
                      break;
                    }
                  }
                } else {
                  if (
                    this.resetBillableUnitsData[m].SupplierEngagementId === seid
                  ) {
                    this.formatFailureValidation.push(
                      'NRCId should be in correct format for seid ' + seid
                    );
                    break;
                  }
                }
              }
              if (n === billableUnitIndex) {
                if (uploadedData[n] !== null && !isNaN(uploadedData[n])) {
                  billableUnits = parseFloat(uploadedData[n].toFixed(2));
                } else {
                  if (
                    this.resetBillableUnitsData[m].SupplierEngagementId ===
                      seid &&
                    this.resetBillableUnitsData[m].IsNRC === fileNRCFlag &&
                    this.resetBillableUnitsData[m].NRCId === nrcId
                  ) {
                    this.uploadErrorData.push({
                      supplierEngagementId: seid,
                      validationMessage:
                        'Billable Units should be in correct format',
                    });
                    break;
                  }
                }
              }
              if (n === uploadedData.length - 1 && seid !== 0) {
                if(this.resetBillableUnitsData[m].SupplierEngagementId ===
                  seid &&
                this.resetBillableUnitsData[m].IsNRC === fileNRCFlag && this.resetBillableUnitsData[m].NRCId === nrcId && this.selectedMonthDetails.PeriodDetail !== invoicePeriod )
                {
                  validationMessageForUpdate = 'Cannot upload for the Invoice month other than current month';
                }
                if(this.resetBillableUnitsData[m].SupplierEngagementId ===
                  seid &&
                this.resetBillableUnitsData[m].IsNRC === fileNRCFlag && this.resetBillableUnitsData[m].NRCId === nrcId && this.selectedMonthDetails.PeriodDetail !== postInvoicePeriod )
                {
                  validationMessageForUpdate = 'Cannot upload for the Post Invoice month other than current month';
                }
                if (
                  validationMessageForUpdate !== '' &&
                  this.resetBillableUnitsData[m].SupplierEngagementId ===
                    seid &&
                  this.resetBillableUnitsData[m].IsNRC === fileNRCFlag
                ) {
                  this.uploadErrorData.push({
                    supplierEngagementId: seid,
                    validationMessage: validationMessageForUpdate,
                  });
                  break;
                } else {
                  if (
                    fileNRCFlag &&
                    this.resetBillableUnitsData[m].SupplierEngagementId ===
                      seid &&
                    this.resetBillableUnitsData[m].IsNRC &&
                    this.resetBillableUnitsData[m].NRCId === nrcId &&
                    nrcId !== 0
                  ) {
                    this.uploadSuccessData.push({
                      supplierEngagementId: seid,
                      billableUnits: billableUnits,
                      isNrc: fileNRCFlag,
                      nrcId: nrcId,
                    });
                  } else {
                    if (
                      !fileNRCFlag &&
                      this.resetBillableUnitsData[m].SupplierEngagementId ===
                        seid &&
                      this.resetBillableUnitsData[m].IsVolume
                    ) {
                      this.uploadSuccessData.push({
                        supplierEngagementId: seid,
                        billableUnits: billableUnits,
                        isNrc: fileNRCFlag,
                        nrcId: nrcId,
                      });
                    }
                  }
                }
              }
            }
          }
        }
        //remove duplicates and update
        for (var s = 0; s < this.uploadSuccessData.length; s++) {
          for (var t = 0; t < this.uploadSuccessData.length; t++) {
            if (s !== t) {
              if (
                this.uploadSuccessData[t].supplierEngagementId ===
                  this.uploadSuccessData[s].supplierEngagementId &&
                this.uploadSuccessData[t].isNrc ===
                  this.uploadSuccessData[s].isNrc &&
                this.uploadSuccessData[t].nrcId ===
                  this.uploadSuccessData[s].nrcId
              ) {
                this.duplicateUploadData.push({
                  supplierEngagementId: this.uploadSuccessData[t]
                    .supplierEngagementId,
                  isNrc: this.uploadSuccessData[t].isNrc,
                  billableUnits: this.uploadSuccessData[t].billableUnits,
                  nrcId: this.uploadSuccessData[t].nrcId,
                });
                this.uploadSuccessData.splice(s, 1);
              }
            }
          }
        }
        for (var v = 0; v < this.duplicateUploadData.length; v++) {
          for (var w = 0; w < this.uploadSuccessData.length; w++) {
            if (
              this.duplicateUploadData[v].supplierEngagementId ===
                this.uploadSuccessData[w].supplierEngagementId &&
              this.duplicateUploadData[v].isNrc ===
                this.uploadSuccessData[w].isNrc &&
              this.duplicateUploadData[v].nrcId ===
                this.uploadSuccessData[w].nrcId
            ) {
              this.duplicateUploadData.push({
                supplierEngagementId: this.uploadSuccessData[w]
                  .supplierEngagementId,
                isNrc: this.uploadSuccessData[w].isNrc,
                billableUnits: this.uploadSuccessData[w].billableUnits,
                nrcId: this.uploadSuccessData[w].nrcId,
              });
              this.uploadSuccessData.splice(w, 1);
            }
          }
        }
        for (var u = 0; u < this.duplicateUploadData.length; u++) {
          this.uploadErrorData.push({
            supplierEngagementId: this.duplicateUploadData[u]
              .supplierEngagementId,
            validationMessage:
              'Duplicates are upoaded in file will not be considered',
          });
        }
        for (var o = 0; o < this.resetBillableUnitsData.length; o++) {
          for (var p = 0; p < this.uploadSuccessData.length; p++) {
            if (
              this.resetBillableUnitsData[o].SupplierEngagementId ===
                this.uploadSuccessData[p].supplierEngagementId &&
              this.resetBillableUnitsData[o].IsNRC ===
                this.uploadSuccessData[p].isNrc &&
              this.resetBillableUnitsData[o].NRCId ===
                this.uploadSuccessData[p].nrcId &&
              !this.resetBillableUnitsData[o].IsPriorPeriodAdjustment
            ) {
              this.resetBillableUnitsData[
                o
              ].BillableUnits = this.uploadSuccessData[p].billableUnits;
            }
          }
        }
      }
      var uniqueFormatValidationRecords = this.formatFailureValidation.filter(
        function (elem, index, self) {
          return index === self.indexOf(elem);
        }
      );
      for (var q = 0; q < uniqueFormatValidationRecords.length; q++) {
        this.uploadErrorData.push({
          supplierEngagementId: 0,
          validationMessage: uniqueFormatValidationRecords[q],
        });
      }
      recordsUploaded = this.uploadSuccessData.length;
      recordsRejected = this.uploadErrorData.length;
      // both true
      if (recordsUploaded > 0 && recordsRejected > 0) {
        this.props.amountCalculationHeadCount(
          this.resetBillableUnitsData,
          false
        );
        this.setState({
          fileErrorMessage:
            recordsRejected +
            ' records failed for upload with below validations',
          fileSuccessMessage: recordsUploaded + ' Records Updated',
          hideUploadSuccessMessage: false,
          hideUploadErrorMessage: false,
          hideUploadValidationDetails: false,
          displayUploadProgress: true,
          fileName: '',
        });
        textFieldRef.current.value = null;
      }
      // only success
      if (recordsUploaded > 0 && recordsRejected === 0) {
        this.props.amountCalculationHeadCount(
          this.resetBillableUnitsData,
          false
        );
        this.setState({
          fileSuccessMessage: recordsUploaded + ' Records Updated',
          hideUploadSuccessMessage: false,
          hideUploadValidationDetails: true,
          displayUploadProgress: true,
          fileName: '',
        });
        textFieldRef.current.value = null;
      }
      // only failure
      if (recordsUploaded === 0 && recordsRejected > 0) {
        this.setState({
          fileErrorMessage:
            recordsRejected +
            ' records failed for upload with below validations',
          hideUploadErrorMessage: false,
          displayUploadProgress: true,
          hideUploadValidationDetails: false,
          fileName: '',
        });
        textFieldRef.current.value = null;
      }
    });
    cancelButtonRef.current.focus();
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBillableUnits: (cumMonth: number) => {
      dispatch(getBillableUnits(cumMonth));
    },
    postBillableUnits: (
      units: IInvoiceHeadCountDetails[],
      numberOfUnitsSubmitted: number
    ) => {
      dispatch(postInvoiceBillableUnits(units, numberOfUnitsSubmitted));
    },
    setGlobalLoadingFlag: (flag: boolean) => {
      dispatch(setIsLoadingGlobal(flag));
    },
    getMonthList: (fiscalYear: number) => {
      dispatch(getMonthListData(fiscalYear));
    },
    getFiscalYearsForSupplier: () => {
      dispatch(getFiscalYearsForSupplier());
    },
    setPeriodDetailPriorAdjustment: () => {
      dispatch(setPeriodDetailPriorAdjustment());
    },
    postPriorPeriodAdjustmentDetails: (
      priorPeriodDetails: IPriorPeriodDetails,
      cumMonth: number,
      fiscalYearNumber: number
    ) => {
      dispatch(
        postPriorPeriodAdjustmentDetails(
          priorPeriodDetails,
          cumMonth,
          fiscalYearNumber
        )
      );
    },
    isColumnSortedDetails: (isColumnSorted: boolean) => {
      dispatch(isColumnSortedDetails(isColumnSorted));
    },
    microsoftSubmittedBillableUnits: (CumMonth: number) => {
      dispatch(microsoftSubmittedBillableUnits(CumMonth));
    },
    amountCalculationHeadCount: (
      billableUnits: IInvoiceBillableUnitDetails[],
      isFilter: boolean
    ) => {
      dispatch(amountCalculationHeadCount(billableUnits, isFilter));
    },
    setBillableUnitsChange: (isBillableUnitChange: boolean) => {
      dispatch(setBillableUnitsChange(isBillableUnitChange));
    },
    setIsActionChanged: (isActionChanged: boolean) => {
      dispatch(setIsActionChanged(isActionChanged));
    },
    getEventDateTime: (EventName: string, CumMonth: number,SupplierId: number) => {
      dispatch(getEventDateTime(EventName, CumMonth, SupplierId));
    },
    getUserPreferences: () => {    
      dispatch(getUserPreferences());
    },
    saveUserPreferences: (userPreferences: UserPreferences) => {
      dispatch(saveUserPreferences(userPreferences));
    },
    isNRCDescriptionFetched: (isDescriptionFetched: boolean) =>{
      dispatch(isNRCDescriptionFetched(isDescriptionFetched));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    supplierList: state.suppliers.suppliersForCurrentUserList,
    fiscalYearNumbers: state.selectedSupplier.availableFiscalYears,
    units: state.headCount.billableUnits,
    monthList: state.headCount.monthList,
    amountTotal: state.headCount.amountTotal,
    totalAmount: state.headCount.totalAmount,
    priorPeriod: state.headCount.priorPeriod,
    showMonthLoader: state.headCount.isFetchingMonth,
    showErrorMessage: state.headCount.IsErrorMessage,
    showErrorMessageMonth: state.headCount.IsErrorMessageMonth,
    isLoadingGlobal: state.user.isLoadingGlobal,
    isBillableUnitChange: state.headCount.isBillableUnitChange,
    showInvoiceLoader: state.headCount.isFetchingInvoice,
    previousMonthMicrosoftSubmittedData:
    state.headCount.microsoftSubmittedBillableUnits,
    dataCount: state.headCount.dataCount,
    sdmLockedOn: state.invoiceView.sdmLockedOn,
    modificationStartOn: state.invoiceView.modificationStartOn,
    accrualsPostOn: state.invoiceView.accrualsPostOn,
    loggedinUserAlias: state.user.Alias,
    userPreferences: state.user.preferences
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeadCount);
