import * as React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Toggle } from '@fluentui/react/lib/Toggle';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
  SelectionMode,
} from '@fluentui/react/lib/DetailsList';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { Dropdown, IDropdownOption, IDropdown } from '@fluentui/react';
import {
  IconButton,
} from 'office-ui-fabric-react/lib/Button';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { getNRCLineItemCategories} from '../../store/actions/nrclineitem.action';
import { connect } from 'react-redux';
import {ScrollablePane } from 'office-ui-fabric-react';
import appConfig from '../../assets/configuration/config';
import { v4 as uuidv4 } from 'uuid';
import { TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { delay } from 'q';
import {  NRCCategoryMockData} from '../../shared/mock/NRCMockData';
import {  INrcCategory} from '../../shared/models/NRC';
import HelmetTab from "../../shared/Helmet/Helmet";
import { Stack} from '@fluentui/react/lib/Stack';
import { getUserRoles } from '../../store/actions/user.action';



export interface IAddNRCLineItemProps {
 
  getNRCLineItemCategories: any;
  nrcLineCategories:any;
  getUserRoles: any;  
  IsInvoicingManager: boolean;
  IsAdmin: boolean;

 
}


export interface INrcLineItemState {
  showPendingPanel: boolean;
  showaddpanel:boolean;
  showeditpanel:boolean;
  showfilterpanel:boolean;
  selectionDetails: any;
  items: INrcCategory[];
  panelflag: any;
  showEditPanel: boolean;
  filterOptions: IDropdownOption[];
  showsuppliercontacts:boolean;
  itemSelected:any;
  nrcTypes:IDropdownOption[];
  newTypename:string,
  newTypeId:number,
  newCategoryname:string,
  newDiscountflag:boolean,
  newSchedule:boolean,
  disablebutton:boolean,
  onedittypeid:number,
  oneditcategoryname:string,
  oneditschedule:boolean,
  oneditisactive:boolean,
  oneditisdiscount:boolean,
  oneditcategoryId:number
  onedittypename:string,
  allItemsList:INrcCategory[]
}
const dropDownRef = React.createRef<IDropdown>();

class Nrclineitems extends React.Component<IAddNRCLineItemProps, INrcLineItemState> {
  private _selection: Selection;
  private _columns: IColumn[];
  private indexSelected: number = -1;



  constructor(props) {
    super(props);
    this.props.getUserRoles();

    this._selection = new Selection({
      onSelectionChanged: () =>{
       this._getSelectionDetails();
      }
    });
   
    this._columns = [
      {
        key: 'column1',
        name: 'Line Item Type',
        fieldName: 'Type',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
        data: 'string',
      },
      {
        key: 'column2',
        name: 'Category',
        fieldName: 'Category',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column3',
        name: 'Discountable',
        fieldName: 'IsDiscounted',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column4',
        name: 'Scheduling Restricted',
        fieldName: 'IsScheduleRestricted',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
      {
        key: 'column5',
        name: 'Active',
        fieldName: 'IsActive',
        minWidth: 200,
        maxWidth: 300,
        isResizable: true,
      },
     
    ];


    this.state = {
      items: [],
      selectionDetails: this._getSelectionDetails(),
      showPendingPanel: false,
      panelflag: '',
      showEditPanel: false,
      filterOptions: [],
      showsuppliercontacts:false,
      showaddpanel:false,
      showeditpanel:false,
      showfilterpanel:false,
      itemSelected:[],
      nrcTypes:[],
      newTypename:'',
      newTypeId:0,
      newCategoryname:'',
      newDiscountflag:false,
      newSchedule:false,
      disablebutton:true,
      oneditcategoryname:'',
      onedittypeid:0,
      oneditschedule:false,
      oneditisactive:false,
      oneditisdiscount:false,
      oneditcategoryId:0,
      onedittypename:'',
      allItemsList:[]
    };
  }

  
  public componentDidMount() {

    this.getNRCLineItemCategories();
  }

  getNRCLineItemCategories = () => {
      // Checking if app is using mock data.
      if (appConfig.UseMockData === 'false') {
        // Creating headers for telemetry
        const headers: object = {
          'X-CorrelationId': uuidv4(),
          SubCorrelationKey: uuidv4(),
        };
  
        // Defining the url
        let url: string = Routes.GetNRCLineItemCategories;
  
        // Logging telemetry for NRC Categories api call.
        telemetryContext.logEvent(TelemetryEvent.FetchNRCLINEiTEMcategoriesBegin, {
          url,
          headers,
        });
  
        // Making the get call.
        return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
          headers,
        })
          .then((response: any) => {
            // Mapping the response data.
            let nrcLineCategories = response.data.map((x) => ({
              CategoryID: x.categoryId,
              Type: x.type,
              TypeId: x.typeId,
              Category: x.category,
              IsActive: x.isActive,
              IsDiscounted: x.isDiscounted,
              IsScheduleRestricted:x.onlyIMViewable
            }));
            this.setState({
              items:nrcLineCategories,
              allItemsList:nrcLineCategories
            })
  
          })
          .catch((error) => {
            // Log Exception
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchNRCLINEiTEMcategoriesFailure,
              undefined,
              {
                url: url,
                headers,
              }
            );
            // Error handling.
            if (error.response && error.response.status === 404) {
              toast.error('Unable to fetch NRC Line Item Categories.', {
                className: css({
                  background: '#a80000 !important',
                }),
              });
            }
  
            throw error;
          });
      } else {
        //Setting mock data.
        return delay(2000).then(() => {
          // dispatch(setNrcLineItemCategories(NRCCategoryMockData));
          this.setState({
            items:NRCCategoryMockData
          })
        });
      }
    
  };
  loadnrctypes = () =>{
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      // Defining the url
      const url: string = `${Routes.GetNRCLineItemTypes}
      `;
      // Logging telemetry
      telemetryContext.logEvent(
        TelemetryEvent.FetchNRCLineItemTypesBegin,
        {
          headers,
          url,
        }
      );
      // console.log('Calling available fiscal years api start');
       gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
        .then((response: any) => {
        if(response.data && response.status === 200){

          let nrcLineTypes = response.data.map((x) => ({
            key: x.typeId,
            text:x.type,
            isActive:x.isActive
          }));
          // Logging success
          this.setState({
            nrcTypes:nrcLineTypes
          })
          telemetryContext.logEvent(
            TelemetryEvent.FetchNRCLineItemTypesBegin,
            {
              headers,
              url,
            }
          );
          
        }
        })
        .catch((error) => {

          telemetryContext.logException(
            error.toString(),
            TelemetryException.FetchNRCLineItemTypesFailure,
            undefined,
            {
              headers,
              url,
            }
          );

          if (error.response && error.response.status === 404) {
            toast.error(
              'NRC Line Item Types information is not available ',
              {
                className: css({
                  background: '#a80000 !important',
                }),
              }
            );
          }
        });
    }
}

deleteNrcLineItem = (data) => {
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers
      const headers: any = {
        SubCorrelationKey: uuidv4(),
        'X-CorrelationId': uuidv4(),
      };
      let url: string = `${Routes.DeleteNRCLineItem}?categoryId=${data.CategoryID}`;
      // Logging telemetry
      telemetryContext.logEvent(TelemetryEvent.DeleteNRCLineItemBegin, {
        headers,
        url,
      });
      return gtoMsalApiFetch(gtoNewApiInstance, url, {
        // data: {},
        headers,
        method: 'delete',
      })
        .then((response: any) => {
          // Logging success
          this._selection.setAllSelected(false)

          telemetryContext.logEvent(TelemetryEvent.DeleteNRCLineItemBegin, {
            headers,
            url,
          });

          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });
          this.getNRCLineItemCategories();
        })
        .catch((error) => {
          //Logging Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.DeleteNRCLineItemFailure,
            undefined,
            {
              headers,
              url,
            }
          );

        });
    } else {
      return delay(2000).then(() => {
        toast.success('NRC Line Item deleted successfully', {
          className: css({
            background: '#107c10 !important',
          }),
        });
      });
    }

};

addNrcLineItem = (value) => {
  // Checking if app is using mock data.
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    let url: string = `${Routes.AddNRCLineItem}`;
    // Logging telemetry
    telemetryContext.logEvent(TelemetryEvent.AddNRCLineItemBegin, {
      headers,
      url,
    });
    var body= {};
    if(value === 'add'){
      if(this.state.newTypename === '' || this.state.newCategoryname === ''){
        return
      }

      body = {"categoryId":0,
     "type":this.state.newTypename,
     "typeId":this.state.newTypeId,
     "category":this.state.newCategoryname,
     "isDiscounted":this.state.newDiscountflag,
     "isActive":true,
     "onlyIMViewable":this.state.newSchedule}

    }else if(value === 'edit'){
      if(this.state.onedittypename === '' || this.state.oneditcategoryname === ''){
        return
      }

      body = {
      "categoryId":this.state.itemSelected.CategoryID,
     "type":this.state.onedittypename,
     "typeId":this.state.onedittypeid,
     "category":this.state.oneditcategoryname,
     "isDiscounted":this.state.oneditisdiscount,
     "isActive":this.state.oneditisactive,
     "onlyIMViewable":this.state.oneditschedule}
    }

    return gtoMsalApiFetch(gtoNewApiInstance, url, {
      data: body,
      headers,
      method: 'post',
    })
      .then((response: any) => {
        this._selection.setAllSelected(false)

        this.setState({
          showaddpanel:false,
          showEditPanel:false

        })
        // Logging success
        telemetryContext.logEvent(TelemetryEvent.AddNRCLineItemBegin, {
          headers,
          url,
        });

        toast.success(response.data, {
          className: css({
                background: '#107c10 !important',
              }),
            });
        this.getNRCLineItemCategories();
      })
      .catch((error) => {
        //Logging Exception
        telemetryContext.logException(
          error.toString(),
          TelemetryException.AddNRCLineItemFailure,
          undefined,
          {
            headers,
            url,
          }
        );

      });
  } else {
    return delay(2000).then(() => {
      toast.success('NRC Line Item Created successfully', {
        className: css({
          background: '#107c10 !important',
        }),
      });
    });
  }

};


private onchangeType = (o: IDropdownOption) => {
  this.setState({
    newTypename:o.text,
    newTypeId:Number(o.key),
    onedittypeid:Number(o.key),
    onedittypename:o.text
  })
};
private onAddCategory = (e, newValue) => {
  this.setState({
    newCategoryname:newValue,
    oneditcategoryname:newValue
  })
};
 _onChangeDiscount = () =>{
   this.setState({
     newDiscountflag:!this.state.newDiscountflag,
     oneditisdiscount:!this.state.oneditisdiscount
   })
}
_onChangeScheduleRestricted = () => {
 this.setState({
   newSchedule:!this.state.newSchedule,
   oneditschedule:!this.state.oneditschedule
 })
}
_onChangeeditActive = () =>{
  this.setState({
    oneditisactive:!this.state.oneditisactive
  })
}



  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    var selectIndex = this._selection
    
    switch (selectionCount) {
      case 0:
        this.setState({
          disablebutton:true
        })
        return ;
        case 1:
          this.indexSelected = this._selection["_anchoredIndex"]
          this.setState({
            itemSelected:this.state.items[this.indexSelected],
            disablebutton:false
          })
    }
  }


  onclickaddbutton = () =>{
    this.setState({
      showaddpanel:true
    })
    this.loadnrctypes();
  }
  ondismissadd = () =>{
    this.setState({
        showaddpanel:false
    })
  }
  onclickeditbutton = () => {
    this.setState({
      showEditPanel: true,
      oneditcategoryname:this.state.itemSelected.Category,
      onedittypeid:this.state.itemSelected.TypeId,
      oneditschedule:this.state.itemSelected.IsScheduleRestricted,
      oneditisactive:this.state.itemSelected.IsActive,
      oneditisdiscount:this.state.itemSelected.IsDiscounted,
      oneditcategoryId:this.state.itemSelected.CategoryID,
      onedittypename:this.state.itemSelected.Type
    });
    this.loadnrctypes();

  };
  onclickdeletebutton = () => {
  //  alert(JSON.stringify(this.state.itemSelected))
   this.deleteNrcLineItem(this.state.itemSelected)
  };
  ondismissEdit = () => {
    this.setState({
      showEditPanel: false,
    });
  };
  onclickfilterbutton = () =>{
    this.setState({
      showfilterpanel:true
    })
  }
  ondismissfilter = () =>{
    this.setState({
        showfilterpanel:false
    })
  }
  private renderDeleteIcon = (item: any): JSX.Element => {
    return (
      <TooltipHost
        content={'Delete'}
        id={'delete'}
        calloutProps={{ gapSpace: 0 }}
      >
        <IconButton
          style={{ height: '10px', textAlign: 'center' }}
          data-automation-id="test"
          iconProps={{ iconName: 'delete' }}
          
        />
      </TooltipHost>
    );
  };
  private filterwithCategory = (e, newValue) => {
    this._selection.setAllSelected(false)

    if(newValue.length > 0){
      this.setState({
        items:this.state.allItemsList.filter(x=> JSON.stringify(x['Category']).toLowerCase().includes(newValue.toLowerCase())),
        // CategoryID: x.categoryId,
        // Type: x.type,
      })
    }else{
      this.setState({
        items:this.state.allItemsList
      })
    }

  };
  private filterwithType = (e, newValue) => {
    this._selection.setAllSelected(false)
    if(newValue.length > 0){
      this.setState({
        items:this.state.allItemsList.filter(x=> JSON.stringify(x['Type']).toLowerCase().includes(newValue.toLowerCase())),
        // CategoryID: x.categoryId,
        // Type: x.type,
      })
    }else{
      this.setState({
        items:this.state.allItemsList
      })
    }

  };
 
 

  render() {
    return (
      <React.Fragment>
      <div style={{height:'100% !important'}} >
      <HelmetTab title={'NRC Lineitem Category'} />
        <h1
          style={{ padding: '10px', fontSize: '18.72px' }}
          aria-label={'Suppliers'}
        >
            NRC LineItem Categories
        </h1>
         <Stack>
          <div className="ms-Grid" dir="ltr" style={{marginTop:'10px'}}>

          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row" style={{ marginLeft: '10px' }}>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <TextField placeholder="Line Item Type"  aria-label='Line Item Type'  onChange={this.filterwithType} />
              </div>
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <TextField placeholder="Category"  aria-label='Category' onChange={this.filterwithCategory}/>
              </div>
            </div>
          </div>
            <div
              className="ms-Grid-row "
              style={{ marginLeft: '18px', marginTop: '10px' }}
            >
              <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4">
                <PrimaryButton
                  text="Add"
                  iconProps={{ iconName: 'Add' }}
                  style={{ marginRight: '5px' }}
                  onClick={() => this.onclickaddbutton()}
                  disabled={ (this.props.IsAdmin === true||this.props.IsInvoicingManager === true )? false :true}
                />

                <PrimaryButton
                  text="Edit"
                  iconProps={{ iconName: 'EditNote' }}
                  style={{ marginRight: '5px' }}
                  onClick={() => this.onclickeditbutton()}
                  disabled ={(!this.state.disablebutton && (this.props.IsAdmin === true||this.props.IsInvoicingManager === true ))? false :true}
                />
                <PrimaryButton
                  text="Delete"
                  iconProps={{ iconName: 'delete' }}
                  style={{ marginRight: '2px' }}
                  onClick={() => this.onclickdeletebutton()}
                  disabled ={(!this.state.disablebutton && (this.props.IsAdmin === true||this.props.IsInvoicingManager === true ))? false :true}
                />
              </div>
            </div>
          </div>

          <Panel
          isOpen={this.state.showaddpanel}
          onDismiss={this.ondismissadd}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          headerText={'New Line Item'}>

            <div className="ms-Grid" dir="ltr" style={{marginTop:'20px'}}>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Dropdown
                    placeholder="Type"
                    ariaLabel="type"
                    options={this.state.nrcTypes}
                    onChange={(e,o) => this.onchangeType(o)}
                    required={true}
                  />                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <TextField placeholder="Category" 
                  onChange={this.onAddCategory}

                required />

                </div>
            <div
              className="ms-Grid-row "
              style={{ margin: '10px' }}
            >
                <Toggle label="Discountable" inlineLabel  onChange={this._onChangeDiscount} defaultChecked={this.state.newDiscountflag}/>
              </div>
              <div
              className="ms-Grid-row "
              style={{ margin: '10px' }}
            >
                <Toggle label="Active" checked inlineLabel />
              </div>
              <div
              className="ms-Grid-row "
              style={{ margin: '10px' }}
            >
                <Toggle label="Scheduling Restricted" inlineLabel  onChange={this._onChangeScheduleRestricted} defaultChecked={this.state.newSchedule}/>
              </div>
              
                <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissadd()
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Ok"
                  onClick={() => this.addNrcLineItem('add')}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>
            </Panel>

            <Panel
        isOpen={this.state.showEditPanel}
        onDismiss={this.ondismissEdit}
          type={PanelType.medium}
          closeButtonAriaLabel="Close"
          headerText={'New Line Item - Edit'}>

            <div className="ms-Grid" dir="ltr" style={{marginTop:'20px'}}>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <Dropdown
                    placeholder="Type"
                    ariaLabel="type"
                    options={this.state.nrcTypes}
                    onChange={(e,o) => this.onchangeType(o)}
                    required={true}
                    selectedKey={this.state.onedittypeid}
                  />                </div>
                <div className="ms-Grid-row" style={{ margin: '10px' }}>
                <TextField placeholder="Category" 
                  onChange={this.onAddCategory}
                    value={this.state.oneditcategoryname}
                required />

                </div>
            <div
              className="ms-Grid-row "
              style={{ margin: '10px' }}
            >
                <Toggle label="Discountable" inlineLabel  onChange={this._onChangeDiscount} defaultChecked={this.state.oneditisdiscount}/>
              </div>
              <div
              className="ms-Grid-row "
              style={{ margin: '10px' }}
            >
                <Toggle label="Active"  inlineLabel  defaultChecked={this.state.oneditisactive} onChange={this._onChangeeditActive}/>
              </div>
              <div
              className="ms-Grid-row "
              style={{ margin: '10px' }}
            >
                <Toggle label="Scheduling Restricted" inlineLabel  onChange={this._onChangeScheduleRestricted} defaultChecked={this.state.oneditschedule}/>
              </div>
              
                <div className=" ms-Grid-row addNrcButtonGroup" style={{ margin: '10px' }}>
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissEdit()
                  }}
                  style={{ margin: '10px' }}
                />
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Ok"
                  onClick={() => this.addNrcLineItem('edit')}
                  style={{ float: 'right', margin: '10px 0px' }}
                />
              </div>
            </div>
            </Panel>

          <div>
           {this.state.items!== undefined && this.state.items.length > 0 &&
                  //  <MarqueeSelection selection={this._selection}>
                  <div style={{
                  height: '70vh',
                  position: 'relative',
                  marginLeft: '1vw'}}
                  >
                <ScrollablePane>        
                  <DetailsList 
                compact={true}
                items={this.state.items}
                columns={this._columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                selectionMode={
                SelectionMode.single
                }
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
              />
              </ScrollablePane>
              
              </div>
            }
          </div>
          </Stack>
      </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserRoles: () => {
      dispatch(getUserRoles());
    },
    getNRCLineItemCategories: () => {
      dispatch(getNRCLineItemCategories());
    },
  };
};

const mapStateToProps = (state) => {
  return {

    nrcLineCategories: state.nrclineitem.nrcLineCategories,
    IsInvoicingManager: state.user.IsInvoicingManager,
    IsAdmin: state.user.IsAdmin,


  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Nrclineitems);
