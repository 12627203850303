import React from 'react';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import { IconButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import '../headcount/HeadCount.css';
import { IInvoiceBillableUnitsExport } from '../../shared/models/InvoiceBillableExport';
import { connect } from 'react-redux';
import { exportToXlsx } from '../../shared/common/common-util';

const columnstyle = {
  fill: { patternType: "solid", bgColor: { rgb: "FFFF0000" } }
};

export interface IExportExcelProps {
  billableUnitsData: IInvoiceBillableUnitDetails[];
  fileName: string;
  isEnable: number;
  showInvoiceViewLoader: boolean;
  showAccrualInitialLoader: boolean;
  isBillableUnits: boolean;
  invoicePeriod: string;
  isDisplayButtonClicked: boolean;
  isAllInvoiceLoader: boolean;
}

export interface IExportExcelState {
  headers: IDataHeaders[];
}

export interface IDataHeaders {
  header: string;
  key: string;
  IsHeadCount: boolean;
}

class ExportExcel extends React.Component<IExportExcelProps, IExportExcelState> {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          header: 'Fiscal Year Number',
          key: 'FiscalYearNumber',
          IsHeadCount: true,
        },
        { header: 'Engagement Id', key: 'EngagementId', IsHeadCount: true },
        {
          header: 'Supplier Engagement Id',
          key: 'SupplierEngagementId',
          IsHeadCount: true,
        },
        {
          header: 'Invoice Prefix',
          key: 'InvoicePrefix',
          IsHeadCount: false,
        },
        { header: 'SD1', key: 'SD1', IsHeadCount: true },
        { header: 'SD2', key: 'SD2', IsHeadCount: true },
        { header: 'Supplier Name', key: 'SupplierName', IsHeadCount: true },
        {
          header: 'Supplier Number',
          key: 'SupplierNumber',
          IsHeadCount: false,
        },
        { header: 'Supplier City', key: 'SupplierCity', IsHeadCount: true },
        {
          header: 'Supplier Country',
          key: 'SupplierCountry',
          IsHeadCount: true,
        },
        {
          header: 'Purchase Order',
          key: 'PurchaseOrder',
          IsHeadCount: false,
        },
        {
          header: 'Company Code',
          key: 'CompanyCode',
          IsHeadCount: false,
        },
        {
          header: 'Cost Center',
          key: 'CostCenter',
          IsHeadCount: false,
        },
        {
          header: 'Internal Order',
          key: 'InternalOrder',
          IsHeadCount: false,
        },
        {
          header: 'Account Code',
          key: 'AccountCode',
          IsHeadCount: false,
        },
        {
          header: 'Function Summary',
          key: 'FunctionSummary',
          IsHeadCount: true,
        },
        { header: 'Function Detail', key: 'FunctionDetail', IsHeadCount: true },
        {
          header: 'Business Segment',
          key: 'BusinessSegment',
          IsHeadCount: true,
        },
        { header: 'Exec PL', key: 'ExecPL', IsHeadCount: true },
        { header: 'Product', key: 'Product', IsHeadCount: true },
        { header: 'Product Group', key: 'ProductGroup', IsHeadCount: true },
        { header: 'Product SubType', key: 'ProductSubType', IsHeadCount: true },
        { header: 'Offering', key: 'Offering', IsHeadCount: true },
        { header: 'LOB', key: 'LOB', IsHeadCount: true },
        { header: 'Media Type', key: 'MediaType', IsHeadCount: true },
        { header: 'Media SubType', key: 'MediaSubType', IsHeadCount: true },
        { header: 'Support Tier', key: 'SupportTier', IsHeadCount: true },
        { header: 'Language', key: 'Language', IsHeadCount: true },
        { header: 'Invoice Period', key: 'InvoicePeriod', IsHeadCount: true },
        {
          header: 'Post Invoice Period',
          key: 'PostInvoicePeriod',
          IsHeadCount: true,
        },
        { header: 'Billable Units', key: 'BillableUnits', IsHeadCount: true },
        { header: 'Pricing Model', key: 'PricingModel', IsHeadCount: true },
        { header: 'Price', key: 'Price', IsHeadCount: true },
        { header: 'Total Amount', key: 'TotalAmount', IsHeadCount: true },
        { header: 'Local Currency', key: 'CurrencyCode', IsHeadCount: true },
        {
          header: 'Discount Percent',
          key: 'DiscountPercent',
          IsHeadCount: true,
        },
        { header: 'Discount Amount', key: 'DiscountAmount', IsHeadCount: true },
        { header: 'Gross Amount', key: 'GrossAmount', IsHeadCount: true },
        { header: 'Tax Percent', key: 'TaxPercent', IsHeadCount: true },
        { header: 'Tax Amount', key: 'TaxAmount', IsHeadCount: true },
        { header: 'Net Amount', key: 'NetAmount', IsHeadCount: true },
        { header: 'Total Amount ($)', key: 'TotalAmountCD', IsHeadCount: true },
        {
          header: 'Discount Amount ($)',
          key: 'DiscountAmountCD',
          IsHeadCount: true,
        },
        { header: 'Gross Amount ($)', key: 'GrossAmountCD', IsHeadCount: true },
        { header: 'Tax Amount ($)', key: 'TaxAmountCD', IsHeadCount: true },
        { header: 'Net Amount ($)', key: 'NetAmountCD', IsHeadCount: true },
        { header: 'Pricing Type', key: 'PricingType', IsHeadCount: true },
        { header: 'Pricing Level', key: 'PricingLevel', IsHeadCount: true },
        { header: 'Volume Min', key: 'VolumeMin', IsHeadCount: true },
        { header: 'Volume Max', key: 'VolumeMax', IsHeadCount: true },
        { header: 'NRC Type', key: 'NRCType', IsHeadCount: true },
        { header: 'NRC Category', key: 'NRCCategory', IsHeadCount: true },
        { header: 'NRC Description', key: 'NRCDescription', IsHeadCount: true },
        {
          header: 'IsPriorPeriodAdjustment',
          key: 'IsPriorPeriodAdjustment',
          IsHeadCount: true,
        },
        { header: 'IsNRC', key: 'IsNRC', IsHeadCount: true },
        { header: 'IsVolume', key: 'IsVolume', IsHeadCount: true },
       
        { header: 'NRCId', key: 'NRCId', IsHeadCount: true },
        { header: 'Sales Region', key: 'SalesRegion', IsHeadCount: true },
        { header: 'PCY', key: 'PCY', IsHeadCount: true },
        { header: 'Sub-PCY', key: 'SubPCY', IsHeadCount: true },
        { header: 'IsCommerce', key: 'IsCommerce', IsHeadCount: true },
        { header: 'BusinessGroup', key: 'BusinessGroup', IsHeadCount: true }
      ],
    };
  }

  //Function to headers for Excel file for Billable units and Invoice view (all invoice data and supplier details)
  private getHeaders = () => {
    if (this.props.isBillableUnits)
      return this.state.headers.filter((x) => x.IsHeadCount);
    else {
      if (this.props.isDisplayButtonClicked) return this.state.headers;
      else {
        let headers = [...this.state.headers];
        return headers;
      }
    }
  };


  private handleExcelExport = () => {
    const billableUnitsData: any = this.props.billableUnitsData;
    let exportData: any = [];
    if (billableUnitsData && billableUnitsData.length > 0) {
      for (let loopIndex = 0; loopIndex < billableUnitsData.length; loopIndex++) {
        exportData.push({
          EngagementId: billableUnitsData[loopIndex].EngagementId,
          FiscalYearNumber: billableUnitsData[loopIndex].FiscalYearNumber,
          SupplierEngagementId: billableUnitsData[loopIndex].SupplierEngagementId,
          FunctionSummary: billableUnitsData[loopIndex].FunctionSummary,
          FunctionDetail: billableUnitsData[loopIndex].FunctionDetail,
          SupplierName: billableUnitsData[loopIndex].SupplierName,
          SupplierCity: billableUnitsData[loopIndex].SupplierCity,
          SupplierCountry: billableUnitsData[loopIndex].SupplierCountry,
          BusinessSegment: billableUnitsData[loopIndex].BusinessSegment,
          ExecPL: billableUnitsData[loopIndex].ExecPL,
          Product: billableUnitsData[loopIndex].Product,
          ProductGroup: billableUnitsData[loopIndex].ProductGroup,
          ProductSubType: billableUnitsData[loopIndex].ProductSubType,
          Offering: billableUnitsData[loopIndex].Offering,
          LOB: billableUnitsData[loopIndex].LOB,
          MediaType: billableUnitsData[loopIndex].MediaType,
          MediaSubType: billableUnitsData[loopIndex].MediaSubType,
          SupportTier: billableUnitsData[loopIndex].SupportTier,
          Language: billableUnitsData[loopIndex].Language,
          TaxPercent: billableUnitsData[loopIndex].TaxPercent,
          BillableUnits: billableUnitsData[loopIndex].BillableUnits,
          PostInvoicePeriod: billableUnitsData[loopIndex].PostInvoicePeriod,
          InvoicePeriod: this.props.invoicePeriod,
          CurrencyCode: billableUnitsData[loopIndex].CurrencyCode,
          DiscountPercent: billableUnitsData[loopIndex].DiscountPercent,
          TotalAmount: billableUnitsData[loopIndex].TotalAmount,
          GrossAmount: billableUnitsData[loopIndex].GrossAmount,
          DiscountAmount: billableUnitsData[loopIndex].DiscountAmount,
          TaxAmount: billableUnitsData[loopIndex].TaxAmount,
          NetAmount: billableUnitsData[loopIndex].NetAmount,
          PricingType:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].PricingType,
          PricingModel:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].PricingModel,
          PricingLevel:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].PricingLevel,
          Price:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].Price,
          VolumeMin:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].VolumeMin,
          VolumeMax:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].VolumeMax,
          PricingModelId:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].PricingModelId,
          PriceLineBillableUnits:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].PriceLineBillableUnits,
          PriceLineAmount:
            billableUnitsData[loopIndex].PriceLine === null
              ? null
              : billableUnitsData[loopIndex].PriceLine[
                billableUnitsData[loopIndex].PriceLine.length - 1
              ].PriceLineAmount,
          NRCType: billableUnitsData[loopIndex].NRCType,
          NRCCategory: billableUnitsData[loopIndex].NRCCategory,
          NRCDescription: billableUnitsData[loopIndex].NRCDescription,
          IsPriorPeriodAdjustment: billableUnitsData[loopIndex].IsPriorPeriodAdjustment
            ? 'true'
            : 'false',
          IsNRC: billableUnitsData[loopIndex].IsNRC ? 'true' : 'false',
          IsVolume: billableUnitsData[loopIndex].IsVolume ? 'true' : 'false',
          SupplierNumber: billableUnitsData[loopIndex].SupplierNumber,
          CompanyCode: billableUnitsData[loopIndex].CompanyCode,
          TotalAmountCD: billableUnitsData[loopIndex].TotalAmountCD,
          GrossAmountCD: billableUnitsData[loopIndex].GrossAmountCD,
          DiscountAmountCD: billableUnitsData[loopIndex].DiscountAmountCD,
          TaxAmountCD: billableUnitsData[loopIndex].TaxAmountCD,
          NetAmountCD: billableUnitsData[loopIndex].NetAmountCD,
          InternalOrder: billableUnitsData[loopIndex].InternalOrder,
          PurchaseOrder: billableUnitsData[loopIndex].PurchaseOrder,
          CostCenter: billableUnitsData[loopIndex].CostCenter,
          AccountCode: billableUnitsData[loopIndex].AccountCode,
          InvoicePrefix: billableUnitsData[loopIndex].InvoicePrefix,
          SD1: billableUnitsData[loopIndex].SD1,
          SD2: billableUnitsData[loopIndex].SD2,
          NRCId: billableUnitsData[loopIndex].NRCId,
          SalesRegion: billableUnitsData[loopIndex].SalesRegion,
          PCY: billableUnitsData[loopIndex].PCY,
          SubPCY: billableUnitsData[loopIndex].SubPCY,
          IsCommerce: billableUnitsData[loopIndex].IsCommerce,
          BusinessGroup: billableUnitsData[loopIndex].BusinessGroup
        });
      }

      exportToXlsx(exportData, this.state.headers, this.props.fileName, "HeadcountBillableUnits");
    }
  };

  public render() {
    return (this.props.showAccrualInitialLoader &&
      this.props.showInvoiceViewLoader) ||
      this.props.isAllInvoiceLoader ? (
      <Spinner className="excelIconBillableUnits" size={SpinnerSize.large} />
    ) : (

      <IconButton
        disabled={this.props.isEnable === 0}
        className="excelIconBillableUnits"
        iconProps={{ iconName: 'ExcelDocument' }}
        title="Export to Excel"
        ariaLabel="Export to Excel"
        onClick={this.handleExcelExport}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showInvoiceViewLoader: state.invoiceView.isFetchingAccrualOrActual,
    showAccrualInitialLoader: state.invoiceView.accrualInitialLoader,
    isDisplayButtonClicked: state.invoiceView.isDisplayButtonClicked,
    isAllInvoiceLoader: state.invoiceView.isAllInvoiceLoader,
  };
};

export default connect(mapStateToProps, null)(ExportExcel);
