import React from 'react';
import {
  Stack,
  Panel,
  PanelType,
  // Checkbox,
  // PrimaryButton,
  // ActionButton,
  ChoiceGroup,
  Fabric,
  Label,
  IChoiceGroupOption,
  PivotItem,
  Icon,
  FontIcon,
  Pivot,
  TooltipHost,
  IconButton,
  IButton,
} from 'office-ui-fabric-react';
import { PrimaryButton,ActionButton,Checkbox  } from '@fluentui/react';
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  Selection,
  SelectionMode,
  CheckboxVisibility
} from '@fluentui/react/lib/DetailsList';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { CardStyle } from '../../shared/styles/Card';
import VolumeList from '../headcount/volume-nrc/detail-list/VolumeDetailList';
import { connect } from 'react-redux';
import { IHeadCountMonthList,TelemetryEvent,Routes,TelemetryException} from '../../shared/models';
import { IInvoiceBillableUnitDetails } from '../../shared/models/InvoiceBillableUnitDetails';
import {
  getMonthListData,
  dataCountBillableUnits,
} from '../../store/actions/headCount.action';
import './invoiceview.css';
import {
  getSupplierandInvoicePrefix,
  getInvoiceInfoMessage,
  getSupplierInvoiceState,
  initialAmountCalculation,
  setSupplierInvoiceState,
  getSupplierVariance,
  getBillableUnitsForInvoice,
  setBillableUnitsForInvoice,
  setIsDisplayButtonClicked,
  showErrorMessageComplete,
  getAllInvoice,
  getDataForActualAccrualCall,
  setIsActionChanged,
  setAllInvoiceAccrualsData,
  setAllInvoiceActualsData,
  getEventDateTime,
} from '../../store/actions/invoiceView.action';
import { ISupplierInvoice } from '../../shared/models/SupplierAndInvoicePrefix.model';
import { getUserRoles } from '../../store/actions/user.action';
import { IAdditionalAttribute } from '../headcount/HeadCount';
import ExportToExcel from '../common-components/ExportToExcel';
import { isColumnSortedDetails } from '../../store/actions/headCount.action';
import { IDataCount } from '../../shared/models/DataCount';
import AdditionalAttributes from '../common-components/additionalAttributes';
import FilterDropdown from '../common-components/FilterComponents/FilterDropdown';
import { MonthListInitialData } from '../headcount/HeadCount';
import { ISupplierInvoieVariance } from '../../shared/models/SupplierInvoiceVariance';
import { getFiscalYearsForSupplier } from '../../store/actions/selectedSupplier.action';
import { InvoicingEvent } from '../../shared/models/InvoicingEvent.enum';
import { columnsForInvoiceViewInitialGrid, columnsForInvoiceViewSupplierDetailGrid } from '../common-components/ColumnsForGrid';
import HelmetTab from "../../shared/Helmet/Helmet"
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import appConfig from '../../assets/configuration/config';
import { css } from 'glamor';
import { Announced } from '@fluentui/react/lib/Announced';



export interface IInvoiceViewProps {
  units: IInvoiceBillableUnitDetails[];
  postBillableUnits: any;
  getMonthList: any;
  monthList: IHeadCountMonthList[];
  getSupplierInvoiceList: any;
  supplierInvoiceList: ISupplierInvoice[];
  additionalAttributesToDisplay: string[];
  getInvoiceInfo: any;
  getSupplierInvoiceState: any;
  invoiceState: string;
  getUserRoles: any;
  amountDetails: ITotalCalculation;
  initialAmountCalculation: any;
  isColumnSortedDetails: any;
  showInvoiceViewLoader: boolean;
  showAccrualInitialLoader: boolean;
  dataCount: IDataCount;
  billableUnitsAllSuppliers: IInvoiceBillableUnitDetails[];
  setSupplierInvoiceState: any;
  dataCountBillableUnits: any;
  getSupplierVariance: any;
  supplierVariance: ISupplierInvoieVariance[];
  getBillableUnitsForInvoice: any;
  setBillableUnitsForInvoice: any;
  invoiceAccrualActualDetails: IInvoiceBillableUnitDetails[];
  isFetchingInvoiceVariance: boolean;
  setIsDisplayButtonClicked: any;
  isDisplayButtonClicked: boolean;
  showErrorMessageComplete: any;
  dataForActualAccrualCall: ISupplierInvoieVariance;
  getAllInvoice: any;
  allInvoiceAccrualsData: IInvoiceBillableUnitDetails[];
  allInvoiceActualsData: IInvoiceBillableUnitDetails[];
  getDataForActualAccrualCall: any;
  setIsActionChanged: any;
  setAllInvoiceAccrualsData: any;
  setAllInvoiceActualsData: any;
  getFiscalYearsForSupplier: any;
  fiscalYearNumbers: number[];
  getEventDateTime: any;
  accrualsPostOn: string,
  actualsPostOn: string,
}

export interface IInvoiceViewState {
  showPanel: boolean;
  additionalAttributes: IAdditionalAttribute[];
  stateDisplayType: string;
  simpledropdownBindExample: IDropdownOption[];
  initialAmounts: ITotalCalculation;
  items: IInvoiceBillableUnitDetails[];
  allSupplierSelected: boolean;
  filteredItems: IInvoiceBillableUnitDetails[];
  isFilter: boolean;
  itemCountFilter: IDataCount;
  isActualAccrual: boolean;
  monthChanged: boolean;
  isBackButtonClicked: boolean;
  invoiceCumMonth: number;
  columnsForVolumeList: IColumn[];
  announcedMessage:string;

}

export interface ITotalCalculation {
  totalAmount: number;
  grossAmount: number;
  discountAmount: number;
  taxAmount: number;
  netAmount: number;
}

const backButtonRef = React.createRef<IButton>();
const changetableAttributesInvoice = React.createRef<IButton>();


class InvoiceView extends React.Component<
  IInvoiceViewProps,
  IInvoiceViewState
> {
  private columns: IColumn[];
  private _selection: Selection;
  private indexSelected: number = -1;
  private prevSelectedIndex: number = -1;
  private selectedCumMonth: IHeadCountMonthList = MonthListInitialData;
  private currentMonth: number;
  private selectedFiscalYear: number;

  constructor(props) {
    super(props);
    this.state = {
      simpledropdownBindExample: [],
      showPanel: false,
      stateDisplayType: 'All',
      items: [],
      isActualAccrual: true,
      monthChanged: false,
      isBackButtonClicked: false,
      allSupplierSelected: false,
      initialAmounts: {
        totalAmount: 0,
        grossAmount: 0,
        discountAmount: 0,
        taxAmount: 0,
        netAmount: 0,
      },
      additionalAttributes: AdditionalAttributes,
      filteredItems: this.props.units,
      isFilter: false,
      itemCountFilter: {
        All: 0,
        Volume: 0,
        NRC: 0,
        PPA: 0,
      },
      invoiceCumMonth: 0,
      columnsForVolumeList: columnsForInvoiceViewInitialGrid,
      announcedMessage:undefined
    };
    this.columns = [
      {
        key: 'column1',
        name: '',
        fieldName: 'month',
        minWidth: 50,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onRender: this.renderMonthListColumn,
        data: 'string',
        isPadded: true,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        this._getSelectionDetails();
      },
    });
  }

  public componentDidMount() {
    document
      .getElementById('fiscalYearDropdown')
      .removeAttribute('aria-describedby');
    document
      .getElementsByClassName('ms-Dropdown-title')[0]
      .removeAttribute('aria-label');
      this.props.getFiscalYearsForSupplier();
      this.props.getSupplierInvoiceList();
      this.props.getUserRoles();
      this.loadFiscalYears();
  }

  loadFiscalYears = () =>{
        if (appConfig.UseMockData === 'false') {
          // Creating headers
          const headers: any = {
            SubCorrelationKey: uuidv4(),
            'X-CorrelationId': uuidv4(),
          };
          // Defining the url
          const url: string = `${Routes.GetAvailableFiscalYears}
          `;
          // Logging telemetry
          telemetryContext.logEvent(
            TelemetryEvent.FetchAvailableFiscalYearsForSupplierBegin,
            {
              headers,
              url,
            }
          );
          // console.log('Calling available fiscal years api start');
           gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
            .then((response: any) => {
            if(response.data && response.status === 200){
              // Logging success
              telemetryContext.logEvent(
                TelemetryEvent.FetchCurrentFiscalYearForInvoiceSuccess,
                {
                  headers,
                  url,
                }
              );
              this.selectedFiscalYear = response.data.currentFiscalYearNumber
              this.props.getMonthList(response.data.currentFiscalYearNumber);
            }
            })
            .catch((error) => {
              // console.log('Calling avaialable fiscal years failed');
              telemetryContext.logException(
                error.toString(),
                TelemetryException.FetchCurrentFiscalYearForInvoiceFailure,
                undefined,
                {
                  headers,
                  url,
                }
              );
              if (error.response && error.response.status === 404) {
                toast.error(
                  'Fiscal Year information is not available ',
                  {
                    className: css({
                      background: '#a80000 !important',
                    }),
                  }
                );
              }
            });
        }
  }

  public componentDidUpdate() {
    if (this.indexSelected === -1) {
      const { monthList } = this.props;
      for (let i = 0; i < monthList.length; i++) {
        if (
          (monthList[i] as IHeadCountMonthList).IsCurrentInvoiceMonth === true
        ) {
          this.currentMonth = +monthList[i].CumMonth;
          this.indexSelected = i;
        }
      }
    }
    if(this.props.isDisplayButtonClicked){
      backButtonRef.current.focus();
      changetableAttributesInvoice.current.focus();

    }
    this._selection.setIndexSelected(this.indexSelected, true, true);
  }
  public render() {
    let taxPercentDisplay = 0;
    if (this.props.invoiceAccrualActualDetails.length > 0)
      taxPercentDisplay = this.props.invoiceAccrualActualDetails[0].TaxPercent;
    let sortedAdditionalAttributes: IAdditionalAttribute[];
    sortedAdditionalAttributes = this.state.additionalAttributes.sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );

    return (
      <>
      <HelmetTab title={'Invoice'} />
        <Panel
          isOpen={this.state.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          headerText="Additional Attributes:"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={this._onRenderFooterContent}
        >
          {sortedAdditionalAttributes.map((attr) => {
            return (
              <div key={attr.key} style={{ marginBottom: '1vh' }}>
                <Checkbox
                  label={attr.displayName}
                  checked={attr.selected}
                  onChange={(ev, isChecked) =>
                    this._onCheckboxChange(attr.name, ev, isChecked)
                  }
                />
              </div>
            );
          })}
        </Panel>
        {this.state.announcedMessage ? <Announced message={this.state.announcedMessage} /> : undefined}

        <div className="flex-container-invoice-view">
        <div className="flex-container-invoice-view-1">
            <h1
              style={{
                marginBottom: '0px',
                marginTop: '0px',
                paddingBottom: '3px',
                paddingTop: '3px',
                fontSize: '18.72px',
              }}
              aria-label={'Invoice'}
            >
            Invoice
            </h1>
            <div className="flex-container-invoice-view-1-item">
            <FilterDropdown
                columns={this.state.columnsForVolumeList}
                item={
                  this.state.isFilter
                    ? this.state.filteredItems
                    : this.props.invoiceAccrualActualDetails
                }
                onFilterChange={this.displayFilterData}
                allItems={this.props.invoiceAccrualActualDetails}
                columnSorted={this.props.isColumnSortedDetails}
                disable={
                  this.props.invoiceAccrualActualDetails.length === 0 ||
                  this.props.showInvoiceViewLoader
                }
                isEditable={false}
              />
              <Dropdown
                aria-label="Fiscal Year Dropdown"
                placeholder={this.selectedFiscalYear !== undefined ?`Fiscal Year ${this.selectedFiscalYear}`: "Select an option"}
                id="fiscalYearDropdown"
                title="FiscalYear"
                onChange={(e, o) => this.onFiscalYearDropdownChange(o)}
                options={this.buildFiscalYearDropdown()}
              />
              <ExportToExcel
                billableUnitsData={
                  this.props.isDisplayButtonClicked
                    ? this.props.invoiceAccrualActualDetails
                    : this.state.isActualAccrual
                    ? this.props.allInvoiceActualsData
                    : this.props.allInvoiceAccrualsData
                }
                fileName={
                  this.props.isDisplayButtonClicked
                    ? `BillableUnitsFor${
                        this.state.isActualAccrual ? 'Actual' : 'Accrual'
                      }_${this.props.dataForActualAccrualCall.InvoicePrefix}_${
                        this.state.allSupplierSelected
                          ? 'All'
                          : this.props.dataForActualAccrualCall.CompanyCode
                      }.csv`
                    : this.state.isActualAccrual
                    ? 'AllInvoiceActuals.csv'
                    : 'AllInvoiceAccruals.csv'
                }
                isEnable={
                  this.props.isDisplayButtonClicked
                    ? this.props.invoiceAccrualActualDetails.length
                    : this.state.isActualAccrual
                    ? this.props.allInvoiceActualsData.length
                    : this.props.allInvoiceAccrualsData.length
                }
                isBillableUnits={false}
                invoicePeriod={this.selectedCumMonth.PeriodDetail}
              />
            </div>
          </div>
          <div
            className="flex-container-invoice-view-2"
            style={{ marginLeft: '0px' }}
          >
            <div
              style={{
                ...CardStyle,
                marginRight: '1%',
                width: '12%',
                height: '70%',
              }}
            >
              <Fabric
                style={{
                  width: '100%',
                  height: '67.5vh',
                  overflow: 'auto',
                  display: 'block',
                  margin: '0 auto',
                }}
                tabIndex={0}
              >
                <DetailsList
                  items={this.getMonthList()}
                  selectionPreservedOnEmptyClick={true}
                  checkButtonAriaLabel="select a Single month"
                  ariaLabelForGrid="Month List"
                  compact={false}
                  columns={this.columns}
                  selection={this._selection}
                  checkboxVisibility={CheckboxVisibility.hidden}
                  selectionMode={
                    this.props.isDisplayButtonClicked ?  SelectionMode.none:
                    (this.props.showAccrualInitialLoader &&
                      this.props.showInvoiceViewLoader) ||
                    this.props.isFetchingInvoiceVariance
                      ? SelectionMode.none
                      : SelectionMode.single
                  }
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={false}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection of Suppliers"
                  styles={{
                    root: {
                      backgroundColor: 'white',
                    },
                  }}
                />
              </Fabric>
            </div>
            <div
              className="flex-container-invoice-view-2-item"
              style={{ width: '100%' }}
            >
              <div className="flex-container-invoice-view-2-item-1">
                <div style={{ paddingTop: '5.5px', width: '25%' }}>
                  {this.props.isDisplayButtonClicked && (
                    <div className="item-1">
                      <div style={{ width: '10%' }}>
                        <TooltipHost content="Go to Summary">
                          <IconButton
                            iconProps={{ iconName: 'ChromeBack' }}
                            onClick={this.onBackButtonClick}
                            style={{ border: '1px solid #0078d4' }}
                            disabled={this.props.showInvoiceViewLoader}
                            aria-label="Go to Summary"
                            componentRef={backButtonRef}
                          />
                        </TooltipHost>
                      </div>
                      <div style={{ width: '85%' }}>
                        <Label>
                          {`${
                            this.props.dataForActualAccrualCall.SupplierName
                          } ( ${
                            this.props.dataForActualAccrualCall.SupplierNumber
                          } ) - ${
                            this.props.dataForActualAccrualCall.CompanyCode
                          } - ${
                            this.props.dataForActualAccrualCall.InvoicePrefix
                          } ( ${
                            this.state.isActualAccrual ? `Actual` : `Accrual`
                          } )`}
                        </Label>
                      </div>
                    </div>
                  )}
                </div>
                {
                  this.props.isDisplayButtonClicked ?
                    <div className="item-2" style={{ paddingTop: '7.5px' }}>
                      <Label style = {{fontSize:'12px',marginRight:'7px'}}>{this.props.invoiceState}</Label>                  
                    </div>
                    :
                    <div className="item-2" style={{ paddingTop: '7.5px' }}>
                      <Label style = {{fontSize:'12px',marginRight:'7px'}}>{`Default date for accruals posting is ${this.props.accrualsPostOn} PST and for actuals posting is ${this.props.actualsPostOn} PST`}</Label>                  
                    </div>
                }                
                <div className="item-3">
                  <ChoiceGroup
                    className="invoice-view"
                    styles={{
                      flexContainer: {
                        display: 'flex',
                        float: 'right',
                        selectors: {
                          '.root-99': {
                            marginLeft: '5px',
                          },
                          '.ms-ChoiceFieldLabel': {
                            fontWeight: 'bold',
                          },
                          '.ms-ChoiceField-wrapper': {
                            marginRight: '1vw',
                          },
                        },
                      },
                    }}
                    defaultSelectedKey="A"
                    disabled={
                      this.props.showAccrualInitialLoader &&
                      this.props.showInvoiceViewLoader
                    }
                    options={[
                      {
                        key: 'A',
                        text: 'Actual',
                      },
                      {
                        key: 'B',
                        text: 'Accrual',
                      },
                    ]}
                    onChange={(ev, o) => this._accrualActualSelectionChanged(o)}
                  />
                  <ActionButton
                    className="invoice-view"
                    onClick={this._showPanel}
                    iconProps={{ iconName: 'Edit' }}
                    role="button"
                    disabled={
                      this.props.invoiceAccrualActualDetails.length === 0 ||
                      this.props.showInvoiceViewLoader
                    }
                    componentRef={changetableAttributesInvoice}
                  >
                    Change Table Attributes
                  </ActionButton>
                </div>
              </div>
              <div className="flex-container-invoice-view-2-item-2">
                {((this.props.showInvoiceViewLoader &&
                  this.props.showAccrualInitialLoader) ||
                  this.props.invoiceAccrualActualDetails.length > 0) && (
                  <Pivot onLinkClick={this.handleState}>
                    <PivotItem
                      headerText="All"
                      itemCount={
                        this.props.showInvoiceViewLoader &&
                        this.props.showAccrualInitialLoader
                          ? 0
                          : this.props.dataCount.All > 0
                          ? this.state.isFilter
                            ? this.state.itemCountFilter.All
                            : this.props.dataCount.All
                          : 0
                      }
                    />
                    <PivotItem
                      headerText="Volume"
                      itemCount={
                        this.props.showInvoiceViewLoader &&
                        this.props.showAccrualInitialLoader
                          ? 0
                          : this.props.dataCount.Volume > 0
                          ? this.state.isFilter
                            ? this.state.itemCountFilter.Volume
                            : this.props.dataCount.Volume
                          : 0
                      }
                    />
                    <PivotItem
                      headerText="NRC"
                      itemCount={
                        this.props.showInvoiceViewLoader &&
                        this.props.showAccrualInitialLoader
                          ? 0
                          : this.props.dataCount.NRC > 0
                          ? this.state.isFilter
                            ? this.state.itemCountFilter.NRC
                            : this.props.dataCount.NRC
                          : 0
                      }
                    />
                    <PivotItem
                      headerText="PPA"
                      itemCount={
                        this.props.showInvoiceViewLoader &&
                        this.props.showAccrualInitialLoader
                          ? 0
                          : this.props.dataCount.PPA > 0
                          ? this.state.isFilter
                            ? this.state.itemCountFilter.PPA
                            : this.props.dataCount.PPA
                          : 0
                      }
                    />
                  </Pivot>
                )}
              </div>
              <div
                className="flex-container-invoice-view-2-item-3"
                style={{ marginBottom: '5% !important' }}
              >
                <VolumeList
                  columns={this.state.columnsForVolumeList}
                  billableUnit={
                    this.props.isDisplayButtonClicked
                      ? this.state.isFilter
                        ? this.state.filteredItems
                        : this.props.invoiceAccrualActualDetails
                      : this.props.supplierVariance
                  }
                  additionalAttributesToDisplay={this.state.additionalAttributes.filter(
                    (x) => x.selected === true
                  )}
                  updatedItems={null}
                  stateSelected={this.state.stateDisplayType}
                  isEditable={false}
                  selectedMonth={0}
                  isFilter={false}
                  getData={null}
                  isActualOrAccrual={this.state.isActualAccrual}
                  onButtonClick={false}
                  onShowDetailsColumns={() => this.setState({ columnsForVolumeList: columnsForInvoiceViewSupplierDetailGrid })}
                  isAutomatedSEIDsFiltered = {true}
                />
              </div>
            </div>
          </div>
          {((this.props.showInvoiceViewLoader &&
            this.props.showAccrualInitialLoader) ||
            this.props.invoiceAccrualActualDetails.length > 0) && (
            <div className="flex-container-invoice-view-3">
              <div className="flex-container-invoice-view-3-item-1">
                <div style={{ marginLeft: '5px' }}>
                  <sup>
                    <Icon
                      style={{
                        fontSize: '7px',
                        color: 'black',
                        marginLeft: '1px',
                        fontWeight: 'bold',
                      }}
                      iconName="Asterisk"
                    />
                  </sup>
                  <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                    {'Prior Period Adjustment'}
                  </span>
                </div>
                <div style={{ marginLeft: '5px' }}>
                  <sup>
                    <Icon
                      style={{
                        fontSize: '7px',
                        color: 'black',
                        marginLeft: '1px',
                        fontWeight: 'bold',
                      }}
                      iconName="Add"
                    />
                  </sup>
                  <span style={{ fontSize: '12px', paddingLeft: '2px' }}>
                    {'NRC'}
                  </span>
                </div>
              </div>
              <div
                className="amount-tile-container"
                style={{ ...CardStyle, width: '15%', textAlign: 'center' }}
              >
                <Label>Total amount</Label>
                <span>
                  {this.props.amountDetails.totalAmount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </span>
              </div>
              <FontIcon
                style={{ marginTop: '1%' }}
                iconName={'CalculatorSubtract'}
              />
              <div
                className="amount-tile-container"
                style={{ ...CardStyle, width: '15%', textAlign: 'center' }}
              >
                <Label>Discount amount</Label>
                <span>
                  {this.props.amountDetails.discountAmount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </span>
              </div>
              <FontIcon
                style={{ marginTop: '1%' }}
                iconName={'CalculatorEqualTo'}
              />
              <div
                className="amount-tile-container"
                style={{ ...CardStyle, width: '15%', textAlign: 'center' }}
              >
                <Label>Gross amount</Label>
                <span>
                  {this.props.amountDetails.grossAmount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </span>
              </div>
              <FontIcon
                style={{ marginTop: '1%' }}
                iconName={'CalculatorAddition'}
              />
              <div
                className="amount-tile-container"
                style={{ ...CardStyle, width: '15%', textAlign: 'center' }}
              >
                <Label>Tax amount {`( ${taxPercentDisplay}% )`}</Label>
                <span>
                  {this.props.amountDetails.taxAmount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </span>
              </div>
              <FontIcon
                style={{ marginTop: '1%' }}
                iconName={'CalculatorEqualTo'}
              />
              <div
                className="amount-tile-container"
                style={{ ...CardStyle, width: '15%', textAlign: 'center' }}
              >
                <Label>Net amount</Label>
                <span>
                  {this.props.amountDetails.netAmount
                    .toFixed(2)
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}
                </span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  //Function for onChange of Fiscal year dropdown
  private onFiscalYearDropdownChange = (o) => {
  this.selectedFiscalYear = o.key;
  this.props.getMonthList(this.selectedFiscalYear);
    };

  //Function to show back the supplier summary data on back button click
  private onBackButtonClick = () => {
    this.setState({ columnsForVolumeList: columnsForInvoiceViewInitialGrid });
    this.props.setIsActionChanged(true);
    this.props.setIsDisplayButtonClicked(false);
    this.props.setBillableUnitsForInvoice([]);
    this.props.showErrorMessageComplete();
    this.props.getDataForActualAccrualCall(undefined);
    this.setState({ isFilter: false });
    this.setState({ isBackButtonClicked: true }, () => {
      this.props.setIsActionChanged(false);
    });
    this.props.getEventDateTime(InvoicingEvent.InvoiceAccrual,this.state.invoiceCumMonth,0);
    this.props.getEventDateTime(InvoicingEvent.InvoiceActual,this.state.invoiceCumMonth,0);
  };

  //Function to get the filtered data on applying filters (data from FilterDropdown component)
  private displayFilterData = (filteredItems, itemCount, isFilter) => {
    this.setState({
      filteredItems: filteredItems,
      itemCountFilter: itemCount,
      isFilter: isFilter,
    });
  };

  //Function to populate the month list
  private getMonthList = () => {
    let months: IHeadCountMonthList[] = [];
    months = months.concat(this.props.monthList);
    return months;
  };
  // get the currenct selected month
  private _getSelectionDetails(): IHeadCountMonthList {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return;

      case 1:
        const monthSelection = this._selection.getSelection()[0] as IHeadCountMonthList;

        this.indexSelected = this.props.monthList.findIndex(
          (x) => x.CumMonth === monthSelection.CumMonth
        );
        this.selectedCumMonth = monthSelection;
        if (this.indexSelected !== this.prevSelectedIndex) {
          this.props.initialAmountCalculation(this.state.initialAmounts);
          this.props.setIsActionChanged(true);
          this.props.setAllInvoiceAccrualsData([]);
          this.props.setAllInvoiceActualsData([]);
          this.props.getSupplierVariance(+monthSelection.CumMonth);
          this.props.setIsDisplayButtonClicked(false);
          this.props.setBillableUnitsForInvoice([]);
          this.props.showErrorMessageComplete();
          this.props.isColumnSortedDetails(false);
          this.props.getAllInvoice(+monthSelection.CumMonth, true);
          this.props.getAllInvoice(+monthSelection.CumMonth, false);
          this.prevSelectedIndex = this.indexSelected;
          this.setState({ isFilter: false,invoiceCumMonth: monthSelection.CumMonth });
          this.setState({ monthChanged: true ,announcedMessage:`${monthSelection.Month} row ${this.indexSelected} Selected`
        }, () => {
            this.props.setIsActionChanged(false);
          });
          this.props.getEventDateTime(InvoicingEvent.InvoiceAccrual,monthSelection.CumMonth,0);
          this.props.getEventDateTime(InvoicingEvent.InvoiceActual,monthSelection.CumMonth,0);
        }

        return monthSelection;
    }
  }
  // on change of Radio button for Accrual or Actual
  private _accrualActualSelectionChanged(option: IChoiceGroupOption) {
    this.props.setIsActionChanged(false);
    let isActualAccrual: boolean;
    if (option.text === 'Actual') isActualAccrual = true;
    else isActualAccrual = false;
    this.setState({ isActualAccrual: isActualAccrual, isFilter: false }, () => {
        this.props.setIsActionChanged(true);
        this.props.isColumnSortedDetails(false);
      const { dataForActualAccrualCall } = this.props;
      if (dataForActualAccrualCall !== undefined) {
        if (dataForActualAccrualCall.CumulativeMonth <= this.currentMonth) {
          this.props.initialAmountCalculation(this.state.initialAmounts);
          this.props.setBillableUnitsForInvoice([]);
          this.props.getBillableUnitsForInvoice(
            dataForActualAccrualCall.CumulativeMonth,
            dataForActualAccrualCall.SupplierId,
            dataForActualAccrualCall.InvoicePrefix,
            dataForActualAccrualCall.CompanyCode,
            dataForActualAccrualCall.SupplierNumber,
            this.state.isActualAccrual
          );
        }
        if (dataForActualAccrualCall.CumulativeMonth > this.currentMonth)
          this.props.getInvoiceInfo();
      }
    });
  }

  // handle to change the state of the pivot element
  private handleState = (item: PivotItem) => {
    this.setState({ stateDisplayType: item.props.headerText });
    this.props.isColumnSortedDetails(false);
  };

  // Fiscal year dropdown creation method
  private buildFiscalYearDropdown = (): IDropdownOption[] => {
    let options: IDropdownOption[] = [];

    const { fiscalYearNumbers } = this.props;
    fiscalYearNumbers.forEach((x) => {
      options.push({
        key: x,
        text: `Fiscal Year ${x.toString()}`,
      });
    });

    return options;
  };

  private _showPanel = () => {
    this.setState({ showPanel: true });
  };

  private _hidePanel = () => {
    changetableAttributesInvoice.current.focus();
    this.setState({ showPanel: false });
  };

  private _onRenderFooterContent = () => {
    return (
      <div>
        <PrimaryButton
          onClick={this._hidePanel}
          style={{ marginRight: '8px' }}
          role="button"
        >
          Done
        </PrimaryButton>
      </div>
    );
  };

  //Additonal Attributes checkbox selection
  private _onCheckboxChange(
    changedAttribute: string,
    ev: React.FormEvent<HTMLElement>,
    isChecked: boolean
  ): void {
    // Setting the selected true for the toggled checkbox.
    let additionalAttributes = [...this.state.additionalAttributes];
    additionalAttributes.find(
      (x) => x.name === changedAttribute
    ).selected = isChecked;
    this.setState({ additionalAttributes: additionalAttributes });
  }

  //Function to render the month list in detailsGrid
  private renderMonthListColumn= (item: any): JSX.Element => {
    return (
      <Stack horizontal horizontalAlign="space-between">
        <span style={{ fontSize: '13px', width: '100%', fontWeight: 430 }}>
           <span> <Checkbox  className="invoicecheckbox" name="myCheckbox"  key={item.index} checked={this.indexSelected === item.index ? true:false} label={item.Month} /><p className='sr-only'> row   {item.index+1}</p>
            </span>
            </span>
        {/* <div>{item.Month}</div> */}
      </Stack>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMonthList: (fiscalYear: number) => {
      dispatch(getMonthListData(fiscalYear));
    },
    getSupplierInvoiceList: () => {
      dispatch(getSupplierandInvoicePrefix());
    },
    getInvoiceInfo: () => {
      dispatch(getInvoiceInfoMessage());
    },
    getSupplierInvoiceState: (SupplierId: number, CumMonth: number, invoicePrefix: string) => {
      dispatch(getSupplierInvoiceState(SupplierId, CumMonth, invoicePrefix));
    },
    getUserRoles: () => {
      dispatch(getUserRoles());
    },
    initialAmountCalculation: (initialAmounts: ITotalCalculation) => {
      dispatch(initialAmountCalculation(initialAmounts));
    },
    isColumnSortedDetails: (isColumnSorted: boolean) => {
      dispatch(isColumnSortedDetails(isColumnSorted));
    },
    setSupplierInvoiceState: (invoiceState: string) => {
      dispatch(setSupplierInvoiceState(invoiceState));
    },
    dataCountBillableUnits: (dataCount: IDataCount) => {
      dispatch(dataCountBillableUnits(dataCount));
    },
    getSupplierVariance: (cumulativeMonth: number) => {
      dispatch(getSupplierVariance(cumulativeMonth));
    },
    getBillableUnitsForInvoice: (
      CumMonth: number,
      SupplierId: number,
      invoicePrefix: string,
      CompanyCode: string,
      SupplierNumber: number,
      isAccrualOrActual: boolean
    ) => {
      dispatch(
        getBillableUnitsForInvoice(
          CumMonth,
          SupplierId,
          invoicePrefix,
          CompanyCode,
          SupplierNumber,
          isAccrualOrActual
        )
      );
    },
    setBillableUnitsForInvoice: (
      invoiceAccrualActualDetails: IInvoiceBillableUnitDetails[]
    ) => {
      dispatch(setBillableUnitsForInvoice(invoiceAccrualActualDetails));
    },
    setIsDisplayButtonClicked: (isDisplayButtonClicked: boolean) => {
      dispatch(setIsDisplayButtonClicked(isDisplayButtonClicked));
    },
    showErrorMessageComplete: () => {
      dispatch(showErrorMessageComplete());
    },
    getAllInvoice: (cumulativeMonth: number, isActualAccrual: boolean) => {
      dispatch(getAllInvoice(cumulativeMonth, isActualAccrual));
    },
    getDataForActualAccrualCall: (
      dataForActualAccrualCall: ISupplierInvoieVariance
    ) => {
      dispatch(getDataForActualAccrualCall(dataForActualAccrualCall));
    },
    setIsActionChanged: (isActionChanged: boolean) => {
      dispatch(setIsActionChanged(isActionChanged));
    },
    setAllInvoiceAccrualsData: (
      allInvoiceAccrualsData: IInvoiceBillableUnitDetails[]
    ) => {
      dispatch(setAllInvoiceAccrualsData(allInvoiceAccrualsData));
    },
    setAllInvoiceActualsData: (
      allInvoiceActualsData: IInvoiceBillableUnitDetails[]
    ) => {
      dispatch(setAllInvoiceActualsData(allInvoiceActualsData));
    },
    getFiscalYearsForSupplier: () => {
      dispatch(getFiscalYearsForSupplier());
    },
    getEventDateTime: (EventName: string, CumMonth: number,SupplierId: number) => {
      dispatch(getEventDateTime(EventName, CumMonth, SupplierId));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    units: state.headCount.billableUnits,
    fiscalYearNumbers: state.selectedSupplier.availableFiscalYears,
    monthList: state.headCount.monthList,
    supplierInvoiceList: state.invoiceView.supplierInvoiceList,
    amountDetails: state.invoiceView.amountDetails,
    invoiceState: state.invoiceView.invoiceState,
    actualsPostOn: state.invoiceView.actualsPostOn,
    accrualsPostOn: state.invoiceView.accrualsPostOn,
    showInvoiceViewLoader: state.invoiceView.isFetchingAccrualOrActual,
    showAccrualInitialLoader: state.invoiceView.accrualInitialLoader,
    dataCount: state.headCount.dataCount,
    billableUnitsAllSuppliers: state.invoiceView.billableUnitsAllSuppliers,
    supplierVariance: state.invoiceView.supplierVariance,
    invoiceAccrualActualDetails: state.invoiceView.invoiceAccrualActualDetails,
    isFetchingInvoiceVariance: state.invoiceView.isFetchingInvoiceVariance,
    isDisplayButtonClicked: state.invoiceView.isDisplayButtonClicked,
    dataForActualAccrualCall: state.invoiceView.dataForActualAccrualCall,
    allInvoiceAccrualsData: state.invoiceView.allInvoiceAccrualsData,
    allInvoiceActualsData: state.invoiceView.allInvoiceActualsData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceView);
