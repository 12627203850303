import * as React from 'react';
import './automatedsupplierengagements.css';
import { TextField } from '@fluentui/react/lib/TextField';
import HelmetTab from "../../shared/Helmet/Helmet";
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    IDetailsListStyles,
    IColumn,
    SelectionMode,
  } from '@fluentui/react/lib/DetailsList';
import appConfig from '../../assets/configuration/config';
import { v4 as uuidv4 } from 'uuid';
import { TelemetryEvent,Routes,TelemetryException } from '../../shared/models';
import telemetryContext from '../../shared/services/TelemetryServices';
import gtoNewApiInstance, {
  gtoMsalApiFetch,
} from '../../shared/http/GtoApiNew.axios';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { delay } from 'q';
import { getUserRoles } from '../../store/actions/user.action';
import { connect } from 'react-redux';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from 'office-ui-fabric-react';


  export interface IAutomatedSeidPageProps {
    getUserRoles: any;  
    IsInvoiceAnalyst: boolean;
    IsSupplierSupportManager:boolean;
  }
  
  
  export interface IAutomatedSeidPageState {
    automatedSeidItemsList:any;
    tempAutomatedSeidItemsList:any;
    activestatelist:any;
    getFiscalYearsList:any;
    currentFiscalYearNumber:any;
    supplierMasterOptions:any;
    filtersedis:any;
    filterseidsingle:any;
    disabledit:boolean;
    showeditPanel:boolean;
    editFields:any;
    captureField:any;
    captureFieldValue:any;
    captureFieldType:any;
    captureFieldName:any;
    selectedSupplierEngagements:any;
    displaySeidListEdit:any;
    alertFlagOnDifferentSupplierSlection:boolean;
    captureSearchtext:any;
    supplierValueSelected:any;
    supplierKeySelected:any;
    capturePricingGroupId:any;
    pricingGroupLists:any;
    displaySeidWithSupplierNames:any;
    captureUniqueSupplierSelected:any;
  }
  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      overflowX: 'auto',
      selectors: {
        '& [role=grid]': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          height: '65vh',
        },
      },
    },
    headerWrapper: {
      flex: '0 0 auto',
    },
    contentWrapper: {
      flex: '1 1 auto',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  };


class Automatedseid extends React.Component<IAutomatedSeidPageProps,IAutomatedSeidPageState> {

    private _automatedseidGridColumns: IColumn[];
    private _selection: Selection;

    constructor(props) {
        super(props);
        this.props.getUserRoles();

        this._selection = new Selection({
          onSelectionChanged: () => {
            this._getSelectionDetails();
          },
        });
        
        this._automatedseidGridColumns =[
            {
            key: 'column1',
            name: 'Supplier Engagement ID',
            fieldName: 'supplierEngagementId',
            minWidth: 180,
            maxWidth: 250,
            isResizable: true,
          },
          {
            key: 'column17',
            name: 'Supplier Name',
            fieldName: 'supplierName',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column2',
            name: 'Fiscal Year Number',
            fieldName: 'fiscalYearNumber',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
          },
          {
            key: 'column3',
            name: 'Engagement ID',
            fieldName: 'engagementId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column4',
            name: 'Business Group',
            fieldName: 'businessGroup',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderBusinessGroup
          },
          {
            key: 'column5',
            name: 'CCOOrg',
            fieldName: 'CCOOrg',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderccoorg
          },
          {
            key: 'column6',
            name: 'PCY',
            fieldName: 'PCY',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpcy
          },
          {
            key: 'column7',
            name: 'PCY IsGroup',
            fieldName: 'PCYIsGroup',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpcyisgroup
          },
          {
            key: 'column8',
            name: 'PCY Id',
            fieldName: 'PCYId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpcyid
          },
          {
            key: 'column9',
            name: 'SubPCY',
            fieldName: 'SubPCY',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.rendersubpcy
          },
          {
            key: 'column10',
            name: 'SubPCY IsGroup',
            fieldName: 'SubPCYIsGroup',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderpsubcyisgroup
          },
          {
            key: 'column11',
            name: 'SubPCY Id',
            fieldName: 'SubPCYId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.rendersubpcyid
          },
          {
            key: 'column12',
            name: 'Language',
            fieldName: 'Language',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderLanguage
          },
          {
            key: 'column13',
            name: 'IsCommerce',
            fieldName: 'IsCommerce',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderiscommerce
          },
          {
            key: 'column14',
            name: 'Offering',
            fieldName: 'Offering',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderoffering
          },
          {
            key: 'column15',
            name: 'Report Category',
            fieldName: 'ReportGrouping',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderreportCategory
          },
          {
            key: 'column16',
            name: 'Supplier Id',
            fieldName: 'supplierId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          
          {
            key: 'column18',
            name: 'Supplier Number',
            fieldName: 'supplierNumber',
            minWidth: 130,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column19',
            name: 'Company Code',
            fieldName: 'companyCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column20',
            name: 'Supplier Site',
            fieldName: 'supplierSite',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column21',
            name: 'Supplier City',
            fieldName: 'supplierCity',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column22',
            name: 'Supplier Country',
            fieldName: 'supplierCountry',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column23',
            name: 'Currency Code',
            fieldName: 'currencyCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column24',
            name: 'Internal Order',
            fieldName: 'internalOrder',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column25',
            name: 'Cost Center',
            fieldName: 'costCenter',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column26',
            name: 'Profit Center',
            fieldName: 'profitCenter',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column27',
            name: 'Account Code',
            fieldName: 'accountCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column45',
            name: 'Pricing Group Id',
            fieldName: 'pricingGroupId',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column28',
            name: 'Purchase Order',
            fieldName: 'purchaseOrder',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column29',
            name: 'SD1',
            fieldName: 'sD1',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column30',
            name: 'SD2',
            fieldName: 'sD2',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column31',
            name: 'CCO1',
            fieldName: 'ccO1',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column32',
            name: 'CCO2',
            fieldName: 'ccO2',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column33',
            name: 'SEID IsInvoice Active',
            fieldName: 'isInvoiceActive',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column34',
            name: 'Invoice Prefix',
            fieldName: 'invoicePrefix',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column35',
            name: 'Business Segment',
            fieldName: 'BusinessSegment',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderBusinessSegment
          },
          {
            key: 'column36',
            name: 'ExcePI',
            fieldName: 'ExcePI',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderExecPI
          },
          {
            key: 'column37',
            name: 'PL Function',
            fieldName: 'function',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column38',
            name: 'PL FunctionDetail',
            fieldName: 'functionDetail',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column39',
            name: 'PL FunctionSummary',
            fieldName: 'functionSummary',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column40',
            name: 'PL Org',
            fieldName: 'org',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column41',
            name: 'PL OrgDetail',
            fieldName: 'orgDetail',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
          },
          {
            key: 'column42',
            name: 'Product',
            fieldName: 'Product',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderProduct
          },
          {
            key: 'column43',
            name: 'Product Group',
            fieldName: 'ProductGroup',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderProductGroup
          },
          {
            key: 'column44',
            name: 'Product SubType',
            fieldName: 'ProductSubType',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender:this.renderProductSubType
          },
        ];
        this.state ={
            automatedSeidItemsList:[],
            tempAutomatedSeidItemsList:[],
            activestatelist:[],
            getFiscalYearsList:[],
            currentFiscalYearNumber:'',
            supplierMasterOptions:[],
            filtersedis:[],
            filterseidsingle:'',
            disabledit:true,
            showeditPanel:false,
            captureField:'',
            captureFieldValue:'',
            captureFieldType:'',
            captureFieldName:'',
            selectedSupplierEngagements:[],
            displaySeidListEdit:[],
            alertFlagOnDifferentSupplierSlection:false,
            captureSearchtext:'',
            supplierValueSelected:'All',
            supplierKeySelected:0,
            capturePricingGroupId:'',
            pricingGroupLists:[],
            displaySeidWithSupplierNames:[],
            captureUniqueSupplierSelected:'',
            editFields:[
              {
                "key":"CCO1",
                "text":"CCO1",
                "type":"text"
              },
              {
                "key":"CCO2",
                "text":"CCO2",
                "type":"text"
              },
              {
                "key":"InternalOrder",
                "text":"Internal Order",
                "type":"number"
              },
              // {
              //   "key":"purchaseOrder",
              //   "text":"Purchase Order",
              //   "type":"number"
              // },
              {
                "key":"PricingGroup",
                "text":"Pricing",
                "type":"number"
              },
              {
                "key":"SD1",
                "text":"SD1",
                "type":"text"
              },
              {
                "key":"SD2",
                "text":"SD2",
                "type":"text"
              }
          ]
        }
    }

    private _getSelectionDetails(): string {
      const selectionCount = this._selection.getSelectedCount();
      switch (selectionCount) {
        case 0:
          this.setState({
            disabledit:true
          })
          return ;
          case 1:
            this.setState({
              selectedSupplierEngagements:this._selection.getSelection(),
              disabledit:false,
            })
            return ;
            default:
              this.setState({
                selectedSupplierEngagements:this._selection.getSelection(),
                disabledit: false,
              });
      }
    }

    componentDidMount(): void {
      this.loadFiscalYears();
      this.loadSupplierMasterList();
    }

    private renderBusinessGroup = (item: any): JSX.Element => {
        if(item.engagement.businessGroup){
            return (
               <span  aria-label={item.engagement.businessGroup}>{item.engagement.businessGroup}</span>
              );
        }else{
        }
      };
      private renderccoorg = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.CCOOrg){
            return (
               <span  aria-label={item.engagement.engagementAttributes.CCOOrg.name}>{item.engagement.engagementAttributes.CCOOrg.name}</span>
              );
        }else{
        }
      };
      private renderpcy = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.PCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.PCY.name}>{item.engagement.engagementAttributes.PCY.name}</span>
              );
        }else{
        }
      };
      private renderpcyid = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.PCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.PCY.id}>{item.engagement.engagementAttributes.PCY.id}</span>
              );
        }else{
        }
      };

      private renderpcyisgroup = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.PCY){

          if( item.engagement.engagementAttributes.PCY.isGroup){
            return (
               <span  aria-label={item.engagement.engagementAttributes.PCY.isGroup}>True</span>
              );
        }else{
            return (
                <span  aria-label={item.engagement.engagementAttributes.PCY.isGroup}>False</span>
               );
        }
        }else{

        } 
      };
      private rendersubpcy = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.SubPCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.SubPCY.name}>{item.engagement.engagementAttributes.SubPCY.name}</span>
              );
        }else{
        }
      };

      private rendersubpcyid = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.SubPCY){
            return (
               <span  aria-label={item.engagement.engagementAttributes.SubPCY.id}>{item.engagement.engagementAttributes.SubPCY.id}</span>
              );
        }else{

        }
      };
      private renderpsubcyisgroup = (item: any): JSX.Element => {

        if(item.engagement.engagementAttributes.SubPCY ){
          if(item.engagement.engagementAttributes.SubPCY.isGroup){
            return (
               <span  aria-label={item.engagement.engagementAttributes.SubPCY.isGroup}>true</span>
              );
        }else{
            return (
                <span  aria-label={item.engagement.engagementAttributes.SubPCY.isGroup}>False</span>
               );
        }
        }else{

        }
        
      };

      private  renderLanguage = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.Language){
            return (               
               <span  aria-label={item.engagement.engagementAttributes.Language.name}>{item.engagement.engagementAttributes.Language.name}</span>
              );
        }else{

        }
      };
      private renderiscommerce = (item: any): JSX.Element => {
        
        if(item.engagement.engagementAttributes.IsCommerce){
            return (              
               <span  aria-label={item.engagement.engagementAttributes.IsCommerce.name}>{item.engagement.engagementAttributes.IsCommerce.name}</span>
              );
        }else{

        }
      };

      private renderoffering = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ServiceOffering){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ServiceOffering.name}>{item.engagement.engagementAttributes.ServiceOffering.name}</span>
              );
        }else{

        }
      };
      private renderreportCategory = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.CloudType){
            return (
               <span  aria-label={item.engagement.engagementAttributes.CloudType.name}>{item.engagement.engagementAttributes.CloudType.name}</span>
              );
        }else{

        }
      };

      private renderBusinessSegment  = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.BusinessSegment){
            return (
               <span  aria-label={item.engagement.engagementAttributes.BusinessSegment.name}>{item.engagement.engagementAttributes.BusinessSegment.name}</span>
              );
        }else{

        }
      };
      private renderExecPI = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ExecPl){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ExecPl.name}>{item.engagement.engagementAttributes.ExecPl.name}</span>
              );
        }else{

        }
      };
      private renderProduct = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.Product){
            return (
               <span  aria-label={item.engagement.engagementAttributes.Product.name}>{item.engagement.engagementAttributes.Product.name}</span>
              );
        }else{

        }
      };
      private renderProductGroup = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ProductGroup){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ProductGroup.name}>{item.engagement.engagementAttributes.ProductGroup.name}</span>
              );
        }else{

        }
      };
      private renderProductSubType = (item: any): JSX.Element => {
        if(item.engagement.engagementAttributes.ProductSubType){
            return (
               <span  aria-label={item.engagement.engagementAttributes.ProductSubType.name}>{item.engagement.engagementAttributes.ProductSubType.name}</span>
              );
        }else{

        }
      };

    loadAutomatedSeidsList = () =>{
        if (appConfig.UseMockData === 'false') {
          // Creating headers
          const headers: any = {
            SubCorrelationKey: uuidv4(),
            'X-CorrelationId': uuidv4(),
          };
          // Defining the url
          const url: string = `${Routes.getAutomatedSeidList}${this.state.currentFiscalYearNumber}`;
          // Logging telemetry
         
           gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
            .then((response: any) => {
              this.setState({
                automatedSeidItemsList:response.data && response.data.sort(function (a, b) {
                  return a.supplierEngagementId - b.supplierEngagementId;
                }),
                activestatelist:response.data && response.data.filter(x=> x.isActive === true),
                tempAutomatedSeidItemsList:response.data
              },this.alertNotFoundForFiscalYear)
              telemetryContext.logEvent(
                TelemetryEvent.FetchAutomatedSeidList,
                {
                  headers,
                  url,
                }
              );
            // }
            })
            .catch((error) => {
    
              telemetryContext.logException(
                error.toString(),
                TelemetryException.FetchAutomatedSeidListFailure,
                undefined,
                {
                  headers,
                  url,
                }
              );
    
              if (error.response && error.response.status === 404) {
                toast.error(
                  'Automated supplier engagement list unable to fetch ',
                  {
                    className: css({
                      background: '#a80000 !important',
                    }),
                  }
                );
              }
            });
        }
    }

    loadSupplierMasterList = () =>{
      if (appConfig.UseMockData === 'false') {
        // Creating headers
        const headers: any = {
          SubCorrelationKey: uuidv4(),
          'X-CorrelationId': uuidv4(),
        };
        // Defining the url
        const url: string = `${Routes.getSupplierMasterData}`;
        // Logging telemetry
        telemetryContext.logEvent(
          TelemetryEvent.FetchSupplierMasterList,
          {
            headers,
            url,
          }
        );
        var supplierList =[];
        supplierList =[{
          key:0,
          text:'All',
          isActive:true
        }]  
         gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
          .then((response: any) => {
          if(response.data && response.status === 200){
  
            let masterData = response.data && response.data.sort(function (a, b) {
              return a.supplierName - b.supplierName;
            }).map((x) => ({
              key: x.supplierMasterId,
              text:x.supplierName,
              isActive:x.isActive
            }));
            // Logging success
            this.setState({
              supplierMasterOptions:supplierList.concat(masterData)
            })
            // Logging success
          
            telemetryContext.logEvent(
              TelemetryEvent.FetchSupplierMasterList,
              {
                headers,
                url,
              }
            );
            
          }
          })
          .catch((error) => {
  
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchSupplierMasterListFailure,
              undefined,
              {
                headers,
                url,
              }
            );
  
            if (error.response && error.response.status === 404) {
              toast.error(
                'Supplier Master  information is not available ',
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
            }
          });
      }
  }

    loadFiscalYears = () =>{
      if (appConfig.UseMockData === 'false') {
        // Creating headers
        const headers: any = {
          SubCorrelationKey: uuidv4(),
          'X-CorrelationId': uuidv4(),
        };
        // Defining the url
        const url: string = `${Routes.GetAvailableFiscalYears}
        `;
        // Logging telemetry
        telemetryContext.logEvent(
          TelemetryEvent.FetchAvailableFiscalYearsForAutomationSeidBegin,
          {
            headers,
            url,
          }
        );
         gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
          .then((response: any) => {
          if(response.data && response.status === 200){
            // Logging success
            telemetryContext.logEvent(
              TelemetryEvent.FetchAvailableFiscalYearsForAutomationSeidSuccess,
              {
                headers,
                url,
              }
            );
            this.setState({
              getFiscalYearsList:response.data && response.data.fiscalYearNumbers,
              currentFiscalYearNumber:response.data.currentFiscalYearNumber
            },this.loadAutomatedSeidsList)
          }
          })
          .catch((error) => {
            telemetryContext.logException(
              error.toString(),
              TelemetryException.FetchAvailableFiscalYearsForAutomationSeidFailure,
              undefined,
              {
                headers,
                url,
              }
            );
            if (error.response && error.response.status === 404) {
              toast.error(
                'Fiscal Year information is not available ',
                {
                  className: css({
                    background: '#a80000 !important',
                  }),
                }
              );
            }
          });
      }
}
loadPrcingGroupBySupplierId = () =>{
  if (appConfig.UseMockData === 'false') {
    // Creating headers
    const headers: any = {
      SubCorrelationKey: uuidv4(),
      'X-CorrelationId': uuidv4(),
    };
    // Defining the url
    const url: string = `${Routes.getPricingGroupDescriptionBySupplier}?supplierId=${this.state.captureUniqueSupplierSelected}`;
    // Logging telemetry
    telemetryContext.logEvent(
      TelemetryEvent.FetchPricingGroupListBySupplierBegin,
      {
        headers,
        url,
      }
    );
   
     gtoMsalApiFetch(gtoNewApiInstance.get, url, { headers })
      .then((response: any) => {
          var masterData = response.data.map((x => ({
            key: x.PricingGroupId,
            text:x.PricingGroupDescription,
          })))
        this.setState({
          pricingGroupLists:masterData,
        },this.alertNotFoundForPricingGroups)
        telemetryContext.logEvent(
          TelemetryEvent.FetchPricingGroupListBySupplierSuccess,
          {
            headers,
            url,
          }
        );
      // }
      })
      .catch((error) => {

        telemetryContext.logException(
          error.toString(),
          TelemetryException.FetchPricingGroupListBySupplierFailure,
          undefined,
          {
            headers,
            url,
          }
        );

        if (error.response && error.response.status === 404) {
          toast.error(
            'Automated supplier engagement list unable to fetch ',
            {
              className: css({
                background: '#a80000 !important',
              }),
            }
          );
        }
      });
  }
}

SaveEditSupplierEngagementsAttributes = () =>{
    // Checking if app is using mock data.
    if (appConfig.UseMockData === 'false') {
      // Creating headers for telemetry
      const headers: object = {
        'X-CorrelationId': uuidv4(),
        SubCorrelationKey: uuidv4(),
      };
      let url: string;
      var body = {};
        for(var i = 0 ;i< this.state.displaySeidListEdit.length;i++){
          if(this.state.captureField !== 'PricingGroup'){
            body[this.state.displaySeidListEdit[i]] = this.state.captureFieldValue.toLowerCase();
          }else{
            body[this.state.displaySeidListEdit[i]] = JSON.stringify(this.state.captureFieldValue).toLowerCase();
          }
        }    

      // Defining the url
        url =Routes.editSupplierEngagementsAttributes +`?fiscalyearnumber=${this.state.currentFiscalYearNumber}&attributeName=${this.state.captureField}`;
        telemetryContext.logEvent(TelemetryEvent.EditSupplierEngagementsAttributesBegin, {
          url,
          headers,
        });

      // Making the get call.
      return gtoMsalApiFetch(gtoNewApiInstance.get, url, {
        data: body,
        headers,
        method: 'PUT',
      })
        .then((response: any) => {
          this.setState({
            showeditPanel:false,
            captureFieldValue:'',
            supplierKeySelected:0,
            supplierValueSelected:'All',
           captureSearchtext:''
          },this.loadAutomatedSeidsList)
          this._selection.setAllSelected(false)

          telemetryContext.logEvent(TelemetryEvent.EditSupplierEngagementsAttributesSuccess, {
            url,
            headers,
          });
          // Mapping the response data.
          toast.success(response.data, {
            className: css({
                  background: '#107c10 !important',
                }),
              });

        })
        .catch((error) => {
          // Log Exception
          telemetryContext.logException(
            error.toString(),
            TelemetryException.EditSupplierEngagementsAttributesFailure,
            undefined,
            {
              url: url,
              headers,
            }
          );
          // Error handling.
          if (error.response && error.response.status === 404) {
            toast.error('Unable Edit Attributes of Supplier Engagements', {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }
          if (error.response && error.response.status === 400) {
            this.setState({
              showeditPanel:false,})
            toast.error(error.response.data.length > 0 && error.response.data[0].errorMessage && error.response.data[0].errorMessage, {
              className: css({
                background: '#a80000 !important',
              }),
            });
          }

          throw error;
        });
    } else {
      //Setting mock data.
      return delay(2000).then(() => {});
    }
}
    private filterwithSupplierNumber = (e, newValue) => {
      this._selection.setAllSelected(false);
      var temp:any;
      var newArr:any;

      this.setState({
        captureSearchtext:newValue,
        filtersedis:[],
        filterseidsingle:''
      })
      if(newValue === '' && this.state.supplierValueSelected !=='All'){
        this.setState({
          automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) )
        })

      }else if(newValue === '' && this.state.supplierValueSelected ==='All'){
        this.setState({
          automatedSeidItemsList:this.state.tempAutomatedSeidItemsList 
        })
      }else if(newValue.includes('\n') && newValue.includes(',')){
        temp = newValue.split('\n')
         newArr = temp.filter((a) => a);
        this.setState({
          filtersedis:newArr
        })
      }
      else if(newValue.includes(',')){
       temp = newValue.split(',')
        newArr = temp.filter((a) => a);
        this.setState({
          filtersedis:newArr
        })
      }else if(newValue.includes('\n')){
        temp = newValue.split('\n')
         newArr = temp.filter((a) => a);
        this.setState({
          filtersedis:newArr
        })
      }else{
        this.setState({
          filtersedis:[],
          filterseidsingle:newValue
        })
      }
      };
  

      onClickSearchSeid = () =>{
        if(this.state.captureSearchtext === '' && this.state.supplierValueSelected !== 'All'){
          this.setState({
            automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) ),
          })
          return;
        }else if(this.state.captureSearchtext === '' && this.state.supplierValueSelected === 'All'){
            this.setState({
              automatedSeidItemsList:this.state.tempAutomatedSeidItemsList
            })
            return;
        }
        var tempresults =[];
        var storetemp= []
        
        // this.setState({
        // automatedSeidItemsList:[]
        // })

        if( this.state.supplierValueSelected === 'All' && this.state.filtersedis.length > 0){

          for(var i =0 ; i<this.state.filtersedis.length ;i++){
            tempresults =this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filtersedis[i].trim(' ').replace(',','')));

            if(!tempresults || tempresults.length < 1){
            }else{
              tempresults = tempresults[0];
                storetemp.push(tempresults)
            }
                  this.setState({
                    automatedSeidItemsList:storetemp,
                    })
                }
        }else if( this.state.supplierValueSelected && this.state.supplierValueSelected !== 'All' && this.state.filtersedis.length > 0){

          for(var i =0 ; i<this.state.filtersedis.length ;i++){
            tempresults =this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(this.state.supplierValueSelected.toLowerCase()) ).filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filtersedis[i].trim(' ').replace(',','')));

            if(!tempresults || tempresults.length < 1){
            }else{
              tempresults = tempresults[0];
                storetemp.push(tempresults)
            }
                  this.setState({
                    automatedSeidItemsList:storetemp,
                     })
                }
        }else if(this.state.supplierValueSelected !== 'All' && this.state.filterseidsingle !== ''){
            storetemp=[]
            tempresults=[]
            this.setState({
            automatedSeidItemsList:this.state.automatedSeidItemsList.length >0 && this.state.automatedSeidItemsList.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filterseidsingle)),
              })
        }
        else {
          storetemp=[]
          tempresults=[]
            this.setState({
          automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.length > 0 && this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierEngagementId']).includes(this.state.filterseidsingle)),
        })
        }
      }

      private buildFiscalYearDropdown = (): IDropdownOption[] => {
        let options: IDropdownOption[] = [];
        this.state.getFiscalYearsList.forEach((x) => {
          options.push({
            key: x,
            text: `Fiscal Year ${x.toString()}`,
          });
        });
    
        return options;
      };
        
      //Function for onChange of Fiscal year dropdown
  private onFiscalYearDropdownChange(o) {
    this._selection.setAllSelected(false)

    this.setState({
      currentFiscalYearNumber:o.key,
      supplierKeySelected:0,
      supplierValueSelected:'All',
      captureSearchtext:'',
    },this.loadAutomatedSeidsList) 
  }

  private onchangeMasterName = (o: IDropdownOption) => {
    this._selection.setAllSelected(false)

    this.setState({
      captureSearchtext:'',
      supplierValueSelected:o.text,
      supplierKeySelected:o.key
    })
    if(o.text === 'All'){
      this.setState({
        automatedSeidItemsList:this.state.tempAutomatedSeidItemsList,
      })
    }else{
      this.setState({
        automatedSeidItemsList:this.state.tempAutomatedSeidItemsList.filter(x=> JSON.stringify(x['supplierName']).toLowerCase().includes(o.text.toLowerCase()) ),
      },this.alertNotFound)
    }
  };

  alertNotFound = () =>{
    if(this.state.automatedSeidItemsList.length  === 0){

      toast.error(`No records found for selected supplier ${this.state.supplierValueSelected} `, {
        className: css({
          background: '#a80000 !important',
        }),
      });
    }
  }

  alertNotFoundForFiscalYear = () =>{
    if(this.state.automatedSeidItemsList.length  === 0){

      toast.error(`No records found for selected fiscal year ${this.state.currentFiscalYearNumber} `, {
        className: css({
          background: '#a80000 !important',
        }),
      });
    }
  }

  alertNotFoundForPricingGroups = () =>{
    if(this.state.pricingGroupLists.length  === 0){

      toast.error(`No pricing groups  found for selected supplier`, {
        className: css({
          background: '#a80000 !important',
        }),
      });
    }
  }


  private onchangeSelectAttributes(o){
    this.setState({
      captureFieldValue:''
    })
    this.setState({
      captureField:o.key,
      captureFieldType:o.type,
      captureFieldName:o.text
    },this.loadPrcingGroupBySupplierId)
  }
  private onchangePricing(o){

    this.setState({
      captureFieldValue:''
    })
    
    this.setState({
      capturePricingGroupId:o.key,
      captureFieldValue:o.key
    })
  }

  onclickeditpermissions = (value) => {
    this.setState({
      showeditPanel:true,
      captureField:''
    },this.formatvalues)
  }

  formatvalues (){
    var temp =[];
    var tempIdsWithNames =[];
    var tempnames =[];
    var supplierID =[];
    for(var i= 0;i<this.state.selectedSupplierEngagements.length;i++){
      temp.push(this.state.selectedSupplierEngagements[i].supplierEngagementId)
      tempIdsWithNames.push(this.state.selectedSupplierEngagements[i].supplierEngagementId +'-' +this.state.selectedSupplierEngagements[i].supplierName)
      if(tempnames.indexOf(this.state.selectedSupplierEngagements[i].supplierName) > -1){
        
      }else{
        tempnames.push(this.state.selectedSupplierEngagements[i].supplierName)
        supplierID.push(this.state.selectedSupplierEngagements[i].supplierId)
      }
    }
      this.setState({
        displaySeidListEdit:temp.length > 0 && temp.sort(function (a, b) {
          return a.value - b.value;
        }),
        displaySeidWithSupplierNames :tempIdsWithNames,
        alertFlagOnDifferentSupplierSlection : tempnames.length > 1 ? true : false,
        captureUniqueSupplierSelected:supplierID && supplierID.length > 0 ? supplierID [0] :''
      })
      
}

  ondismissEdit = () => {
    this.setState({
      showeditPanel: false,
    });
  };
  private onchangeFieldValue = (e,newValue) =>{
    this.setState({
      captureFieldValue:newValue,

    })
  }
  private _getErrorMessageOnFieldValidations = (value: string): string => {
    let errorMessage: string = '';
    if(value.length > 0 ){
  }else{
    errorMessage=`Enter valid ${this.state.captureFieldName.toLowerCase()}`
  }
    return errorMessage;
  };

  renderSelectedSupplierEngagementsIdList =() =>{
    return this.state.displaySeidWithSupplierNames.map((x,i) =>
    
     <li aria-label= {x} tabIndex={0}>{x}</li> )
  }
    

    render() {
        return (
          <React.Fragment>
          <div className='mainDiv'>
          <HelmetTab title={'Automated Supplier Engagements'} />
            <h1 className='pageTitle' aria-label={'Automated Supplier Engagements'}>
              Automated Supplier Engagements
            </h1>

            <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row filterRow">
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
              <Dropdown
                      aria-label="Fiscal Year Dropdown"
                      label="Fiscal Year"
                      placeholder={this.state.currentFiscalYearNumber !== undefined ?`Fiscal Year ${this.state.currentFiscalYearNumber}`: "Select an option"}
                      id="fiscalYearDropdown"
                      title="FiscalYear"
                      onChange={(e, o) => this.onFiscalYearDropdownChange(o)}
                      options={this.buildFiscalYearDropdown()}
                    />
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
              <Dropdown
                      aria-label="Record Type"
                      placeholder='Supplier Engagement'
                      label="Record Type"
                      id="RecordType"
                      title="RecordType"
                      options={[{
                        key:0,
                        text:'Supplier Engagement'
                      }]}
                    />
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
              <Dropdown
                    placeholder="Supplier Name"
                    label="Supplier Name"
                    ariaLabel="Supplier Name"
                    options={this.state.supplierMasterOptions}
                    required={true}
                    onChange={(e,o) => this.onchangeMasterName(o)}
                    selectedKey={this.state.supplierKeySelected}
                  />
              </div>
              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2">
                <TextField placeholder="Enter to search supplier engagement id's" label="Supplier Engagement Id's" multiline rows={4}  onChange={this.filterwithSupplierNumber}
                value={this.state.captureSearchtext} />
              </div>

              <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg2 searchButton">
              <PrimaryButton
                  text="Search"
                  iconProps={{ iconName: 'search' }}
                  onClick={() => this.onClickSearchSeid()}
                />  
              </div>
            </div>
          </div>

          {(this.props.IsInvoiceAnalyst || this.props.IsSupplierSupportManager )&& <div className="ms-Grid" dir="ltr" >
            <div className="ms-Grid-row editButton">
               <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4">
                <PrimaryButton
                  text="Edit"
                  iconProps={{ iconName: 'EditNote' }}
                  style={{ marginRight: '10px' }}
                  disabled={this.state.disabledit}
                  onClick={() => this.onclickeditpermissions('edit')}
                />
              </div>
            </div>
          </div>}

              <Panel
            isOpen={this.state.showeditPanel}
            onDismiss={this.ondismissEdit}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
            headerText={'Edit Automated Supplier Engagements'}
          >
            <div>
                <p aria-label='Selected Automated Supplier Engagements Ids List' style={{fontWeight:500}}>Selected Automated Supplier Engagements Id's List</p>
                {this.state.displaySeidWithSupplierNames.length < 7 && <ul className="grid-list-length selectedListDiv-length">
              {this.renderSelectedSupplierEngagementsIdList()}
              </ul>}
              {this.state.displaySeidWithSupplierNames.length > 6 && <ul className="grid-list selectedListDiv">
              {this.renderSelectedSupplierEngagementsIdList()}
              </ul>}
            </div>
            
           <div className="ms-Grid" dir="ltr">

              <div className="ms-Grid-row commonMargin" >
                  <Dropdown
                    placeholder="Select attribute"
                    label="Select attribute"
                    ariaLabel="Select attribute"
                    options={this.state.editFields}
                    onChange={(e,o) => this.onchangeSelectAttributes(o)}
                  />
                </div>
               {this.state.captureField !=='' && <div>
               {this.state.captureField ==='PricingGroup' ?  <div className="ms-Grid-row commonMargin">

               <Dropdown
                    placeholder="Select Pricing Group"
                    label="Pricing"
                    ariaLabel="Pricing"
                    options={this.state.pricingGroupLists}
                    onChange={(e,o) => this.onchangePricing(o)}
                  />
           { this.state.alertFlagOnDifferentSupplierSlection  && <p style={{color:'#B2484D'}}> Alert ! Please select automated supplier engagement id's of single supplier to edit pricing</p>}
                </div>:

                <div className="ms-Grid-row commonMargin">
                  <TextField label={this.state.captureFieldName}  type={this.state.captureFieldType} onChange={this.onchangeFieldValue}
                  value={this.state.captureFieldValue}
                  placeholder={`Please enter valid ${this.state.captureField.toLowerCase()} value`}
                  // onGetErrorMessage ={this._getErrorMessageOnFieldValidations}
                  />
                </div> }

                <div className="editButtonGroup commonMargin" >
                <DefaultButton
                  data-automation-id="cancelBtn"
                  text="Cancel"
                  onClick={() => {
                    this.ondismissEdit();
                  }}
                  style={{ margin: '10px' }}
                />
               {this.state.captureField ==='PricingGroup' ? <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  disabled={this.state.alertFlagOnDifferentSupplierSlection}
                  onClick={() => this.SaveEditSupplierEngagementsAttributes()}
                  style={{ float: 'right', margin: '10px 0px' }}
                /> :
                <PrimaryButton
                  data-automation-id="submitBtn"
                  role="button"
                  text="Save"
                  disabled={this.state.captureFieldValue !=='' ?false:true}
                  onClick={() => this.SaveEditSupplierEngagementsAttributes()}
                  style={{ float: 'right', margin: '10px 0px' }}
                />}
              </div>
              </div>}
                </div> 

            </Panel>
            <div className='gridproperties'>
              <DetailsList
                compact={true}
                items={this.state.automatedSeidItemsList}
                columns={this._automatedseidGridColumns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={this._selection}
                selectionPreservedOnEmptyClick={true}
                selectionMode={
                  (this.props.IsInvoiceAnalyst || this.props.IsSupplierSupportManager) ?
                  SelectionMode.multiple : SelectionMode.none
                  }
                  styles={gridStyles}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
              />
          </div>
        </div>
      </React.Fragment>
                )}
}
        const mapDispatchToProps = (dispatch, props) => {
          return {
            getUserRoles: () => {
              dispatch(getUserRoles());
            },
          };
        };
        const mapStateToProps = (state) => {
          return {
            IsInvoiceAnalyst: state.user.IsInvoiceAnalyst,
            IsSupplierSupportManager:state.user.IsSupplierSupportManager
          };
        };

export default connect(mapStateToProps, mapDispatchToProps)(Automatedseid)
