// Enum defining api routes.
export enum Routes {
  FetchUserAuth = 'api/userAuth/GetUserAuthorization?module=Home',
  FetchSupplierList = 'api/Supplier/GetSuppliersDetails',
  FetchUserModules = 'api/userAuth/GetUserAuthorizationModule',
  FetchPOData = 'api/v1/Purchaseorder/GetPurchaseOrdersForSupplier',
  FetchBudgetData = 'api/Supplier/BudgetAndProjectedActuals',
  GetAvailableFiscalYears = 'api/Timeline/GetFiscalYears',
  PostAravoEngagementId = 'api/Aravo/PostAravoEngagementContractMapping',
  	

  // FetchExistingContracts = 'api/v2/contract/GetExistingContracts',
  FetchExistingContracts = 'api/Contract/GetAllActiveContractsAndShells',
  FetchContractSearchSuggestions = 'api/Contract/SearchContract',
  InsertContract = 'api/Contract/MapContract',
  FetchContractDetails = 'api/Contract/GetRelatedContracts',
  FetchFeatureStatus = 'api/Feature/IsFeatureEnabled',
  FetchAravoToContractMapForSupplier = 'api/Aravo/GetActiveAravoEngagementContractMapping',
  FetchMatchingContractShells = 'api/Contract/GetMatchingShells',
  InsertMergeContractWithShell = 'api/Contract/MergeContractWithShell',
  FetchActiveContractsForSupplier = 'api/Contract/GetActiveContractsForSupplier',
  DeleteAravoIdForSupplier = 'api/Aravo/DeleteAravoEngagementContractMapping',
  FetchJudgementsForSupplier = 'api/Supplier/GetJudgementsForSupplier',
  PostJudgementsForSupplier = 'api/Supplier/PostJudgement',
  FetchLineItemsForSupplier = '/api/v1/PurchaseOrder/GetPurchaseOrderLineItems',
  CreatePurchaseOrder = 'api/Purchaseorders/CreatePurchaseOrder',
  GetSuppliersForUser = 'TODO PlaceHolder', // TODO: This may need cleanup.

  /* headCount */
  GetBillableUnits = '/api/user/invoice',
  PostBillableUnits = '/api/user/invoice',
  AcceptModification = '/api/user/invoice/acceptModification',
  GetPriorPeriodAdjustmentDetails = 'api/Timeline/GetFiscalPeriods',
  PostPriorPeriodAdjustment = '/api/user/invoice/priorPeriodAdjustment',
  GetMonthList = 'api/user/invoice/Adjustments',
  MicrosoftSubmittedBillableUnits = '/api/user/invoice/MicrosoftSubmittedBillableUnits',
  GetNRCTypes = '/api/nrc/Types',
  GetNRCCategories = '/api/nrc/Categories',
  GetNRCDescription = '/api/nrc/supplierEngagement',
  SaveNRCDescription = '/api/nrc/user',
  SubmitNRCDetails = '/api/user/invoice/nrc',  
  getEventDateTime = '/api/Timeline/EventDateTime',
  /*------ */

  /* invoiceView */
  GetSupplierandInvoicePrefix = '/api/Supplier/GetSupplierAndInvoicePrefix',
  GetAccrualsForInvoicePrefix = '/api/invoice/supplier/InvoiceAccruals',
  GetActualsForInvoicePrefix = '/api/invoice/supplier/InvoiceActuals',
  GetSupplierInvoiceState = '/api/invoice/supplier/InvoiceState',
  GetSupplierInvoice = '/api/invoice/supplier/InvoiceVariance',
  GetAllInvoiceAccruals = '/api/invoice/supplier/AllInvoiceAccruals',
  GetAllInvoiceActuals = '/api/invoice/supplier/AllInvoiceActuals',
  /*------ */

  /* UnAuthUser */
  GetAvailableRoles = '/api/userAuth/GetAvailableUserRoles',
  GetUnAuthUserPendingRequest = 'api/userAuth/GetUnAuthUserPendingRequest',
  SubmitAccessRequest = 'api/userAuth/SaveUnAuthUserAccessRequest',
  SendMailReminderForApproval = 'api/userAuth/SendReminderForApproval',
  GetSDUsers = '/api/userAuth/allservicedelivery',
  SubmitSDDetails = '/api/userAuth/setservicedeliveryproxy',
  /*------ */

  /* BOB Report */
  GetBOBReportMasterData = '/api/invoice/bob/GetBobMasterData',
  GetBOBInvoiceReportSummaryData = '/api/invoice/bob/FetchBobInvoiceSummary',
  GetBOBInvoiceReportDetails = '/api/invoice/bob/FetchBobInvoiceDetails',
  GetBOBBudgetReportSummaryData = '/api/invoice/bob/FetchBobBudgetSummary',
  GetBOBBudgetReportDetails = '/api/invoice/bob/FetchBobBudgetDetails',
  /*------ */

  /* User preferences */
  UserPreferences = '/api/user/preferences',


  /*nrc line items*/
  GetNRCLineItemCategories = '/api/nrc/Categories',
  GetNRCLineItemTypes = '/api/nrc/Types',
  DeleteNRCLineItem = '/api/nrc/category',
  AddNRCLineItem = '/api/nrc/category',

  /*user management*/
  getAllUsersList = '/api/user/all',
  getUserRoles ='/api/user/roles',
  getNewUserData = '/api/user/search/new',
  AddNewUser = '/api/user/add',
  getPendingRequests='/api/user/request/pending',
  updateUserStatus='/api/user/roles/update',
  approveRequestPending='/api/user/request/action',


  /*Supplier*/
  getSupplierList= '/api/Supplier/all',
  archiveSupplier = '/api/Supplier/archive',
  getSupplierMasterData= '/api/Supplier/master/all',
  getDiscountTermList ='/api/Supplier/discount/all',
  getCurrencyList ='/api/Supplier/currency/all',
  addSupplier = '/api/Supplier',
  /*Supplier Site*/
  getSupplierSiteList = 'api/SupplierSite/all',
  getSupplierSiteCity = '/api/SupplierSite/city/all',
  addSupplierSite = 'api/SupplierSite',
  approveSupplierSite = 'api/SupplierSite/approve',
  rejectSupplierSite = 'api/SupplierSite/reject',
  archiveSupplierSite='api/SupplierSite/action',
  getSuppliers = 'api/Supplier/GetSuppliers',
  getPendingSupplierSiteRecords= '/api/SupplierSite/pending/all',

  /*Report*/
  getAllReport ='/api/report/all',
  approveSupplier ='/api/Supplier/approve',
  rejectSupplier ='/api/Supplier/reject',
  addOrDeleteInvoiceContact ='/api/Supplier/invoicecontact',

  /* invoicePrefix */
  getAllInvoicePrefix = '/api/InvoicePrefix',
  archiveInvoicePrefix = '/api/InvoicePrefix/archive',
  addInvoicePrefix = 'api/InvoicePrefix',
  /*------ */

  /*Automated supplier engagements*/
  getAutomatedSeidList ='/api/automation/supplierengagement/fiscalYearNumber/',
  editSupplierEngagementsAttributes = 'api/automation/supplierengagement/attributes',
  editSupplierEngagementAttributePurchaseOrder ='api/automation/supplierengagement/supplier/purchaseorder',
  getPricingGroupDescriptionBySupplier ='api/v1/Pricing/GetPricingGroupBySupplier',

  /*Rollover*/
  checkAutomatedSeidRollover ='/api/automation/supplierengagement/rollover',
  getFiscalYeartoRollover = '/api/v1/RollOver/GetSyncToYear',
  checkEngagementAndSupplierEngagementRollover = '/api/v1/RollOver/GetSyncToYearStatus',
  updateBulkRolloverEngagementAndSupplierEngagement = '/api/v1/RollOver/BulkRollOver'
}
